import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { colorsArray } from "../../helpers/helpers";

const Audience = ({ totalAudience, genderCount, ageRangeCatalog }) => {

    const [audienceGenderData, setAudienceGenderData] = useState({
        series: [{
            name: "género",
            data: [0, 0, 0]
        }],
        options: {
            chart: {
                toolbar: {
                    show: false
                }
            },
            colors: colorsArray,
            plotOptions: {
                bar: {
                    barHeight: '75%',
                    distributed: true,
                    horizontal: true,
                    borderRadius: 7,
                }
            },
            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'center',
                markers: {
                    width: 12,
                    height: 12,
                    radius: 12,
                }
            },
            dataLabels: {
                enabled: true,
                formatter: (val) => val + "%",
                offsetX: 15,
                style: {
                    colors: ['#15253c']
                },
                // dropShadow: {
                //     enabled: true,
                //     top: 1,
                //     left: 1,
                //     blur: 1,
                //     color: '#000',
                //     opacity: 1
                // }
            },
            xaxis: {
                type: 'category',
                categories: ['mujeres', 'hombres', 'indefinido'],
                labels: {
                    show: true
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: true,
                }
            },
            yaxis: {
                labels: {
                    show: false
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                }
            },
            tooltip: {
                theme: 'dark',
                x: {
                    show: false
                },
                y: {
                    show: false,
                    formatter: (value) => value + "%",
                    title: {
                        formatter: () => ''
                    }
                },
                marker: {
                    show: true,
                },
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'round',
                colors: colorsArray,
                width: 2,
                dashArray: 0,
            }
        }
    });

    const [audienceAgeRange, setAudienceAgeRange] = useState({
        series: [{
            name: "rango de edades",
            data: [0, 0, 0, 0, 0],
        }],
        options: {
            chart: {
                toolbar: {
                    show: false
                }
            },
            colors: colorsArray,
            plotOptions: {
                bar: {
                    barHeight: '50%',
                    distributed: true,
                    horizontal: false,
                    borderRadius: 13,
                }
            },
            legend: {
                show: false,
                position: 'right',
                horizontalAlign: 'center',
                markers: {
                    width: 12,
                    height: 12,
                    radius: 12,
                },
            },
            dataLabels: {
                enabled: true,
                formatter: (val) => val + "%",
                style: {
                    colors: ['#15253c']
                },
                // dropShadow: {
                //     enabled: true,
                //     top: 1,
                //     left: 1,
                //     blur: 1,
                //     color: '#000',
                //     opacity: 1
                // }
            },
            xaxis: {
                type: 'category',
                categories: ['', '', '', '', ''],
                labels: {
                    show: true
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            yaxis: {
                labels: {
                    show: true
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                tickAmount: 5,
            },
            tooltip: {
                theme: 'dark',
                x: {
                    show: true,
                    formatter: (value) => "De " + value + " años"
                },
                y: {
                    show: false,
                    formatter: (value) => value + "%",
                    title: {
                        formatter: () => ''
                    }
                },
                marker: {
                    show: true,
                },
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'round',
                colors: colorsArray,
                width: 2,
                dashArray: 0,
            }
        }
    });

    useEffect(() => {
        if (genderCount.length !== 0) {
            setAudienceGenderData(oldValues => ({
                series: [{
                    name: "género",
                    data: [genderCount[0].collabFemale, genderCount[0].collabMale, genderCount[0].collabUnknown]
                }],
                options: {
                    ...oldValues.options,
                    xaxis: {
                        ...oldValues.options.xaxis,
                        categories: [`${genderCount[0].collabFemale}% mujeres`, `${genderCount[0].collabMale}% hombres`, `${genderCount[0].collabUnknown}% indefinido`]
                    }
                }
            }));
        }
        if (ageRangeCatalog.length !== 0) {
            setAudienceAgeRange(oldValues => ({
                series: [{
                    name: "rango de edades",
                    data: ageRangeCatalog.map(ageRange => ageRange.percentage),
                }],
                options: {
                    ...oldValues.options,
                    xaxis: {
                        ...oldValues.options.xaxis,
                        categories: ageRangeCatalog.map(ageRange => ageRange.description),
                    }
                }
            }));
        }
    }, [genderCount, ageRangeCatalog]);

    return (
        <div className='card reports__cards-style'>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-12 col-md-5 text-center' style={{ borderRight: "3px solid #fff" }}>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <span className='fs-6 text-blue-sec'>Audiencia total: <b>{totalAudience}</b></span>
                            </div>
                            <div className="col-12 p-0">
                                <Chart
                                    options={audienceGenderData.options}
                                    series={audienceGenderData.series}
                                    type="bar"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-7 text-center'>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <span className='fs-6 text-blue-sec'>Rango de edades</span>
                            </div>
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <div className="col-11">
                                    <Chart
                                        options={audienceAgeRange.options}
                                        series={audienceAgeRange.series}
                                        type="bar"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Audience;