import React from 'react';
import Chart from "react-apexcharts";
import { colorsArray2 } from "../../../helpers/helpers";

const QuestionTypeBinary = ({ question }) => {
    const data3 = {
        series: question.quizAnswersList.map(answer => parseInt(answer.percentAnsw)),
        options: {
            chart: {
                type: 'radialBar'
            },
            plotOptions: {
                radialBar: {
                    offsetY: 0,
                    startAngle: 0,
                    endAngle: 270,
                    track: {
                        show: true,
                        background: "#C4DCE8"
                    },
                    hollow: {
                        margin: 5,
                        size: '40%',
                        background: 'transparent',
                    },
                    dataLabels: {
                        total: {
                            show: true,
                            label: 'Total',
                            color: "#15253c",
                            formatter: function (w) {
                                const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0)
                                return total + "%"
                            }
                        },
                    }
                },
            },
            legend: {
                show: true,
                floating: true,
                position: 'left',
                horizontalAlign: 'center',
                markers: {
                    width: 12,
                    height: 12,
                    radius: 12,
                },
                offsetX: 130,
                offsetY: 15,
            },
            colors: colorsArray2,
            labels: question.quizAnswersList.map(answer => `${answer.descriptionAnswer} (${answer.percentAnsw}%)`),
            responsive: [{
                breakpoint: 1600,
                options: {
                    legend: {
                        show: true,
                        floating: true,
                        position: 'left',
                        horizontalAlign: 'center',
                        markers: {
                            width: 12,
                            height: 12,
                            radius: 12,
                        },
                        offsetX: 45,
                        offsetY: 20,
                    },
                }
            }, {
                breakpoint: 1365,
                options: {
                    legend: {
                        show: false,
                    },
                }
            }]
        }
    }
    return (
        <div className='col-12 col-md-6 p-3 mb-3 mb-md-0'>
            <p className='fs-6 text-gray fw-bold'>{question.description}</p>
            <Chart options={data3.options}
                series={data3.series}
                type="radialBar"
                height={350}
            />
        </div>
    )
}

export default QuestionTypeBinary