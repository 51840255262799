import React, { useState, Fragment, useEffect } from 'react';
// API calls
import { getSprTypeQuestion } from '../../models/catalog_model';
import { getQuizSpecific, setUpdateMarketStudies } from '../../models/quizSponsor_model';
// Components
import SurveyModal from './SurveyModal';
// Tools
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as toastr from 'toastr';
import $ from 'jquery';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';

const QuestionCreator = ({ quizSpecific, showCreator, idSurvey }) => {
    const [requestObj, setRequestObj] = useState({
        idQuizSponsor: idSurvey,
        flagEditOrder: false,
        questions: [],
    });
    const [questionObj, setQuestionObj] = useState({
        idQuestionQuiz: 0,
        idTypeQuestion: 0,
        idOrder: 0,
        description: '',
        answers: [],
    });
    const {
        idQuestionQuiz,
        idTypeQuestion,
        description,
        idOrder,
        answers,
    } = questionObj;
    const [questionsArr, setQuestionsArr] = useState([]);
    const [questionEdit, setQuestionEdit] = useState(false);
    const [surveyEdit, setSurveyEdit] = useState(false);
    const [catalogeType, setCatalogeType] = useState([]);
    const [option_1, setOption_1] = useState('');
    const [option_2, setOption_2] = useState('');
    const [option_3, setOption_3] = useState('');
    const [option_4, setOption_4] = useState('');
    const [option_5, setOption_5] = useState('');
    useEffect(() => {
        getSprTypeQuestion().then(response => {
            if (response) {
                if (response.code === 1) {
                    setCatalogeType(response.listTypeQuestion);
                }
            }
        });
        if (showCreator || quizSpecific.quizDetails.title !== undefined) {
            getQuizSpecific(quizSpecific.quizDetails.idQuizSponsor).then(response => {
                if (response) {
                    if (response.code === 1) {
                        let obj = {
                            idQuizSponsor: quizSpecific.quizDetails.idQuizSponsor,
                            flagEditOrder: true,
                            questions: response.questions,
                        };
                        setRequestObj(obj);
                        setQuestionsArr(response.questions);
                        setSurveyEdit(true);
                    }
                }
            });
        }
        // eslint-disable-next-line
    }, []);
    const resetFunc = () => {
        setQuestionObj({
            idQuestionQuiz: 0,
            idTypeQuestion: 0,
            idOrder: 0,
            description: '',
            answers: [],
        });
        setOption_1('');
        setOption_2('');
        setOption_3('');
        setOption_4('');
        setOption_5('');
        setQuestionEdit(false);
    }
    const reorder = (list, startIndex, endIndex) => {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        let counter = 1;
        for (let x in result) {
            result[x].idOrder = counter;
            for (let y in result[x].answers) {
                result[x].answers[y].idOrder = counter;
            }
            counter++;
        }
        resetFunc();
        return result;
    }
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const draggableid = result.draggableId;
        const id = draggableid.split('-');
        switch (parseInt(id[1])) {
            case 2:
                $(`#img-option-${parseInt(id[1])}`).attr('src', '../assets/img/elementos_encuestas-13.png');
                break;
            case 3:
                $(`#img-option-${parseInt(id[1])}`).attr('src', '../assets/img/elementos_encuestas-16.png');
                break;
            case 4:
                $(`#img-option-${parseInt(id[1])}`).attr('src', '../assets/img/elementos_encuestas-14.png');
                break;
            case 5:
                $(`#img-option-${parseInt(id[1])}`).attr('src', '../assets/img/elementos_encuestas-15.png');
                break;
            default:
                $(`#img-option-${parseInt(id[1])}`).attr('src', '../assets/img/elementos_encuestas-12.png');
                break;
        }
        setQuestionObj({
            ...questionObj,
            idTypeQuestion: parseInt(id[1]),
        });
    };
    const handelOptionImg = (id) => {
        let img = '';
        switch (id) {
            case 2:
                img = '../assets/img/elementos_encuestas-13.png';
                break;
            case 3:
                img = '../assets/img/elementos_encuestas-16.png';
                break;
            case 4:
                img = '../assets/img/elementos_encuestas-14.png';
                break;
            case 5:
                img = '../assets/img/elementos_encuestas-15.png';
                break;
            default:
                img = '../assets/img/elementos_encuestas-12.png';
                break;
        }
        return img;
    }
    const handleTitleQuestion = id => {
        let structure;
        switch (id) {
            case 1:
                structure = <h5 className='text-center fw-bold'>Abierta <i className='bi bi-textarea-resize' /></h5>
                break;
            case 2:
                structure = <h5 className='text-center fw-bold'>Binaria <i className='bi bi-signpost-split' /></h5>
                break;
            case 3:
                structure = <h5 className='text-center fw-bold'>Radio <i className='bi bi-ui-radios' /></h5>
                break;
            case 4:
                structure = <h5 className='text-center fw-bold'>Check <i className='bi bi-ui-checks' /></h5>
                break;
            case 5:
                structure = <h5 className='text-center fw-bold'>Fecha <i className='bi bi-calendar-date' /></h5>
                break;
            default:
                structure = '';
                break;
        }
        return structure;
    }
    return (
        <div className='col-12 mt-5 text-base'>
            <div className='row'>
                <div className='col-12'>
                    <h5>Crea tu pregunta</h5>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='list'>
                        {(provided) => (
                            <div
                                className='col-12 bg-gray py-3 mb-3 rounded-3'
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <div className='row'>
                                    <div className='col-12 mb-3'>
                                        <span className='text-base'>Elige una de las siguientes opciones y colócala en el apartado de pregunta</span>
                                    </div>
                                    {
                                        catalogeType.map((data, index) => (
                                            <Draggable
                                                key={'type-' + data.idTypeQuestion}
                                                draggableId={`draggableid-${data.idTypeQuestion}`}
                                                index={index}
                                            >
                                                {(provided, snapshot) => {
                                                    return (
                                                        <div className='col d-flex justify-content-center'>
                                                            <div
                                                                ref={provided.innerRef}
                                                                snapshot={snapshot}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className='d-flex justify-content-center'
                                                            >
                                                                <img
                                                                    id={`img-option-${data.idTypeQuestion}`}
                                                                    className='w-50 box-shadow'
                                                                    src={handelOptionImg(data.idTypeQuestion)}
                                                                    alt='options'
                                                                    onMouseDownCapture={() => {
                                                                        switch (data.idTypeQuestion) {
                                                                            case 2:
                                                                                $(`#img-option-${data.idTypeQuestion}`).attr('src', '../assets/img/elementos_encuestas-19.png');
                                                                                break;
                                                                            case 3:
                                                                                $(`#img-option-${data.idTypeQuestion}`).attr('src', '../assets/img/elementos_encuestas-17.png');
                                                                                break;
                                                                            case 4:
                                                                                $(`#img-option-${data.idTypeQuestion}`).attr('src', '../assets/img/elementos_encuestas-20.png');
                                                                                break;
                                                                            case 5:
                                                                                $(`#img-option-${data.idTypeQuestion}`).attr('src', '../assets/img/elementos_encuestas-21.png');
                                                                                break;
                                                                            default:
                                                                                $(`#img-option-${data.idTypeQuestion}`).attr('src', '../assets/img/elementos_encuestas-18.png');
                                                                                break;
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            </Draggable>
                                        ))
                                    }
                                </div>
                            </div>
                        )}
                    </Droppable>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col'>
                                <h5>Nueva pregunta</h5>
                            </div>
                            <div className='col'>
                                <h5>Cuestionario</h5>
                            </div>
                        </div>
                    </div>
                    <Droppable droppableId='list'>
                        {(provided) => (
                            <div
                                className='col-sm-12 col-md-6 col-lg-6 mb-3'
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                <div className='col-12 bg-gray p-3 rounded-3'>
                                    <div className='card shadow'>
                                        <div className='card-body'>
                                            <div className='mb-3'>
                                                {handleTitleQuestion(idTypeQuestion)}
                                            </div>
                                            {
                                                idTypeQuestion === 0 ? (
                                                    <h3 className='text-center'>Arrastra la pregunta aquí</h3>
                                                ) : (
                                                    <div className='mb-3'>
                                                        <label
                                                            htmlFor='questionDescription'
                                                            className="form-label fw-bold"
                                                        >Descripción de la pregunta:</label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-gray"
                                                            id="questionDescription"
                                                            name="questionDescription"
                                                            onChange={e => {
                                                                setQuestionObj({
                                                                    ...questionObj,
                                                                    description: e.target.value,
                                                                })
                                                            }}
                                                            value={description}
                                                        />
                                                    </div>
                                                )
                                            }
                                            {
                                                idTypeQuestion === 0 ? (
                                                    null
                                                ) : (
                                                    idTypeQuestion === 3 || idTypeQuestion === 4 ? (
                                                        <Fragment>
                                                            <div className='mb-3'>
                                                                <label
                                                                    htmlFor='option_1'
                                                                    className="form-label fw-bold"
                                                                >Opción 1:</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-gray"
                                                                    id="option_1"
                                                                    onChange={e => {
                                                                        setOption_1(e.target.value);
                                                                    }}
                                                                    value={option_1}
                                                                />
                                                            </div>
                                                            <div className='mb-3'>
                                                                <label
                                                                    htmlFor='option_2'
                                                                    className="form-label fw-bold"
                                                                >Opción 2:</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-gray"
                                                                    id="option_2"
                                                                    onChange={e => {
                                                                        setOption_2(e.target.value);
                                                                    }}
                                                                    value={option_2}
                                                                />
                                                            </div>
                                                            <div className='mb-3'>
                                                                <label
                                                                    htmlFor='option_3'
                                                                    className="form-label fw-bold"
                                                                >Opción 3:</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-gray"
                                                                    id="option_3"
                                                                    onChange={e => {
                                                                        setOption_3(e.target.value);
                                                                    }}
                                                                    value={option_3}
                                                                />
                                                            </div>
                                                            <div className='mb-3'>
                                                                <label
                                                                    htmlFor='option_4'
                                                                    className="form-label fw-bold"
                                                                >Opción 4:</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-gray"
                                                                    id="option_4"
                                                                    onChange={e => {
                                                                        setOption_4(e.target.value);
                                                                    }}
                                                                    value={option_4}
                                                                />
                                                            </div>
                                                            <div className='mb-3'>
                                                                <label
                                                                    htmlFor='option_5'
                                                                    className="form-label fw-bold"
                                                                >Opción 5:</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control form-control-gray"
                                                                    id="option_5"
                                                                    onChange={e => {
                                                                        setOption_5(e.target.value);
                                                                    }}
                                                                    value={option_5}
                                                                />
                                                            </div>
                                                            {
                                                                questionEdit ? (
                                                                    <div className='col-12 d-flex justify-content-between'>
                                                                        <button className='btn bg-blue-navy text-white'
                                                                            onClick={() => {
                                                                                if (description === '' || description.trim().length === 0) {
                                                                                    toastr.warning('Es necesario escribir algo en la descripción de la pregunta.', '¡Ooops!');
                                                                                    return;
                                                                                }
                                                                                if (questionsArr.length === 10) {
                                                                                    toastr.warning('Solo se puede agregar 10 preguntas al cuestionario.', '¡Ooops!');
                                                                                    return;
                                                                                }
                                                                                // Create a copy of current obj selected
                                                                                let obj = {
                                                                                    ...questionObj,
                                                                                    idQuestionQuiz: surveyEdit === true ? (idQuestionQuiz) : (0),
                                                                                    idTypeQuestion: parseInt(idTypeQuestion),
                                                                                }
                                                                                let counter = 0;
                                                                                let ansArr = [];
                                                                                let ansObj = {};
                                                                                let allAns = [
                                                                                    option_1,
                                                                                    option_2,
                                                                                    option_3,
                                                                                    option_4,
                                                                                    option_5,
                                                                                ];
                                                                                // Itearation in current answer array
                                                                                if (surveyEdit) {
                                                                                    for (let x in allAns) {
                                                                                        // Organice answers by ascendant order
                                                                                        if (allAns[x] !== '') {
                                                                                            ansObj = {
                                                                                                idOrder: counter,
                                                                                                idAnswerQuiz: answers.length < counter + 1 ? (0) : (answers[x].idAnswerQuiz),
                                                                                                description: allAns[x],
                                                                                            }
                                                                                            counter++;
                                                                                            ansArr.push(ansObj);
                                                                                        }
                                                                                        ansObj = {};
                                                                                    }
                                                                                } else {
                                                                                    for (let x in allAns) {
                                                                                        // Organice answers by ascendant order
                                                                                        if (allAns[x] !== '') {
                                                                                            ansObj = {
                                                                                                idOrder: counter,
                                                                                                idAnswerQuiz: 0,
                                                                                                description: allAns[x],
                                                                                            }
                                                                                            counter++;
                                                                                            ansArr.push(ansObj);
                                                                                        }
                                                                                        ansObj = {};
                                                                                    }
                                                                                }
                                                                                // Validate that at least 2 options must be fill it
                                                                                if (ansArr.length < 2) {
                                                                                    toastr.warning('Debes llenar por lo menos 2 opciones.', '¡Ooops!');
                                                                                    return;
                                                                                }
                                                                                // Fill answers array
                                                                                obj = {
                                                                                    ...obj,
                                                                                    answers: ansArr,
                                                                                }
                                                                                // Update obj
                                                                                let arr = questionsArr;
                                                                                if (idOrder === 1) {
                                                                                    arr.splice(0, 1, obj);
                                                                                } else {
                                                                                    arr.splice(idOrder - 1, 1, obj);
                                                                                }
                                                                                setQuestionsArr(arr);
                                                                                toastr.info('Se agregó la pregunta al formulario.', '¡Éxito!');
                                                                                // Reset form
                                                                                resetFunc();
                                                                            }}
                                                                        >Actualizar</button>
                                                                        <button className='btn bg-blue-navy text-white'
                                                                            onClick={() => resetFunc()}
                                                                        >Cancelar</button>
                                                                    </div>
                                                                ) : (
                                                                    <button className='btn bg-blue-navy text-white'
                                                                        onClick={() => {
                                                                            if (description === '' || description.trim().length === 0) {
                                                                                toastr.warning('Es necesario escribir algo en la descripción de la pregunta.', '¡Ooops!');
                                                                                return;
                                                                            }
                                                                            if (questionsArr.length === 10) {
                                                                                toastr.warning('Solo se puede agregar 10 preguntas al cuestionario.', '¡Ooops!');
                                                                                return;
                                                                            }
                                                                            // Create a copy of current obj
                                                                            let obj = {
                                                                                ...questionObj,
                                                                                idQuestionQuiz: 0,
                                                                                idTypeQuestion: parseInt(idTypeQuestion),
                                                                            }
                                                                            // Asisign an order id
                                                                            /* let currentIDOrder = 0; */
                                                                            if (questionsArr.length === 0) {
                                                                                obj = {
                                                                                    ...obj,
                                                                                    idOrder: 1,
                                                                                };
                                                                                /* currentIDOrder = 1; */
                                                                            } else {
                                                                                obj = {
                                                                                    ...obj,
                                                                                    idOrder: questionsArr.length + 1,
                                                                                };
                                                                                /* currentIDOrder = questionsArr.length + 1; */
                                                                            }
                                                                            let counter = 0;
                                                                            let ansArr = [];
                                                                            let ansObj = {};
                                                                            let allAns = [
                                                                                option_1,
                                                                                option_2,
                                                                                option_3,
                                                                                option_4,
                                                                                option_5,
                                                                            ];
                                                                            // Itearation in current answer array
                                                                            for (let x in allAns) {
                                                                                // Organice answers by ascendant order
                                                                                if (allAns[x] !== '') {
                                                                                    ansObj = {
                                                                                        idOrder: counter,
                                                                                        idAnswerQuiz: 0,
                                                                                        description: allAns[x],
                                                                                    }
                                                                                    counter++;
                                                                                    ansArr.push(ansObj);
                                                                                }
                                                                                ansObj = {};
                                                                            }
                                                                            // Validate that at least 2 options must be fill it
                                                                            if (ansArr.length < 2) {
                                                                                toastr.warning('Debes llenar por lo menos 2 opciones.', '¡Ooops!');
                                                                                return;
                                                                            }
                                                                            // Fill answers array
                                                                            obj = {
                                                                                ...obj,
                                                                                answers: ansArr,
                                                                            }
                                                                            let arr = questionsArr;
                                                                            arr.push(obj);
                                                                            // Update curreny questions array
                                                                            setQuestionsArr(arr);
                                                                            toastr.info('Se agregó la pregunta al formulario.', '¡Éxito!');
                                                                            // Reset form
                                                                            resetFunc();
                                                                        }}
                                                                    >Guardar</button>
                                                                )
                                                            }
                                                        </Fragment>
                                                    ) : (
                                                        idTypeQuestion === 2 ? (
                                                            <Fragment>
                                                                <div className='mb-3'>
                                                                    <label
                                                                        htmlFor='option_1'
                                                                        className="form-label fw-bold"
                                                                    >Opción 1:</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control form-control-gray"
                                                                        id="option_1"
                                                                        onChange={e => {
                                                                            setOption_1(e.target.value);
                                                                        }}
                                                                        value={option_1}
                                                                    />
                                                                </div>
                                                                <div className='mb-3'>
                                                                    <label
                                                                        htmlFor='option_2'
                                                                        className="form-label fw-bold"
                                                                    >Opción 2:</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control form-control-gray"
                                                                        id="option_2"
                                                                        onChange={e => {
                                                                            setOption_2(e.target.value);
                                                                        }}
                                                                        value={option_2}
                                                                    />
                                                                </div>

                                                                {
                                                                    questionEdit ? (
                                                                        <div className='col-12 d-flex justify-content-between'>
                                                                            <button className='btn bg-blue-navy text-white'
                                                                                onClick={() => {
                                                                                    if (description === '' || description.trim().length === 0) {
                                                                                        toastr.warning('Es necesario escribir algo en la descripción de la pregunta.', '¡Ooops!');
                                                                                        return;
                                                                                    }
                                                                                    // Validate that at least 2 options must be fill it
                                                                                    if (option_1 === '' || option_2 === '' || option_1.trim().length === 0 || option_2.trim().length === 0) {
                                                                                        toastr.warning('Debes llenar las 2 opciones.', '¡Ooops!');
                                                                                        return;
                                                                                    }
                                                                                    if (questionsArr.length === 10) {
                                                                                        toastr.warning('Solo se puede agregar 10 preguntas al cuestionario.', '¡Ooops!');
                                                                                        return;
                                                                                    }
                                                                                    // Create a copy of current obj selected
                                                                                    let obj = {
                                                                                        ...questionObj,
                                                                                        idQuestionQuiz: surveyEdit ? (idQuestionQuiz) : (0),
                                                                                        idTypeQuestion: parseInt(idTypeQuestion),
                                                                                    }
                                                                                    let counter = 1;
                                                                                    let ansArr = [];
                                                                                    let ansObj = {};
                                                                                    let allAns = [
                                                                                        option_1,
                                                                                        option_2,
                                                                                    ];
                                                                                    // Itearation in current answer array
                                                                                    if (surveyEdit) {
                                                                                        for (let x in allAns) {
                                                                                            // Organice answers by ascendant order
                                                                                            if (allAns[x] !== '') {
                                                                                                ansObj = {
                                                                                                    idOrder: counter,
                                                                                                    idAnswerQuiz: answers[x].idAnswerQuiz,
                                                                                                    description: allAns[x],
                                                                                                }
                                                                                                counter++;
                                                                                                ansArr.push(ansObj);
                                                                                            }
                                                                                            ansObj = {};
                                                                                        }
                                                                                    } else {
                                                                                        for (let x in allAns) {
                                                                                            // Organice answers by ascendant order
                                                                                            if (allAns[x] !== '') {
                                                                                                ansObj = {
                                                                                                    idOrder: counter,
                                                                                                    idAnswerQuiz: 0,
                                                                                                    description: allAns[x],
                                                                                                }
                                                                                                counter++;
                                                                                                ansArr.push(ansObj);
                                                                                            }
                                                                                            ansObj = {};
                                                                                        }
                                                                                    }
                                                                                    // Fill answers array
                                                                                    obj = {
                                                                                        ...obj,
                                                                                        answers: ansArr,
                                                                                    }
                                                                                    // Update obj
                                                                                    let arr = questionsArr;
                                                                                    if (idOrder === 1) {
                                                                                        arr.splice(0, 1, obj);
                                                                                    } else {
                                                                                        arr.splice(idOrder - 1, 1, obj);
                                                                                    }
                                                                                    setQuestionsArr(arr);
                                                                                    toastr.info('Se agregó la pregunta al formulario.', '¡Éxito!');
                                                                                    // Reset form
                                                                                    resetFunc();
                                                                                }}
                                                                            >Actualizar</button>
                                                                            <button className='btn bg-blue-navy text-white'
                                                                                onClick={() => resetFunc()}
                                                                            >Cancelar</button>
                                                                        </div>
                                                                    ) : (
                                                                        <button className='btn bg-blue-navy text-white'
                                                                            onClick={() => {
                                                                                if (description === '' || description.trim().length === 0) {
                                                                                    toastr.warning('Es necesario escribir algo en la descripción de la pregunta.', '¡Ooops!');
                                                                                    return;
                                                                                }
                                                                                // Validate that at least 2 options must be fill it
                                                                                if (option_1 === '' || option_2 === '' || option_1.trim().length === 0 || option_2.trim().length === 0) {
                                                                                    toastr.warning('Debes llenar las 2 opciones.', '¡Ooops!');
                                                                                    return;
                                                                                }
                                                                                if (questionsArr.length === 10) {
                                                                                    toastr.warning('Solo se puede agregar 10 preguntas al cuestionario.', '¡Ooops!');
                                                                                    return;
                                                                                }
                                                                                // Create a copy of current obj
                                                                                let obj = {
                                                                                    ...questionObj,
                                                                                    idQuestionQuiz: 0,
                                                                                    idTypeQuestion: parseInt(idTypeQuestion),
                                                                                }
                                                                                // Asisign an order id
                                                                                /* let currentIDOrder = 0; */
                                                                                if (questionsArr.length === 0) {
                                                                                    obj = {
                                                                                        ...obj,
                                                                                        idOrder: 1,
                                                                                    };
                                                                                    /* currentIDOrder = 1; */
                                                                                } else {
                                                                                    obj = {
                                                                                        ...obj,
                                                                                        idOrder: questionsArr.length + 1,
                                                                                    };
                                                                                    /* currentIDOrder = questionsArr.length + 1; */
                                                                                }
                                                                                let counter = 1;
                                                                                let ansArr = [];
                                                                                let ansObj = {};
                                                                                let allAns = [
                                                                                    option_1,
                                                                                    option_2,
                                                                                ];
                                                                                // Itearation in current answer array
                                                                                for (let x in allAns) {
                                                                                    // Organice answers by ascendant order
                                                                                    if (allAns[x] !== '') {
                                                                                        ansObj = {
                                                                                            /* idOrder: currentIDOrder, */
                                                                                            idOrder: counter,
                                                                                            idAnswerQuiz: 0,
                                                                                            description: allAns[x],
                                                                                        }
                                                                                        counter++;
                                                                                        ansArr.push(ansObj);
                                                                                    }
                                                                                    ansObj = {};
                                                                                }
                                                                                // Fill answers array
                                                                                obj = {
                                                                                    ...obj,
                                                                                    answers: ansArr,
                                                                                }
                                                                                let arr = questionsArr;
                                                                                arr.push(obj);
                                                                                // Update curreny questions array
                                                                                setQuestionsArr(arr);
                                                                                toastr.info('Se agregó la pregunta al formulario.', '¡Éxito!');
                                                                                // Reset form
                                                                                resetFunc();
                                                                            }}
                                                                        >Guardar</button>
                                                                    )
                                                                }
                                                            </Fragment>
                                                        ) : (
                                                            questionEdit ? (
                                                                <div className='col-12 d-flex justify-content-between'>
                                                                    <button className='btn bg-blue-navy text-white'
                                                                        onClick={() => {
                                                                            if (description === '' || description.trim().length === 0) {
                                                                                toastr.warning('Es necesario escribir algo en la descripción de la pregunta.', '¡Ooops!');
                                                                                return;
                                                                            }
                                                                            if (questionsArr.length === 10) {
                                                                                toastr.warning('Solo se puede agregar 10 preguntas al cuestionario.', '¡Ooops!');
                                                                                return;
                                                                            }
                                                                            let obj = {
                                                                                ...questionObj,
                                                                                idQuestionQuiz: surveyEdit ? (idQuestionQuiz) : (0),
                                                                                idTypeQuestion: parseInt(idTypeQuestion),
                                                                                answers: [],
                                                                            }
                                                                            let arr = questionsArr;
                                                                            if (idOrder === 1) {
                                                                                arr.splice(0, 1, obj);
                                                                            } else {
                                                                                arr.splice(idOrder - 1, 1, obj);
                                                                            }
                                                                            setQuestionsArr(arr);
                                                                            toastr.info('Se agregó la pregunta al formulario.', '¡Éxito!');
                                                                            resetFunc();
                                                                        }}
                                                                    >Actualizar</button>
                                                                    <button className='btn bg-blue-navy text-white'
                                                                        onClick={() => resetFunc()}
                                                                    >Cancelar</button>
                                                                </div>
                                                            ) : (
                                                                <button className='btn bg-blue-navy text-white'
                                                                    onClick={() => {
                                                                        if (description === '' || description.trim().length === 0) {
                                                                            toastr.warning('Es necesario escribir algo en la descripción de la pregunta.', '¡Ooops!');
                                                                            return;
                                                                        }
                                                                        if (questionsArr.length === 10) {
                                                                            toastr.warning('Solo se puede agregar 10 preguntas al cuestionario.', '¡Ooops!');
                                                                            return;
                                                                        }
                                                                        let obj = {
                                                                            ...questionObj,
                                                                            idQuestionQuiz: 0,
                                                                            idTypeQuestion: parseInt(idTypeQuestion),
                                                                            answers: [],
                                                                        }
                                                                        if (questionsArr.length === 0) {
                                                                            obj = {
                                                                                ...obj,
                                                                                idOrder: 1,
                                                                            }
                                                                        } else {
                                                                            obj = {
                                                                                ...obj,
                                                                                idOrder: questionsArr.length + 1,
                                                                            }
                                                                        }
                                                                        let arr = questionsArr;
                                                                        arr.push(obj);
                                                                        setQuestionsArr(arr);
                                                                        toastr.info('Se agregó la pregunta al formulario.', '¡Éxito!');
                                                                        resetFunc();
                                                                    }}
                                                                >Guardar</button>
                                                            )
                                                        )
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div className='col-sm-12 col-md-6 col-lg-6 mb-3 d-flex justify-content-end'>
                    <div className='col-12 bg-gray p-3 rounded-3'>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-between mb-3'>
                                <button
                                    className={questionsArr.length === 0 ? ('invisible') : ('btn bg-blue-navy text-white btn-sm')}
                                    onClick={() => {
                                        Swal.fire({
                                            title: '¿Estás seguro?',
                                            text: "Al limpiar el formulario no se podrá recuperar otra vez.",
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#af2f72',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: 'Si, limpiar',
                                            cancelButtonText: 'Cancelar'
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                setQuestionsArr([]);
                                                resetFunc();
                                                Swal.fire(
                                                    '¡Limpieza!',
                                                    'Tu formulario fue limpiado.',
                                                    'success'
                                                )
                                            }
                                        })
                                    }}
                                >Limpiar</button>
                                <button
                                    className={questionsArr.length === 0 ? ('invisible') : ('btn bg-blue-navy text-white btn-sm')}
                                    onClick={() => $("#surveyModal").show()}
                                >Vista previa</button>
                                <button
                                    className='btn bg-pink-guay text-white btn-sm'
                                    onClick={() => {
                                        $('#submitBtn_2').attr('type', 'button')
                                        $('#submitBtn_2').attr('disabled', '')
                                        if (questionsArr.length > 0) {
                                            let data = {
                                                ...requestObj,
                                                idQuizSponsor: idSurvey === 0 ? (quizSpecific.quizDetails.idQuizSponsor) : (idSurvey),
                                                questions: questionsArr,
                                            };
                                            const formData = new FormData();
                                            formData.append('request', JSON.stringify(data));
                                            setUpdateMarketStudies(formData).then(response => {
                                                if (response) {
                                                    if (response.code === 1) {
                                                        if (surveyEdit) {
                                                            toastr.success('Se creó el estudio de mercado correctamente.', '¡Éxito!');
                                                        } else {
                                                            toastr.success('Se actualizó el estudio de mercado correctamente.', '¡Éxito!');
                                                        }
                                                    }
                                                    $('#submitBtn_2').attr('type', 'submit')
                                                    $('#submitBtn_2').removeAttr('disabled')
                                                }
                                            });
                                        } else {
                                            toastr.warning('Debes tener por lo menos 1 pregunta para crear un cuestionario.', '¡Ooops!');
                                            $('#submitBtn_2').attr('type', 'submit')
                                            $('#submitBtn_2').removeAttr('disabled')
                                        }
                                    }}
                                    id='submitBtn_2'
                                >{surveyEdit ? ('Guardar formulario') : ('Crear formulario')}</button>
                            </div>
                            <SurveyModal
                                questionsArr={questionsArr}
                                titleName={quizSpecific.quizDetails.title}
                            />
                            <DragDropContext onDragEnd={(result) => {
                                const { source, destination } = result;
                                if (!destination) {
                                    return;
                                }
                                if (
                                    source.index === destination.index &&
                                    source.droppableId === destination.droppableId
                                ) {
                                    return;
                                }
                                setQuestionsArr((prevQuestionArr) => reorder(prevQuestionArr, source.index, destination.index));
                            }}>
                                <Droppable droppableId='list'>
                                    {
                                        (droppableProvided) => (
                                            <div
                                                {...droppableProvided.droppableProps}
                                                ref={droppableProvided.innerRef}
                                            >
                                                {
                                                    questionsArr.map((data, index) => (
                                                        <Draggable
                                                            key={'question-' + index}
                                                            draggableId={'id-' + index}
                                                            index={index}
                                                        >
                                                            {
                                                                (draggableProvided) => (
                                                                    <div
                                                                        className='col-12 mb-3'
                                                                        {...draggableProvided.draggableProps}
                                                                        ref={draggableProvided.innerRef}
                                                                        {...draggableProvided.dragHandleProps}
                                                                    >
                                                                        <div className='card shadow'>
                                                                            <div className='card-body'>
                                                                                <div className='row'>
                                                                                    <div className='col-sm-12 col-md-10 col-lg-10'>
                                                                                        <div className='row'>
                                                                                            <div className='col-12'>
                                                                                                <span className='fw-bold'>{data.description}</span>
                                                                                            </div>
                                                                                            {
                                                                                                data.answers.length > 0 ? (
                                                                                                    data.answers.map((answer, index) => (
                                                                                                        <div className='col-12'>
                                                                                                            <span><span className='fw-bold'>{index === 0 ? ('a) ') : (index === 1 ? ('b) ') : (index === 2 ? ('c) ') : (index === 3 ? ('d) ') : (index === 4 ? ('e) ') : (null)))))}</span>{answer.description}</span>
                                                                                                        </div>
                                                                                                    ))
                                                                                                ) : (
                                                                                                    null
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-sm-12 col-md-2 col-lg-2 d-flex justify-content-around flex-wrap align-content-center'>
                                                                                        <i className='bi bi-arrows-move pointer' />
                                                                                        <div class="dropdown dropstart text-end">
                                                                                            <i className='bi bi-caret-down-fill pointer' data-bs-toggle="dropdown" />
                                                                                            <ul class="dropdown-menu">
                                                                                                <li
                                                                                                    className='dropdown-item pointer'
                                                                                                    onClick={() => {
                                                                                                        setQuestionEdit(true);
                                                                                                        setQuestionObj({
                                                                                                            idQuestionQuiz: data.idQuestionQuiz,
                                                                                                            idTypeQuestion: data.idTypeQuestion,
                                                                                                            idOrder: data.idOrder,
                                                                                                            description: data.description,
                                                                                                            answers: data.answers,
                                                                                                        });
                                                                                                        if (data.idTypeQuestion === 2) {
                                                                                                            for (let x in data.answers) {
                                                                                                                if (parseInt(x) === 0) {
                                                                                                                    setOption_1(data.answers[x].description);
                                                                                                                }
                                                                                                                if (parseInt(x) === 1) {
                                                                                                                    setOption_2(data.answers[x].description);
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                        if (data.idTypeQuestion === 3 || data.idTypeQuestion === 4) {
                                                                                                            for (let x in data.answers) {
                                                                                                                if (parseInt(x) === 0) {
                                                                                                                    setOption_1(data.answers[x].description);
                                                                                                                }
                                                                                                                if (parseInt(x) === 1) {
                                                                                                                    setOption_2(data.answers[x].description);
                                                                                                                }
                                                                                                                if (parseInt(x) === 2) {
                                                                                                                    setOption_3(data.answers[x].description);
                                                                                                                }
                                                                                                                if (parseInt(x) === 3) {
                                                                                                                    setOption_4(data.answers[x].description);
                                                                                                                }
                                                                                                                if (parseInt(x) === 4) {
                                                                                                                    setOption_5(data.answers[x].description);
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }}
                                                                                                >Editar <i className='bi bi-pencil float-end' /></li>
                                                                                                <li
                                                                                                    className='dropdown-item pointer'
                                                                                                    onClick={() => {
                                                                                                        Swal.fire({
                                                                                                            title: '¿Estás seguro?',
                                                                                                            text: "Al eliminar la pregunta no podrás recuperarla otra vez.",
                                                                                                            icon: 'warning',
                                                                                                            showCancelButton: true,
                                                                                                            confirmButtonColor: '#af2f72',
                                                                                                            cancelButtonColor: '#d33',
                                                                                                            confirmButtonText: 'Si, eliminar',
                                                                                                            cancelButtonText: 'Cancelar'
                                                                                                        }).then((result) => {
                                                                                                            if (result.isConfirmed) {
                                                                                                                let arr = questionsArr;
                                                                                                                arr.splice(index, 1);
                                                                                                                for (let x in arr) {
                                                                                                                    arr[x].idOrder = parseInt(x) + 1;
                                                                                                                }
                                                                                                                setQuestionsArr(arr);
                                                                                                                resetFunc();
                                                                                                                Swal.fire(
                                                                                                                    '¡Eliminado!',
                                                                                                                    'Tu pregunta fue eliminada.',
                                                                                                                    'success'
                                                                                                                )
                                                                                                            }
                                                                                                        })
                                                                                                    }}
                                                                                                >Eliminar <i className='bi bi-trash2 float-end' /></li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </Draggable>
                                                    ))
                                                }
                                                {droppableProvided.placeholder}
                                            </div>
                                        )
                                    }
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ quizSpecific }) => {
    return { quizSpecific }
}

export default connect(mapStateToProps)(QuestionCreator);
