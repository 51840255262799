import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import authReducer, { restoreSessionAction } from "./authDuck";
import profileReducer, { restoreProfileAction } from "./profileDuck";
import navigationReducer from './navigationDuck';
import quizReducer from "./quizDuck";

let rootReducer = combineReducers({
    user: authReducer,
    profile: profileReducer,
    quizSpecific: quizReducer,
    navigation: navigationReducer,
});

//TODO: Cambiar por productivo

//dev
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//prod
const composeEnhancers = compose;

const generateStore = () => {
    const store = createStore(
        rootReducer,
        composeEnhancers(
            applyMiddleware(thunk)
        )
    );
    //restablecer session en refresh
    restoreSessionAction()(store.dispatch)
    restoreProfileAction()(store.dispatch)
    return store;
}

export default generateStore;