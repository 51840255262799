import React from 'react';
import Chart from "react-apexcharts";
import { colorsArray2, returnMonthBasedOnNumber } from "../../../helpers/helpers";

const QuestionTypeDate = ({ question }) => {
    const data4 = {
        series: [
            {
                name: question.quizAnswersList[0].descriptionAnswer,
                data: question.quizAnswersList[0].responses.map(response => {
                    const dates = response.date.split("-");
                    return {
                        y: dates[1],
                        x: response.date,
                        z: parseInt(response.numDate * 10)
                    }
                })
            }],
        options: {
            chart: {
                height: 350,
                type: 'bubble',
                toolbar: {
                    show: true,
                    tools: {
                        download: false,
                        selection: true,
                        zoomin: false,
                        zoomout: true,
                        zoom: true,
                        pan: false,
                        reset: '<i class="bi bi-arrow-counterclockwise fs-3 fw-bold"></i>',
                        customIcons: []
                    },
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                type: 'datetime',
            },
            yaxis: {
                min: 1,
                max: 12,
                labels: {
                    show: true,
                    formatter: (value) => returnMonthBasedOnNumber(Math.ceil(value)),
                },
            },
            colors: colorsArray2,
            tooltip: {
                theme: 'dark',
                x: {
                    show: false,
                },
                y: {
                    show: false,
                    formatter: (val, opts) => {
                        return `mes: ${returnMonthBasedOnNumber(val)} fecha: ${opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].x}`
                    },
                    title: {
                        formatter: () => ""
                    },
                },
                z: {
                    formatter: (val) => val / 10,
                    title: 'Número de personas: '
                },
                marker: {
                    show: true,
                },
            }
        },
    }
    return (
        <div className='col-12 col-md-6 p-3 mb-3 mb-md-0'>
            <p className='fs-6 text-gray fw-bold'>{question.description}</p>
            <Chart
                options={data4.options}
                series={data4.series}
                type="bubble"
                height={350}
            />
        </div>
    )
}

export default QuestionTypeDate