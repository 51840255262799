import React, { useState, useEffect } from 'react';
// API calls
import { getSprProfilebyUser } from '../../models/sprSponsor_model';
import { getSponsorProfile } from '../../models/sponsor_model';
// Tools
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as toastr from 'toastr';
import { doSetProfileAction } from '../../redux/profileDuck';
import { isEmptyObject } from 'jquery';

const Overview = ({ user, profile, doSetProfileAction }) => {
    const { userId } = user;
    const [accountObj, setAccountObj] = useState({
        name: '',
        email: '',
    });
    const { name, email } = accountObj;
    const [marca, setMarca] = useState({
        legalName: '',
        name: '',
        webPage: '',
    });
    const {
        legalName,
        orgName,
        webPage,
    } = marca;
    useEffect(() => {
        getSprProfilebyUser(userId).then((response) => {
            setAccountObj({
                name: response.userInfo[0].name + ' ' + response.userInfo[0].last_name,
                email: response.userInfo[0].email,
            });
        });
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        getSponsorProfile(userId).then((response) => {
            switch (response?.code) {
                case 1:
                    if (response.listProfile.length > 0) {
                        if (isEmptyObject(profile)) {
                            doSetProfileAction(response.listProfile[0]);
                            setMarca({
                                legalName: response.listProfile[0].legalName,
                                orgName: response.listProfile[0].organization,
                                webPage: response.listProfile[0].webPage,
                            });
                        } else {
                            for (let x in response.listProfile) {
                                if (response.listProfile[x].idSponsorProfile === profile.idSponsorProfile) {
                                    doSetProfileAction(response.listProfile[x]);
                                }
                            }
                            setMarca({
                                legalName: profile.legalName,
                                orgName: profile.organization,
                                webPage: profile.webPage,
                            });
                        }
                    }
                    break;
                case 1000:
                    toastr.error("No se encontró la información del contacto");
                    break;
                default:
                    toastr.error("Ocurrió un error inesperado");
                    break;
            }
        });
        // eslint-disable-next-line
    }, []);
    return (
        <div className="col-11 mx-auto my-4">
            <div className="row">
                <div className='col-12 my-4'>
                    <b className='fs-5 text-blue-sec'>Overview</b>
                </div>
                <div className='col-12 mb-3'>
                    <div className='card bg-white_translucid-verylow shadow presence__show-container container-1'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-12'>
                                    <b className='text-yellow'>Suscripción</b>
                                </div>
                                <div className='col-12 mb-3'>
                                    <span className='text-gray-lighten'>Puedes ver el detalle de tu plan y subscripción</span>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col'>
                                        <div className="row align-items-start">
                                            <div className='col-5'>
                                                <span className='text-gray fw-bold'>Plan actual</span>
                                            </div>
                                            <div className='col-7 bg-yellow-opacity text-center rounded'>
                                                <span className='text-yellow fw-bold'>{"Estándar"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className="row align-items-start">
                                            <div className='col-5'>
                                                <span className='text-gray fw-bold'>Fecha de subscripción</span>
                                            </div>
                                            <div className='col-7 bg-yellow-opacity text-center rounded'>
                                                <span className='text-yellow fw-bold'>{"1/10/10"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className="row align-items-start">
                                            <div className='col-5'>
                                                <span className='text-gray fw-bold'>Costo mensual</span>
                                            </div>
                                            <div className='col-7 bg-yellow-opacity text-center rounded'>
                                                <span className='text-yellow fw-bold'>{"$$$$$$"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='col d-flex align-items-center'>
                                        <div className="row w-100">
                                            <div className='col-5'>
                                                <span className='text-gray fw-bold'>Costo mensual</span>
                                            </div>
                                            <div className='col-7 bg-yellow-opacity d-flex align-items-center rounded'>
                                                <span className='text-yellow fw-bold'>{ "$$$$$$" }</span>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className='col'>
                                        <div className="row align-items-start">
                                            <div className='col-5'>
                                                <span className='text-gray fw-bold'>Plan actual</span>
                                            </div>
                                            <div className='col-7 bg-yellow-opacity text-center rounded'>
                                                <span className='text-yellow fw-bold'>{"Audiencia"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <Link to="/"><span className='text-green float-end'>Ver más <i className="bi bi-plus-circle-fill"></i></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 mb-3'>
                    <div className='card bg-white_translucid-verylow shadow presence__show-container container-2'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-12'>
                                    <b className='text-green'>Configuración de cuenta</b>
                                </div>
                                <div className='col-12 mb-3'>
                                    <span className='text-gray-lighten'>Puedes editar tu perfil</span>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col'>
                                        <div className="row align-items-start">
                                            <div className='col-4'>
                                                <span className='text-gray fw-bold'>Nombre</span>
                                            </div>
                                            <div className='col-8 bg-green-opacity text-center rounded'>
                                                <span className='text-green fw-bold'>{name}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className="row align-items-start">
                                            <div className='col-3 pe-0'>
                                                <span className='text-gray fw-bold'>Correo</span>
                                            </div>
                                            <div className='col-9 bg-green-opacity text-center rounded'>
                                                <span className='text-green fw-bold'>{email}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className="row align-items-start">
                                            <div className='col-4'>
                                                <span className='text-gray fw-bold'>Constraseña</span>
                                            </div>
                                            <div className='col-8 bg-green-opacity text-center rounded'>
                                                <span className='text-green fw-bold'>{"************"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <Link to="general-account"><span className='text-green float-end'>Ver más <i className="bi bi-plus-circle-fill"></i></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 mb-3'>
                    <div className='card bg-white_translucid-verylow shadow presence__show-container container-3'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-12'>
                                    <b className='text-cian'>Configuración de marca</b>
                                </div>
                                <div className='col-12 mb-3'>
                                    <span className='text-gray-lighten'>Puedes editar tu perfil</span>
                                </div>
                                <div className='row mb-3'>
                                    <div className='col'>
                                        <div className="row align-items-start">
                                            <div className='col-4'>
                                                <span className='text-gray fw-bold'>Razón social</span>
                                            </div>
                                            <div className='col-8 bg-cian-opacity text-center rounded'>
                                                <span className='text-cian fw-bold'>{legalName}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className="row align-items-start">
                                            <div className='col-3'>
                                                <span className='text-gray fw-bold'>Marca</span>
                                            </div>
                                            <div className='col-9 bg-cian-opacity text-center rounded'>
                                                <span className='text-cian fw-bold'>{orgName}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className="row align-items-start">
                                            <div className='col-4'>
                                                <span className='text-gray fw-bold'>Página web</span>
                                            </div>
                                            <div className='col-8 bg-cian-opacity text-center rounded'>
                                                <span className='text-cian fw-bold'>{webPage}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <Link to="profile"><span className='text-green float-end'>Ver más <i className="bi bi-plus-circle-fill"></i></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='col-sm-11 col-md-6 col-lg-6 mb-3'>
                    <div className='card shadow presence__show-container container-3'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-12'>
                                    <h3>Configuración de recursos</h3>
                                </div>
                                <div className='col-12 py-3'>
                                    <span className='text-base'>Aquí podrás agregar y modificar: Banners, anuncios de comunidad general, promociones y anuncios para la comunidad administradora...</span>
                                </div>
                                <div className='col-12'>
                                    <Link to="configuration"><span className='float-end'>Ver más</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

const mapStateToProps = ({ user, profile }) => {
    return {
        user,
        profile
    }
}

export default connect(mapStateToProps, { doSetProfileAction })(Overview);
