import { login } from "../models/sprUser_model";
import { changeNavigationSelected } from "./navigationDuck";
import { getTokenF } from "../firebase-messaging-sw";


//const
const LOGIN = 'LOGIN';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_ERROR = 'LOGIN_ERROR';

const COMPLETE = 'COMPLETE';

const LOGOUT = 'LOGOUT';

const initialState = {
    logged: false,
    fetching: false,
}

// reducer
const authReducer = (state = initialState, action) => {

    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                fetching: true,
                error: false
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                fetching: false,
                logged: true,
                ...action.payload
            }
        case LOGIN_ERROR:
            return {
                ...state,
                fetching: false,
                logged: false,
                error: action.payload,
            }
        case COMPLETE:
            return {
                ...state,
                fetching: false,
                logged: true,
                ...action.payload
            }
        case LOGOUT:
            return {
                ...initialState
            }

        default:
            return state;
    }

}

export default authReducer;

//auxiliar
const saveStorage = function (user) {
    sessionStorage.setItem('user', btoa(JSON.stringify(user)));
}

const removeStorage = function () {
    sessionStorage.removeItem('user');
}

//actions
export const restoreSessionAction = () => (dispatch) => {
    if (sessionStorage.getItem('user') !== null) {
        const user = JSON.parse(atob(sessionStorage.getItem('user')));
        if (user && user.userId) {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: user
            })
        }
    }
}

export const doLoginAction = (email, password) => (dispatch, getState) => {
    dispatch({
        type: LOGIN
    })
    login(email, password).then((response) => {
        switch (response?.code) {
            case 1:
                if (!response.isAdmin) {
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: {
                            email,
                            userId: response.id,
                            isAdmin: response.isAdmin
                        }
                    });
                    saveStorage(getState().user);
                    getTokenF();
                    changeNavigationSelected('inicio')(dispatch);
                } else {
                    dispatch({
                        type: LOGIN_ERROR,
                        payload: {
                            message: "No tiene permisos de acceso."
                        }
                    });
                }
                break;
            case 1000:
                dispatch({
                    type: LOGIN_ERROR,
                    payload: {
                        message: "No se encuentra registrado, cheque el estatus de su registro."
                    }
                });
                break;
            case 1001:
                dispatch({
                    type: LOGIN_ERROR,
                    payload: {
                        message: "Asegúrese de haber ingresado sus credenciales correctamente."
                    }
                });
                break;
            default:
                dispatch({
                    type: LOGIN_ERROR,
                    payload: {
                        message: "ocurrió un error inesperado, verifique su conexión a internet."
                    }
                });
                break;
        }
    });
}

export const doLogOutAction = () => (dispatch, _getState) => {
    dispatch({
        type: LOGOUT
    });
    removeStorage();
}