import React, { Fragment } from 'react';
import { useTable, usePagination } from 'react-table';

function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        /* pageCount, */
        gotoPage,
        nextPage,
        previousPage,
        /* setPageSize, */
        state: { pageIndex, /* pageSize */ },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 5 },
        },
        usePagination
    )
    // Render the UI for your table
    return (
        <Fragment>
            <div className='col-12 generic-table'>
                <table {...getTableProps()} className='table table-striped table-hover text-base'>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()} className='text-center'>{column.render('Header')}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()} className='text-center'>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {
                data.length > 0 ? (
                    <div className=' my-3'>
                        <ul className="pagination justify-content-end">
                            <li
                                className="page-item pointer fw-bold text-cian"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            ><i className='bi bi-chevron-double-left' /></li>
                            {
                                pageOptions.map(data => (
                                    <li
                                        className={`page-item px-2 mx-1 pointer fw-bold ${pageIndex === data ? ("pagination-option-active") : ("pagination-option")}`}
                                        onClick={() => gotoPage(data)}
                                    >{data + 1}</li>
                                ))
                            }
                            <li
                                className="page-item pointer fw-bold text-cian"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            ><i className='bi bi-chevron-double-right' /></li>
                        </ul>
                    </div>
                ) : (
                    null
                )
            }
        </Fragment>
    )
}

const TableExample = ({ resumeArr }) => {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Nombre',
                accessor: 'title',
            },
            {
                Header: 'Fecha inicial',
                accessor: 'initialDate',
            },
            {
                Header: 'Fecha final',
                accessor: 'finalDate',
            },
            {
                Header: 'Días restantes',
                accessor: 'restDays',
            },
            {
                Header: 'Visitas',
                accessor: 'views',
            },
            {
                Header: 'Concluidos',
                accessor: 'finished',
            },
            {
                Header: 'Iniciados',
                accessor: 'initiates',
            },
        ],
        []
    )

    return <div className='row w-100'> <Table columns={columns} data={resumeArr} /> </div>;
};

export default TableExample;
