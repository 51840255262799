import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import QuizSponsorList from './QuizSponsorList';
import QuizSponsorSpecific from './QuizSponsorSpecific';
import * as toastr from 'toastr';
import { doAddNewQuizAction, doSetQuizInitialState } from '../../redux/quizDuck';
import { isEmptyObject } from 'jquery';
import TableExample from './TableExample';
import { getListMarketStudyResume } from '../../models/quizSponsor_model';

const MarketStudiesScreen = ({ quizSpecific, doSetQuizInitialState, doAddNewQuizAction, profile }) => {

    const { error, quizDetails } = quizSpecific;
    const [resumeArr, setResumeArr] = useState([]);
    const [hiddeForm, setHiddeForm] = useState(false);
    useEffect(() => {
        if (error !== false) {
            toastr.error(error.message);
        }

        if (error !== false) {
            return () => {
                doSetQuizInitialState();
            };
        }

    }, [error, doSetQuizInitialState]);

    useEffect(() => {
        getListMarketStudyResume(profile.idSponsorProfile).then(response => {
            if (response) {
                if (response.code === 1) {
                    setResumeArr(response.quizResume);
                } else {
                    setResumeArr([]);
                }
            }
        });
    }, [profile]);

    return (
        <div className="col-11 mx-auto my-4">
            <div className="row">
                <div className='col-12 col-md-6 my-4 px-0'>
                    <b className='fs-5 text-blue-sec'>Estudios de mercado</b>
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-end flex-wrap align-content-center">
                    {
                        !isEmptyObject(quizDetails) ?
                            (
                                <span className='pointer text-center btn-secondary rounded-circle px-1'>
                                    <i className="bi bi-chevron-left fs-5" onClick={() => {
                                        doSetQuizInitialState();
                                        setHiddeForm(false);
                                    }}
                                    />
                                </span>
                            ) : (
                                <div className='col-1 pointer text-pink'>
                                    <div className='col-12 text-center'>
                                        <i className="bi bi-plus-circle-fill fs-3" onClick={doAddNewQuizAction} />
                                    </div>
                                    <div className='col-12 text-center fs-7'>Nuevo</div>
                                </div>
                            )

                    }
                </div>
                {
                    !isEmptyObject(quizDetails) ?
                        (
                            <div className='col-12 bg-white_translucid-verylow shadow p-5'>
                                <QuizSponsorSpecific
                                    hiddeForm={hiddeForm}
                                    setHiddeForm={setHiddeForm}
                                />
                            </div>
                        ) : (
                            <div className='row'>
                                <div className="col-12 bg-white_translucid-verylow shadow p-3 mb-5 rounded-3">
                                    <div className='row'>
                                        <div className='col-12 mb-3'>
                                            <b className='text-cian'>Encuestas no iniciadas</b>
                                        </div>
                                        <div className='col-12'>
                                            <QuizSponsorList
                                                setHiddeForm={setHiddeForm}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 bg-white_translucid-verylow shadow p-3 rounded-3">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <b className='text-cian'>Históricos</b>
                                        </div>
                                        <div className='col-12'>
                                            <TableExample
                                                resumeArr={resumeArr}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                }
            </div>
        </div>
    );
};

const mapStateToProps = ({ quizSpecific, profile }) => {
    return {
        quizSpecific,
        profile,
    }

}

export default connect(mapStateToProps, { doSetQuizInitialState, doAddNewQuizAction })(MarketStudiesScreen);
