import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { colorsArray } from "../../helpers/helpers";

const Publicity = ({ publicity }) => {

    const [publicityData, setPublicityData] = useState({
        series: [{
            name: "publicidad",
            data: [0, 0, 0],
        }],
        options: {
            chart: {
                toolbar: {
                    show: false
                }
            },
            colors: colorsArray,
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                    horizontal: false,
                    borderRadius: 12,
                    colors: {
                        backgroundBarColors: ["#fff"],
                        backgroundBarOpacity: 0,
                        backgroundBarRadius: 10,
                    },
                    dataLabels: {
                        position: 'bottom',
                        style: {
                            colors: ['#15253c']
                        },
                    },
                }
            },
            legend: {
                show: true,
                position: 'right',
                horizontalAlign: 'center',
                markers: {
                    width: 12,
                    height: 12,
                    radius: 12,
                },
                itemMargin: {
                    vertical: 14
                },
                offsetY: 15
            },
            dataLabels: {
                enabled: true,
                formatter: val => val,
                style: {
                    colors: ['#15253c']
                },
                // dropShadow: {
                //     enabled: true,
                //     top: 1,
                //     left: 1,
                //     blur: 1,
                //     color: '#000',
                //     opacity: 1
                // }
            },
            xaxis: {
                type: 'category',
                categories: ['Banner', 'Promociones', 'Publicidad'],
                labels: {
                    show: true
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                }
            },
            yaxis: {
                labels: {
                    show: false
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                }
            },
            tooltip: {
                theme: 'dark',
                x: {
                    show: true
                },
                y: {
                    show: false,
                    formatter: (value) => value + " clics",
                    title: {
                        formatter: () => ''
                    }
                },
                marker: {
                    show: true,
                },
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'round',
                colors: colorsArray,
                width: 2,
                dashArray: 0,
            }
        }
    });

    useEffect(() => {
        setPublicityData(oldValues => ({
            series: [{
                name: "publicidad",
                data: [parseInt(publicity.totalBanner), parseInt(publicity.totalPromotion), parseInt(publicity.totalPublicity)],
            }],
            options: {
                ...oldValues.options,
                xaxis: {
                    ...oldValues.options.xaxis,
                    categories: ['Banner', 'Promociones', 'Publicidad'],
                }
            }
        }));

    }, [publicity])

    return (
        <div className='card reports__cards-style'>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-12 text-center mb-3'>
                        <span className='fs-6 text-blue-sec'>Número de <b>clic</b> por tipo de publicidad</span>
                    </div>
                    <div className='col-12'>
                        <Chart options={publicityData.options}
                            series={publicityData.series}
                            type="bar"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Publicity;