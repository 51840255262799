import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getSectorCat, getSubSectorCat } from '../../models/catalog_model';
import { setUpdateProfile } from '../../models/sponsor_model';
import * as toastr from 'toastr';
import $ from 'jquery';

const ProfileForm = ({ user, profile, profileInfo }) => {

    const { userId } = user;

    const { idSponsorProfile } = profile;

    const [catalogs, setCatalogs] = useState({
        sectors: [],
        subSectors: []
    });

    const [profileInitialForm, setProfileInitialForm] = useState({
        enterpriseName: '',
        sector: '0',
        subSector: '0',
        paginaWeb: '',
        facebookUrl: '',
        instagramUrl: '',
        twitterUrl: '',
        linkedinUrl: '',
        description: '',
        logo: '',
        registerDate: '',
        updateDate: '',
        active: 1,
        legalName: '',
        mission: '',
        vision: '',
        slogan: '',
    });

    const { enterpriseName, sector, subSector, paginaWeb, facebookUrl, instagramUrl, twitterUrl, linkedinUrl, description, logo, registerDate, updateDate, active,
        legalName,
        mission,
        vision,
        slogan,
    } = profileInitialForm;

    useEffect(() => {
        setProfileInitialForm({
            enterpriseName: profileInfo.organization || '',
            sector: profileInfo.sector || '0',
            subSector: profileInfo.subsector || '0',
            paginaWeb: profileInfo.webPage || '',
            facebookUrl: profileInfo.facebook || '',
            instagramUrl: profileInfo.instagram || '',
            twitterUrl: profileInfo.twitter || '',
            linkedinUrl: profileInfo.linkedin || '',
            description: profileInfo.description || '',
            logo: profileInfo.logo || '../assets/img/sin_logo.png',
            registerDate: profileInfo.register_date || '',
            updateDate: profileInfo.dateOfRenovation || '',
            active: profileInfo.active === 0 ? (profileInfo.active) : (1),
            legalName: profileInfo.legalName || '',
            mission: profileInfo.mission || '',
            vision: profileInfo.vision || '',
            slogan: profileInfo.slogan || '',
        });
        getSectorCat().then(response => {
            setCatalogs(prevState => {
                return {
                    ...prevState,
                    sectors: response
                }
            });
        });

        return () => {
        }

    }, [profileInfo]);
    useEffect(() => {
        getSubSectorCat(sector).then(response => {
            setCatalogs(prevState => {
                return {
                    ...prevState,
                    subSectors: response
                }
            });
        })
        return () => {
        }
    }, [sector])

    const handleInputChange = ({ target }) => {
        if (target.name === 'active') {
            if (target.checked === true) {
                setProfileInitialForm(prevState => ({
                    ...prevState,
                    [target.name]: 1
                }));
            } else {
                setProfileInitialForm(prevState => ({
                    ...prevState,
                    [target.name]: 0
                }));
            }
        } else {
            setProfileInitialForm(prevState => ({
                ...prevState,
                [target.name]: target.value
            }));
        }
    }


    const handleClicSave = () => {
        $('#submitBtn').attr('disabled', '')
        if (enterpriseName !== '' && sector !== '0' && subSector !== '0') {
            setUpdateProfile(userId, idSponsorProfile, enterpriseName, sector, subSector, paginaWeb, facebookUrl, instagramUrl, twitterUrl, linkedinUrl, description, active, logo, legalName, mission, vision, slogan).then(response => {
                switch (response?.code) {
                    case 1:
                        // dispatch({
                        //     type: types.setProfileActive,
                        //     payload: response.idSponsorProfile
                        // });
                        toastr.success("Información guardada correctamente");
                        break;
                    case 404:
                        toastr.error("Contacto no encontrado");
                        break;
                    case 1000:
                        toastr.error("Usuario no encontrado");
                        break;

                    default:
                        toastr.error("Error inesperado");
                        break;
                }
                $('#submitBtn').removeAttr('disabled')
            });
        } else {
            $('#submitBtn').removeAttr('disabled')
            toastr.error("Debe rellenar el nombre de la empresa, el sector y sub sector");
        }
    }

    return (
        <div className="row">
            <div className="col-12 mb-3">
                <div className="text-center">
                    <input type="file"
                        className="visually-hidden"
                        id="uploadLogo" name="logo"
                        accept="image/png, image/jpeg"
                        onChange={e => {
                            if (e.target.files.length > 0) {
                                let reader = new FileReader();
                                let file = e.target.files[0];
                                if (file.size < 2000000) {
                                    let ext = file.name.split('.').pop();
                                    ext = ext.toLowerCase();
                                    switch (ext) {
                                        case 'jpg':
                                        case 'jpeg':
                                        case 'png':
                                            reader.onload = function (e) {
                                                $('#enterpriseLogo').attr('src', e.target.result);
                                            };
                                            reader.readAsDataURL(e.target.files[0]);
                                            setProfileInitialForm(prevSatate => {
                                                return {
                                                    ...prevSatate,
                                                    logo: file
                                                }
                                            });
                                            break;
                                        default:
                                            toastr.warning("El formato debe ser jpg, jpeg o png.", "¡Ooops!");
                                            break;
                                    }
                                } else {
                                    toastr.warning("El peso de la imagen debe ser menor a 2 MB.", "¡Ooops!");
                                }
                            }
                        }}
                    />
                    <img src={logo} id="enterpriseLogo" alt="logo empresa" className="img-thumbnail img-150 pointer pull-up" onClick={() => { $('#uploadLogo').trigger('click') }} />
                    <div className='mt-3 text-cian'>
                        <label><b>Miembro desde:</b> {registerDate}</label>
                        <br />
                        <label><b>Fecha de renovación:</b> {updateDate}</label>
                    </div>
                </div>
            </div>
            <div className='col-12 my-4'>
                <b className='fs-5 text-cian'>Perfil</b>
            </div>
            <div className='row mb-4'>
                <div className='col'>
                    <div className="row align-items-center">
                        <div className="col-2">
                            <b className="col-form-label text-base">Empresa / Marca</b>
                        </div>
                        <div className="col-10">
                            <input
                                type="text"
                                className="form-control bg-blue-white border-0"
                                placeholder="Escriba aquí..."
                                name="enterpriseName"
                                value={enterpriseName}
                                onChange={handleInputChange}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </div>
                <div className='col d-flex align-items-center'>
                    <div className="row align-items-center">
                        <div className="col-2">
                            <b className="col-form-label text-base">Sector</b>
                        </div>
                        <div className="col-10">
                            <select
                                className="form-select bg-blue-white border-0"
                                name="sector"
                                value={sector}
                                onChange={handleInputChange}
                            >
                                <option value="0">Elija...</option>
                                {
                                    catalogs.sectors.map((catalog) => (
                                        <option
                                            key={catalog.sector_id}
                                            value={catalog.sector_id}
                                        >{catalog.description}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col'>
                    <div className="row align-items-center">
                        <div className="col-2">
                            <b className="col-form-label text-base">Razón social</b>
                        </div>
                        <div className="col-10">
                            <input
                                type="text"
                                className="form-control bg-blue-white border-0"
                                placeholder="Escriba aquí..."
                                name="legalName"
                                value={legalName}
                                onChange={handleInputChange}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </div>
                <div className='col'>
                    <div className="row align-items-center">
                        <div className="col-2">
                            <b className="col-form-label text-base">Sub Sector</b>
                        </div>
                        <div className="col-10">
                            <select
                                className="form-select bg-blue-white border-0"
                                name="subSector"
                                value={subSector}
                                onChange={handleInputChange}
                            >
                                <option value="0">Elija...</option>
                                {
                                    catalogs.subSectors.map((catalog) => (
                                        <option
                                            key={catalog.sub_sector_id}
                                            value={catalog.sub_sector_id}
                                        >{catalog.description}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col'>
                    <div className="row align-items-center">
                        <div className="col-2">
                            <b className="col-form-label text-base">Página web</b>
                        </div>
                        <div className="col-10">
                            <input
                                type="text"
                                className="form-control bg-blue-white border-0"
                                placeholder="url"
                                name="paginaWeb"
                                value={paginaWeb}
                                onChange={handleInputChange}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </div>
                <div className='col'>
                    <div className="row align-items-center">
                        <div className="col-2">
                            <b className="col-form-label text-base">Activo</b>
                        </div>
                        <div className="col-10">
                            <div className="form-check form-switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="active"
                                    checked={active === 1 ? true : false}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col'>
                    <div className="row align-items-center">
                        <div className="col-12">
                            <b className="col-form-label text-green">Misión</b>
                            <textarea
                                className="form-control bg-blue-white border-0"
                                rows="3"
                                name="mission"
                                value={mission}
                                onChange={handleInputChange}
                                autoComplete="off"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col'>
                    <div className="row align-items-center">
                        <div className="col-12">
                            <b className="col-form-label text-green">Visión</b>
                            <textarea
                                className="form-control bg-blue-white border-0"
                                rows="3"
                                name="vision"
                                value={vision}
                                onChange={handleInputChange}
                                autoComplete="off"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col'>
                    <div className="row align-items-center">
                        <div className="col-12">
                            <b className="col-form-label text-green">Slogan</b>
                            <textarea
                                className="form-control bg-blue-white border-0"
                                rows="1"
                                name="slogan"
                                value={slogan}
                                onChange={handleInputChange}
                                autoComplete="off"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 my-4'>
                <b className='fs-5 text-cian'>Redes Sociales</b>
            </div>
            <div className='row mb-4'>
                <div className='col'>
                    <div className="row align-items-center">
                        <div className="col-1">
                            <b className="fs-4 col-form-label text-blue-sec"><i className="bi bi-facebook" /></b>
                        </div>
                        <div className="col-11">
                            <input
                                type="text"
                                className="form-control bg-blue-white border-0"
                                placeholder="Escriba aquí..."
                                name="facebookUrl"
                                value={facebookUrl}
                                onChange={handleInputChange}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </div>
                <div className='col'>
                    <div className="row align-items-center">
                        <div className="col-1">
                            <b className="fs-4 col-form-label text-pink"><i className="bi bi-instagram" /></b>
                        </div>
                        <div className="col-11">
                            <input
                                type="text"
                                className="form-control bg-blue-white border-0"
                                placeholder="Escriba aquí..."
                                name="instagramUrl"
                                value={instagramUrl}
                                onChange={handleInputChange}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col'>
                    <div className="row align-items-center">
                        <div className="col-1">
                            <b className="fs-4 col-form-label text-cian"><i className="bi bi-twitter" /></b>
                        </div>
                        <div className="col-11">
                            <input
                                type="text"
                                className="form-control bg-blue-white border-0"
                                placeholder="Escriba aquí..."
                                name="twitterUrl"
                                value={twitterUrl}
                                onChange={handleInputChange}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </div>
                <div className='col'>
                    <div className="row align-items-center">
                        <div className="col-1">
                            <b className="fs-4 col-form-label text-gray"><i className="bi bi-linkedin" /></b>
                        </div>
                        <div className="col-11">
                            <input
                                type="text"
                                className="form-control bg-blue-white border-0"
                                placeholder="Escriba aquí..."
                                name="linkedinUrl"
                                value={linkedinUrl}
                                onChange={handleInputChange}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="col-12 mt-3">
                <label className="fw-bold">Descripción de marca</label>
                <textarea className="form-control" placeholder="Escriba aquí..." style={{ height: '100px' }} name="description" value={description} onChange={handleInputChange} maxLength="500"></textarea>
                <div className="form-text text-end">
                    {description.length} / 500
                </div>
            </div> */}
            <div className="col-12 mt-3 mb-5">
                <div className="d-grid col-2 mx-auto">
                    <button className="btn btn-sm btn-primary btn-pink-guay" onClick={handleClicSave} id='submitBtn'>Guardar</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ user, profile }) => {
    return {
        user,
        profile
    }
}

export default connect(mapStateToProps)(ProfileForm);