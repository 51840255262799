import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// eslint-disable-next-line
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { getMessaging, getToken } from "firebase/messaging";

// console.log('entro a firebase');

const firebaseConfig = {
    apiKey: "AIzaSyB7wDdZKaSgi8vfV_DmJF-GnTu0LTy0DLQ",
    authDomain: "guay-app.firebaseapp.com",
    projectId: "guay-app",
    storageBucket: "guay-app.appspot.com",
    messagingSenderId: "336713410772",
    appId: "1:336713410772:web:6c5826be769b8fdaa83341",
    measurementId: "G-JYK3Y4Q4NB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line
const analytics = getAnalytics(app);
const messaging = getMessaging();
// console.log(messaging);
export const getTokenF = async function(){
    getToken(messaging,{ vapidKey: 'BBboBKCSI-yi9i2K204QJ-264HifGH-KV_DDV2QzToHvRHrFifAJuhu0SI1yomJrvjz-THRTgzZnWY97EAWaHQ4' }).then((currentToken) => {
        // console.log(currentToken);
        if (currentToken) {
            // console.log(currentToken);
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
};

