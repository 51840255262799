import axios from 'axios';

export const getSectorCat = async () => {
    try {
        let result = await axios({
            method: 'POST',
            url: global.base_url + "catalogs/getSectorCat",
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

export const getSubSectorCat = async (sectorId) => {
    try {
        let result = await axios({
            method: 'POST',
            url: global.base_url + "catalogs/getSubSectorCat?idSector=" + sectorId,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

export const getSprPromotionCategory = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + "sprCatalogs/getSprPromotionCategory",
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

export const getSprPromotionType = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + "sprCatalogs/getSprPromotionType",
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @returns 
 */
export const getSprTypeQuestion = async () => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + "sprCatalogs/getSprTypeQuestion",
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
