import React, { useState, useEffect } from 'react';
// API calls
import { getBrandPresence, deleteBrandPresence } from '../../../models/sponsor_model';
import { getSprPromotionCategory, getSprPromotionType } from '../../../models/catalog_model';
// Components
import BannerImage from './BannerImage';
import LoaderImage from './LoaderImage';
import Advertising from './Advertising';
import Administration from './Administration';
import PreviewBanner from './PreviewBanner';
// Tools
import * as toastr from 'toastr';
import { connect } from 'react-redux';
import $ from 'jquery';

const Presence = ({ profile }) => {
    const [promoCat, setPromoCat] = useState([]);
    const [promoType, setPromoType] = useState([]);
    const [advertisingData, setAdvertisingData] = useState([]);
    const dateOfRenovation = profile.dateOfRenovation;
    const [bannerForm, setBannerForm] = useState({
        id: profile.idSponsorProfile || 0,
        brand: 0,
        type: 0,
        name: '',
        url: '',
        startDate: '',
        endDate: '',
        resource: '',
    });
    const [loaderForm, setLoaderForm] = useState({
        id: profile.idSponsorProfile || 0,
        brand: 0,
        type: 0,
        loaderName: '',
        url: '',
        loaderStartDate: '',
        loaderEndDate: '',
        resource: '',
    });
    const [advertisingForm, setAdvertisingForm] = useState({
        id: profile.idSponsorProfile || 0,
        brand: 0,
        type: 0,
        advertisingName: '',
        urlProm: '',
        urlWeb: '',
        advertisingStartDate: '',
        advertisingEndDate: '',
        resource: '',
        idPromotionCategory: 0,
        idPromotionType: 0,
    });
    const [viewerData, setViewerData] = useState({
        url: '',
        type: 0
    });
    const [previewImage, setPreviewImage] = useState({
        image_1: '',
        image_2: '',
        image_3: '',
    });
    const [adminForm, setAdminForm] = useState({
        id: profile.idSponsorProfile || 0,
        brand: 0,
        type: 0,
        adminName: '',
        url: '',
        adminStartDate: '',
        adminEndDate: '',
        resource: '',
    });
    const [bannerPrevImg, setBannerPrevImg] = useState('');
    useEffect(() => {
        getSprPromotionCategory().then(response => {
            setPromoCat(response);
        });
        getSprPromotionType().then(response => {
            setPromoType(response);
        });
        getBrandPresence(profile.idSponsorProfile).then(response => {
            let getBanner = [];
            let getLoader = [];
            let getAdvertising = [];
            let getAdministration = [];
            // Filter each presence type
            let image_1 = '';
            let image_2 = '';
            let image_3 = '';
            for (let x in response.listPresence) {
                if (response.listPresence[x].typePresence === 1) {
                    getBanner.push(response.listPresence[x]);
                    setBannerForm({
                        id: getBanner[0].idSponsorProfile,
                        brand: getBanner[0].idBrandPresence,
                        type: getBanner[0].typePresence,
                        name: getBanner[0].title,
                        url: getBanner[0].customUrl1,
                        startDate: '',
                        endDate: '',
                        resource: getBanner[0].resourceUrl,
                        idPromotionCategory: 0,
                        idPromotionType: 0,
                    });
                    image_1 = getBanner[0].resourceUrl;
                }
                if (response.listPresence[x].typePresence === 2 || response.listPresence[x].typePresence === 3) {
                    getLoader.push(response.listPresence[x]);
                    setLoaderForm({
                        id: getLoader[0].idSponsorProfile,
                        brand: getLoader[0].idBrandPresence,
                        type: getLoader[0].typePresence,
                        loaderName: getLoader[0].title,
                        url: getLoader[0].customUrl1,
                        loaderStartDate: '',
                        loaderEndDate: '',
                        resource: getLoader[0].resourceUrl,
                        idPromotionCategory: 0,
                        idPromotionType: 0,
                    });
                    image_2 = getLoader[0].resourceUrl;
                }
                if (response.listPresence[x].typePresence === 4 || response.listPresence[x].typePresence === 5) {
                    getAdvertising.push(response.listPresence[x]);
                }
                if (response.listPresence[x].typePresence === 6 || response.listPresence[x].typePresence === 7) {
                    getAdministration.push(response.listPresence[x]);
                    setAdminForm({
                        id: getAdministration[0].idSponsorProfile,
                        brand: getAdministration[0].idBrandPresence,
                        type: getAdministration[0].typePresence,
                        adminName: getAdministration[0].title,
                        url: getAdministration[0].customUrl1,
                        adminStartDate: '',
                        adminEndDate: '',
                        resource: getAdministration[0].resourceUrl,
                        idPromotionCategory: 0,
                        idPromotionType: 0,
                    });
                    image_3 = getAdministration[0].resourceUrl;
                }
            }
            setPreviewImage({
                ...previewImage,
                image_1,
                image_2,
                image_3,
            });
            setAdvertisingData(getAdvertising);
        });
        // eslint-disable-next-line
    }, [profile.idSponsorProfile]);
    const resetForm = () => {
        getBrandPresence(profile.idSponsorProfile).then(response => {
            let getBanner = [];
            let getLoader = [];
            let getAdvertising = [];
            let getAdministration = [];
            // Filter each presence type
            for (let x in response.listPresence) {
                if (response.listPresence[x].typePresence === 1) {
                    getBanner.push(response.listPresence[x]);
                    setBannerForm({
                        id: getBanner[0].idSponsorProfile,
                        brand: getBanner[0].idBrandPresence,
                        type: getBanner[0].typePresence,
                        name: getBanner[0].title,
                        url: getBanner[0].customUrl1,
                        startDate: '',
                        endDate: '',
                        resource: getBanner[0].resourceUrl,
                        idPromotionCategory: 0,
                        idPromotionType: 0,
                    });
                }
                if (response.listPresence[x].typePresence === 2 || response.listPresence[x].typePresence === 3) {
                    getLoader.push(response.listPresence[x]);
                    setLoaderForm({
                        id: getLoader[0].idSponsorProfile,
                        brand: getLoader[0].idBrandPresence,
                        type: getLoader[0].typePresence,
                        loaderName: getLoader[0].title,
                        url: getLoader[0].customUrl1,
                        loaderStartDate: '',
                        endDate: '',
                        resource: getLoader[0].resourceUrl,
                        idPromotionCategory: 0,
                        idPromotionType: 0,
                    });
                }
                if (response.listPresence[x].typePresence === 4 || response.listPresence[x].typePresence === 5) {
                    getAdvertising.push(response.listPresence[x]);
                }
                if (response.listPresence[x].typePresence === 6 || response.listPresence[x].typePresence === 7) {
                    getAdministration.push(response.listPresence[x]);
                    setAdminForm({
                        id: getAdministration[0].idSponsorProfile,
                        brand: getAdministration[0].idBrandPresence,
                        type: getAdministration[0].typePresence,
                        adminName: getAdministration[0].title,
                        url: getAdministration[0].customUrl1,
                        adminStartDate: '',
                        adminEndDate: '',
                        resource: getAdministration[0].resourceUrl,
                        idPromotionCategory: 0,
                        idPromotionType: 0,
                    });
                }
            }
            setAdvertisingData(getAdvertising);
        });
    }
    /**
     * 
     * @param {*} id 
     */
    const deleteFile = (id) => {
        let formData = new FormData();
        formData.append("request", JSON.stringify(id));
        deleteBrandPresence(formData).then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                    case 200:
                        resetForm();
                        toastr.success('Se eliminó correctamente.', '¡Éxito!');
                        break;
                    default:
                        toastr.error('Hubo un problema al eliminar. Contacta al equipo de soporte. Problema: ' + response.message + ', ' + response.code, '¡Ooops!');
                        break;
                }
            } else {
                toastr.error('Problemas con el servidor. Intenta más tarde.', '¡Ooops!');
            }
        });
    }
    const images = {
        banner: '../assets/img/elementos_admin-50.png',
        colaboradores: '../assets/img/elementos_admin-51.png',
        administradores: '../assets/img/elementos_admin-52.png',
        promociones: '../assets/img/elementos_admin-53.png',
    };
    const {
        banner,
        colaboradores,
        administradores,
        promociones,
    } = images;
    const handleHelper = (id) => {
        let imageSrc;
        switch (id) {
            case 2:
                imageSrc = colaboradores;
                break;
            case 3:
                imageSrc = administradores;
                break;
            case 4:
                imageSrc = promociones;
                break;
            default:
                imageSrc = banner;
                break;
        }
        setBannerPrevImg(imageSrc);
        $('#previewBanner').show();
    }
    return (
        <div className="col-11 mx-auto my-4">
            <div className="row">
                <div className='col-12 my-4'>
                    <b className='fs-5 text-blue-sec'>Presencia de marca <span className="presence__text-blue">({profile.organization})</span></b>
                </div>
                <BannerImage
                    bannerForm={bannerForm}
                    setBannerForm={setBannerForm}
                    resetForm={resetForm}
                    previewImage={previewImage}
                    setPreviewImage={setPreviewImage}
                    handleHelper={handleHelper}
                />
                <div className="col-12">
                    <ul className="nav nav-tabs border-0 presence__show-container container-2 justify-content-end px-0" role="tablist">
                        <li className="nav-item">
                            <a
                                className="nav-link active tab-style-2"
                                data-bs-toggle="tab"
                                href="#tab1"
                                id='tab-1'
                                onClick={() => {
                                    if ($(`#tab-1`).hasClass('tab-style-1')) {
                                        $(`#tab-1`).removeClass('tab-style-1');
                                        $(`#tab-1`).addClass('tab-style-2');
                                        $(`#tab-2`).removeClass('tab-style-2');
                                        $(`#tab-2`).addClass('tab-style-1');
                                    }
                                }}
                            >Colaboradores</a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link tab-style-1"
                                data-bs-toggle="tab"
                                href="#tab2"
                                id='tab-2'
                                onClick={() => {
                                    if ($(`#tab-2`).hasClass('tab-style-1')) {
                                        $(`#tab-2`).removeClass('tab-style-1');
                                        $(`#tab-2`).addClass('tab-style-2');
                                        $(`#tab-1`).removeClass('tab-style-2');
                                        $(`#tab-1`).addClass('tab-style-1');
                                    }
                                }}
                            >Administradores</a>
                        </li>
                    </ul>
                    <div className="tab-content p-0 presence__show-container container-2">
                        <div id="tab1" className="container-fluid tab-pane active p-0">
                            <LoaderImage
                                loaderForm={loaderForm}
                                setLoaderForm={setLoaderForm}
                                resetForm={resetForm}
                                previewImage={previewImage}
                                setPreviewImage={setPreviewImage}
                                handleHelper={handleHelper}
                            />
                        </div>
                        <div id="tab2" className="container-fluid tab-pane fade p-0">
                            <Administration
                                adminForm={adminForm}
                                setAdminForm={setAdminForm}
                                resetForm={resetForm}
                                previewImage={previewImage}
                                setPreviewImage={setPreviewImage}
                                handleHelper={handleHelper}
                            />
                        </div>
                    </div>
                </div>
                <Advertising
                    advertisingForm={advertisingForm}
                    advertisingData={advertisingData}
                    viewerData={viewerData}
                    dateOfRenovation={dateOfRenovation}
                    setViewerData={setViewerData}
                    setAdvertisingForm={setAdvertisingForm}
                    resetForm={resetForm}
                    deleteFile={deleteFile}
                    promoCat={promoCat}
                    promoType={promoType}
                    handleHelper={handleHelper}
                />
            </div>
            <PreviewBanner
                bannerPrevImg={bannerPrevImg}
            />
        </div>
    );
}

const mapStateToProps = ({ profile }) => {
    return {
        profile
    }
}

export default connect(mapStateToProps)(Presence);
