import React from 'react';
// Tools
import $ from 'jquery';
import ReactPlayer from 'react-player';

const ModalViewer = ({ data }) => {
    const {
        url,
        type
    } = data;
    const selectTool = () => {
        if (type === 1 || type === 2 || type === 4 || type === 6) {
            return (
                <img
                    src={url}
                    style={{
                        width: 'auto',
                        maxHeight: '80vh'
                    }}
                    alt="viewer"
                />
            )
        }
        if (type === 3 || type === 5 || type === 7) {
            return (
                <ReactPlayer
                    url={url}
                    width="auto"
                    height="80vh"
                    controls
                    playing
                    muted
                />
            )
        }
    }
    return (
        <div
            className="modal"
            id="modalViewer"
            style={{
                backgroundColor: 'rgba(255,255,255,0.9)'
            }}
            data-backdrop="false"
        >
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div
                    className="modal-content"
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none'
                    }}
                >
                    <div style={{ position: 'absolute', right: '0', top: '0', zIndex: '100' }}>
                        <button
                            type="button"
                            className="btn-close pointer"
                            onClick={() => $("#modalViewer").hide()}
                        ></button>
                    </div>
                    <div className="modal-body d-flex justify-content-center">
                        {selectTool()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalViewer;
