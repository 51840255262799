//const
const SET_PROFILE = 'SET_PROFILE';
const PROFILE_LOGOUT = 'PROFILE_LOGOUT';

const initialState = {};

// reducer
const profileReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_PROFILE:
            return {
                ...state,
                ...action.payload
            }

        case PROFILE_LOGOUT:
            return {
            }

        default:
            return state;
    }

}

export default profileReducer;

//auxiliar
const saveStorage = function (profile) {
    sessionStorage.setItem('profile', btoa(JSON.stringify(profile)));
}

const removeStorage = function () {
    sessionStorage.removeItem('profile');
}

//actions
export const restoreProfileAction = () => (dispatch) => {
    if (sessionStorage.getItem('profile') !== null) {
        const profile = JSON.parse(atob(sessionStorage.getItem('profile')));
        dispatch({
            type: SET_PROFILE,
            payload: { ...profile }
        })
    }
}

export const doSetProfileAction = (profile) => (dispatch, getState) => {
    dispatch({
        type: SET_PROFILE,
        payload: { ...profile }
    });
    saveStorage(getState().profile);
}

export const doCleanProfileLogOut = () => (dispatch, getState) => {
    dispatch({
        type: PROFILE_LOGOUT,
    });
    removeStorage();
}
