import axios from 'axios';

export const getListMarketStudyByIdProfile = async (idSponsorProfile) => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + `quizSponsor/getListMarketStudyByIdProfile?idSponsorProfile=${idSponsorProfile}`,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

export const getQuizSponsorSpecific = async (idQuizSponsor) => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + `quizSponsor/getQuizSponsorSpecific?idQuizSponsor=${idQuizSponsor}`,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

export const setDesactiveQuiz = async (idQuizSponsor) => {
    const formData = new FormData();
    const data = { idQuizSponsor };
    formData.append('request', JSON.stringify(data));
    try {
        let result = await axios({
            method: 'POST',
            url: global.base_url + "quizSponsor/setDesactiveQuiz",
            headers: {
                Authorization: global.tokenAuth
            },
            data: formData
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

export const setUpdateQuiz = async (idQuizSponsor, idSponsorProfile, title, description, initialDate, finalDate, audience, active) => {
    const formData = new FormData();
    const data = {
        "idQuizSponsor": idQuizSponsor,
        "idSponsorProfile": idSponsorProfile,
        "title": title,
        "description": description,
        "initDate": initialDate,
        "finalDate": finalDate,
        "audience": audience,
        "active": active
    };
    formData.append('request', JSON.stringify(data));
    try {
        let result = await axios({
            method: 'POST',
            url: global.base_url + "quizSponsor/setUpdateQuiz",
            headers: {
                Authorization: global.tokenAuth
            },
            data: formData
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} idQuizSponsor 
 * @returns 
 */
export const getQuizSpecific = async (idQuizSponsor) => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + `quizSponsor/getQuizSpecific?idQuizSponsor=${idQuizSponsor}`,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} data 
 * @returns 
 */
export const setUpdateMarketStudies = async (data) => {
    try {
        let result = await axios({
            method: 'POST',
            url: global.base_url + "quizSponsor/setUpdateMarketStudies",
            headers: {
                Authorization: global.tokenAuth
            },
            data: data
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} idSponsorProfile 
 * @returns 
 */
export const getListMarketStudyResume = async (idSponsorProfile) => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + `quizSponsor/getListMarketStudyResume?idSponsorProfile=${idSponsorProfile}`,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
