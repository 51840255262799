import React from 'react'
import $ from 'jquery'

const PreviewBanner = ({ bannerPrevImg }) => {
    return (
        <div
            className="modal"
            id="previewBanner"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false"
        >
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content bg_modal_rounded">
                    <div className="modal-body bg_modal_presence">
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-12'>
                                    <span
                                        className="btn rounded-circle btn-close pointer bg-secondary"
                                        style={{
                                            width: '25px',
                                            height: '25px',
                                            position: 'absolute',
                                            right: '-35px',
                                            top: '-35px',
                                        }}
                                        onClick={() => $('#previewBanner').hide()}
                                    ></span>
                                </div>
                                <div className='col-6 mx-auto'>
                                    <h3 className='text-center text-secondary'>¡Al terminar de configurar esta sección así es como se verá tu publicidad!</h3>
                                </div>
                                <div className='col-12'>
                                    <img
                                        className='img-fluid'
                                        src={bannerPrevImg}
                                        alt='banner'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreviewBanner