//const
const CHANGE_NAVIGATION = 'CHANGE_NAVIGATION';

const initialState = {
    SELECTED: ''
}

// reducer
const navigationReducer = (state = initialState, action) => {

    switch (action.type) {
        case CHANGE_NAVIGATION:
            return {
                ...state,
                SELECTED: action.payload
            }

        default:
            return state;
    }

}

export default navigationReducer;

//actions
export const changeNavigationSelected = (menuOption) => (dispatch, getState) => {
    dispatch({
        type: CHANGE_NAVIGATION,
        payload: menuOption
    })
}

export const resetNavigationSelected = () => (dispatch, _getState) => {
    dispatch({
        type: CHANGE_NAVIGATION,
        payload: ''
    })
}
