import axios from 'axios';

export const getSprProfilebyUser = async (idUser) => {
    try {
        let result = await axios({
            url: global.base_url + "sprProfile/getSprProfilebyUser?idUser=" + idUser,
            method: 'GET',
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            },
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * 
 * @param {*} formData 
 * @returns 
 */
export const updSprProfilebyUser = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "sprProfile/updSprProfilebyUser",
            method: "POST",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data;",
                Authorization: global.tokenAuth
            },
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
};
