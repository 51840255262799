import { isEmptyObject } from "jquery";
import React, { useEffect, useState } from "react";

const Advertisement = ({ advertisement }) => {

    const [advertisementData, setAdvertisementData] = useState({
        totalAllViews: 0,
        totalAllClic: 0,
        totalAllHrs: 0,
        totalAllAvg: 0
    });

    useEffect(() => {
        if (!isEmptyObject(advertisement)) {
            setAdvertisementData({
                totalAllViews: advertisement.totalAllViews,
                totalAllClic: advertisement.totalAllClic,
                totalAllHrs: advertisement.totalAllHrs,
                totalAllAvg: advertisement.totalAllAvg
            });
        }
    }, [advertisement])


    return (
        <div className='card reports__cards-style'>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-12 text-center mb-3'>
                        <span className='fs-6 text-blue-sec'>Número de impresiones: <b>{advertisementData.totalAllViews}</b></span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex align-items-center">
                        <div className="row row-cols-1 row-cols-md-3 g-2 g-xl-3 g-xxl-5 mx-auto">
                            <div className="col">
                                <div className="card reports__cards-style-yellow h-100">
                                    <div className="card-body text-center text-white border-0 p-0">Número de clics</div>
                                    <div className="card-body text-white d-flex justify-content-center align-items-center p-0">
                                        <span className="fs-1 card-text fw-bold">{advertisementData.totalAllClic}</span>
                                    </div>
                                    <div className="card-body text-center text-white border-0 p-0"></div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card reports__cards-style-blue h-100">
                                    <div className="card-body text-center text-white border-0 p-0">Tiempo total en pantalla</div>
                                    <div className="card-body text-center text-white p-0">
                                        <span className="fs-1 card-text fw-bold">{advertisementData.totalAllHrs}</span>
                                    </div>
                                    <div className="card-body text-center text-white border-0 p-0">HORAS</div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card reports__cards-style-green h-100">
                                    <div className="card-body text-center text-white border-0 p-0">Tiempo promedio por usuario</div>
                                    <div className="card-body text-center text-white p-0">
                                        <span className="fs-1 card-text fw-bold">{advertisementData.totalAllAvg}</span>
                                    </div>
                                    <div className="card-body text-center text-white border-0 p-0">SEGUNDOS</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Advertisement;