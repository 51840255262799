import React, { useEffect, useState } from 'react';
import { getSpecificMarketStudy } from '../../models/sponsorReports_model';
import QuestionList from './questions/QuestionList';

const MarketStudyDetail = ({ idSponsorProfile, idQuiz, title }) => {

    const [quizQuestions, setQuizQuestions] = useState([]);

    useEffect(() => {
        getSpecificMarketStudy(idSponsorProfile, idQuiz).then(response => {
            switch (response?.code) {
                case 1:
                    setQuizQuestions(response.quizResume);
                    break;

                default:
                    break;
            }
        })

        return () => {

        }
    }, [idSponsorProfile, idQuiz])

    return (
        <div className='row'>
            <div className='col-12 my-4'>
                <span className='fs-6 text-blue-sec ms-5'>Detalle Estudio de Mercado: <b>{title}</b></span>
            </div>
            <div className='col-11 mx-auto mb-3'>
                <div className='row w-100 reports__cards-style'>
                    {
                        quizQuestions.map(question => (
                            <QuestionList question={question} key={question.idQuestionQuiz} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default MarketStudyDetail;