import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { colorsArray } from "../../helpers/helpers";

const Allocation = ({ enterprises }) => {

    const [enterprisesData, setEnterprisesData] = useState({
        series: [0],
        options: {
            chart: {
                type: 'donut'
            },
            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'center',
                markers: {
                    width: 12,
                    height: 12,
                    radius: 12,
                }
            },
            colors: colorsArray,
            dataLabels: {
                enabled: true,
                formatter: (value) => (Math.round(value * 100) / 100).toFixed(2) + "%",
                style: {
                    colors: ['#15253c']
                },
                dropShadow: {
                    enabled: false,
                }
            },
            labels: [''],
            stroke: {
                show: false
            },
            tooltip: {
                theme: 'dark',
                fillSeriesColor: false,
                y: {
                    show: true,
                    formatter: (value) => value + "%",
                    title: {
                        formatter: (seriesName) => seriesName,
                    },
                },
                marker: {
                    show: true,
                },
            }
        }
    });

    useEffect(() => {
        if (enterprises.length !== 0) {
            setEnterprisesData(oldValues => ({
                series: enterprises.map(center => parseInt(center.collabs)),
                options: {
                    ...oldValues.options,
                    labels: enterprises.map(center => center.description)
                }
            }));
        }

    }, [enterprises])

    return (
        <div className='card reports__cards-style h-100'>
            <div className='card-body'>
                <div className='row h-100'>
                    <div className='col-12 text-center mb-3'>
                        <span className='fs-6 text-blue-sec'>Distribución por organización</span>
                    </div>
                    <div className='col-12'>
                        <Chart options={enterprisesData.options}
                            series={enterprisesData.series}
                            type="donut"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Allocation;