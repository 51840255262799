import React from 'react'
import QuestionTypeBinary from './QuestionTypeBinary';
import QuestionTypeCheck from './QuestionTypeCheck';
import QuestionTypeDate from './QuestionTypeDate';
import QuestionTypeOpen from './QuestionTypeOpen';
import QuestionTypeRadio from './QuestionTypeRadio';

const QuestionList = ({ question }) => {
    switch (question.idTypeQuiz) {
        case 1: //Abierta
            return <QuestionTypeOpen question={question} />;
        case 2: //Binaria
            return <QuestionTypeBinary question={question} />;
        case 3: //Radio
            return <QuestionTypeRadio question={question} />
        case 4: //Check
            return <QuestionTypeCheck question={question} />;
        case 5: //Fecha
            return <QuestionTypeDate question={question} />;

        default:
            return <div>Sin definir</div>;
    }
}

export default QuestionList;