import { getQuizSponsorSpecific } from "../models/quizSponsor_model";

//const
const GET_QUIZ = 'GET_QUIZ';
const GET_QUIZ_SUCCESS = 'GET_QUIZ_SUCCESS';
const GET_QUIZ_ERROR = 'GET_QUIZ_ERROR';

const ADD_QUIZ = 'ADD_QUIZ';

const SET_QUIZ_INITIALSTATE = 'SET_QUIZ_INITIALSTATE';

const initialState = {
    fetching: false,
    error: false,
    quizDetails: {},
}

//reducer
const quizReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_QUIZ:
            return {
                ...state,
                fetching: true
            }

        case GET_QUIZ_SUCCESS:
            return {
                ...state,
                fetching: false,
                error: false,
                quizDetails: {
                    ...action.payload
                }
            }

        case GET_QUIZ_ERROR:
            return {
                ...state,
                fetching: false,
                error: {
                    message: action.payload
                },
                quizDetails: {}
            }

        case ADD_QUIZ:
            return {
                ...state,
                quizDetails: {
                    idQuizSponsor: 0,
                    idProfile: action.payload
                }
            }

        case SET_QUIZ_INITIALSTATE:
            return {
                ...initialState
            }

        default:
            return state;
    }

}

export default quizReducer;

//actions
export const doSetEditQuizAction = (idQuizSponsor) => (dispatch, _getState) => {
    dispatch({
        type: GET_QUIZ
    });
    getQuizSponsorSpecific(idQuizSponsor).then(response => {
        switch (response?.code) {
            case 1:
                dispatch({
                    type: GET_QUIZ_SUCCESS,
                    payload: response.quizSponsor[0]
                });
                break;

            case 99:
                dispatch({
                    type: GET_QUIZ_ERROR,
                    payload: 'Ocurrió un error al abrir el cuestionario.'
                })
                break;

            case 1001:
                dispatch({
                    type: GET_QUIZ_ERROR,
                    payload: 'Este cuestionario no se encuentra registrado, recargue la pagina y vuelva a intentarlo.'
                })
                break;

            default:
                dispatch({
                    type: GET_QUIZ_ERROR,
                    payload: 'Ocurrió un error desconocido.'
                })
                break;
        }
    })
}

export const doAddNewQuizAction = () => (dispatch, getState) => {
    dispatch({
        type: ADD_QUIZ,
        payload: getState().profile.idSponsorProfile
    });
}

export const doSetQuizInitialState = () => (dispatch, _getState) => {
    dispatch({
        type: SET_QUIZ_INITIALSTATE
    });
}