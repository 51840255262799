import React, { useEffect, useState } from "react";
import Audience from "./Audience";
import Allocation from "./Allocation";
import AudienceMap from "./AudienceMap";
import Publicity from "./Publicity";
import Advetisement from "./Advertisement";
import MarketStudies from "./MarketStudies";
import { getAdsByProfile, getAdvertisingByProfile, getFileAdvertising, getGeneralReport, getListMarketStudyReports } from "../../models/sponsorReports_model";
import { connect } from "react-redux";
import * as toastr from 'toastr';
import { Oval, TailSpin } from 'react-loader-spinner'

const Reports = ({ profile }) => {

  const [totalAudience, setTotalAudience] = useState(0);
  const [genderCount, setGenderCount] = useState([]);
  const [ageRangeCatalog, setAgeRangeCatalog] = useState([]);
  const [enterprises, setEnterprises] = useState([]);
  const [audienceLocation, setAudienceLocation] = useState([]);
  const [publicity, setPublicity] = useState({});
  const [advertisement, setAdvertisement] = useState({});
  const [marketStudies, setMarketStudies] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loadData, setLoadData] = useState(false);

  useEffect(() => {
    getGeneralReport(profile.idSponsorProfile).then(response => {
      switch (response?.code) {
        case 1:
          setTotalAudience(response.totalAudience);
          setGenderCount(response.genderCount);
          setAgeRangeCatalog(response.ageRangeCatalog);
          setEnterprises(response.enterprises);
          setAudienceLocation(response.audienceLocation);
          setLoader(false);
          break;

        case 1001:
          toastr.info("El perfil actual no se encuentra activo");
          setLoader(false);
          break;

        default:
          break;
      }
    })

    getAdvertisingByProfile(profile.idSponsorProfile).then(response => {
      switch (response?.code) {
        case 1:
          setPublicity({
            totalBanner: response.totalBanner,
            listAddBanner: response.listAddBanner,
            totalPromotion: response.totalPromotion,
            listAddPromotion: response.listAddPromotion,
            totalPublicity: response.totalPublicity,
            listAddPublicity: response.listAddPromotion
          })
          break;

        default:
          break;
      }
    })

    getAdsByProfile(profile.idSponsorProfile).then(response => {
      switch (response?.code) {
        case 1:
          setAdvertisement({
            totalAllViews: response.totalAllViews,
            totalAllClic: response.totalAllClic,
            totalAllHrs: response.totalAllHrs,
            totalAllAvg: response.totalAllAvg
          });
          break;

        default:
          break;
      }
    })

    getListMarketStudyReports(profile.idSponsorProfile).then(response => {
      switch (response?.code) {
        case 1:
          setMarketStudies(response.quizResume);
          break;

        default:
          break;
      }
    })

    return () => {
    }
  }, [profile]);

  const handleClickDownload = function () {
    setLoadData(true)
    toastr.info("Estamos preparando tu archivo...");
    getFileAdvertising(profile.idSponsorProfile).then((response) => {
      switch (response?.code) {
        case 1:
          window.open(response.urlFile);
          setLoadData(false)
          break;

        case 1001:
          toastr.info("El perfil actual no se encuentra activo");
          setLoadData(false)
          break;

        case 1002:
          toastr.info("El perfil actual no cuenta con información");
          setLoadData(false)
          break;

        default:
          toastr.error("Ocurrió un error, contacte a su administrador.", "Error");
          setLoadData(false)
          break;
      }
    })
  }

  if (loader) {
    return (
      <div className="reports__loader-container">
        <Oval color="#0095c2" secondaryColor="#fff" />
        <br />
        <span>Cargando resultados...</span>
      </div>
    )
  } else {
    return (
      <div className="col-11 mx-auto my-4" >
        <div className="row">
          <div className='col-12 my-4'>
            <b className='fs-5 text-blue-sec'>Reportes</b>
          </div>
          <div className="row bg-white_translucid-verylow shadow">
            <div className='col-12 my-3'>
              <b className='text-blue-sec'>Generales</b>
            </div>
            <div className='col-12 col-xxl-8 mb-3'>
              <Audience totalAudience={totalAudience} genderCount={genderCount} ageRangeCatalog={ageRangeCatalog} />
            </div>
            <div className='col-12 col-md-4 col-xxl-4 mb-3'>
              <Allocation enterprises={enterprises} />
            </div>
            <div className='col-12 col-md-8 col-xxl-12 mb-3'>
              <AudienceMap audienceLocation={audienceLocation} />
            </div>
            <div className='col-12 col-md-5'>
              <div className="my-3">
                <span className='text-blue-sec ms-3'>Publicidad</span>
              </div>
              <Publicity publicity={publicity} />
            </div>
            <div className='col-12 col-md-7'>
              {
                loadData ? (
                  <div className="my-3 d-flex align-items-center justify-content-end pointer">
                    <u className='text-blue-sec me-2'>Descargando archivos </u>
                    <TailSpin
                      color="#000"
                      height="20px"
                      width="auto"
                    />
                  </div>
                ) : (
                  <div
                    className="my-3 d-flex align-items-center justify-content-end pointer"
                    onClick={handleClickDownload}
                  >
                    <u className='fs-6 text-blue-sec'>Descarga los datos</u> <i className="ms-3 fs-6 bi bi-download"></i>
                  </div>
                )
              }
              <div className="my-3">
                <span className='text-blue-sec ms-3'>Anuncios</span>
              </div>
              <Advetisement advertisement={advertisement} />
            </div>
            <div className='col-12 mt-4 mb-2'>
              <b className='text-blue-sec'>Reportes</b>
              <br />
              <span className='text-blue-sec'>Estudios de mercado</span>
            </div>
            <div className='col-12 mb-3'>
              <MarketStudies marketStudies={marketStudies} idSponsorProfile={profile.idSponsorProfile} />
            </div>
          </div>
        </div>
      </div >
    )
  }
}

const mapStateToProps = ({ profile }) => ({
  profile
});

export default connect(mapStateToProps, {})(Reports);
