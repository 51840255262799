import React from 'react';
import { connect } from 'react-redux';
import ProfileForm from './ProfileForm';
import { useNavigate } from 'react-router-dom';

const ProfileScreen = ({ profile }) => {
    const navigate = useNavigate();

    return (
        <div className="col-11 bg-white_translucid-verylow box-shadow rounded-3 p-5 mx-auto my-4">
            <div className="row">
                <div className="col-12">
                    <div className="col-12">
                        <div className='row'>
                            <div className='col'>
                                <div
                                    className="d-flex"
                                    onClick={() => {
                                        navigate('overview');
                                    }}
                                >
                                    <h2 className="pull-up me-0 me-sm-5"><i className="bi bi-arrow-left-square-fill pointer"></i></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-4">
                        <ProfileForm profileInfo={profile} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ profile }) => {
    return {
        profile
    }
}

export default connect(mapStateToProps)(ProfileScreen);

