import axios from 'axios';

/**
 * Get General Reports Info (genderCount, ageRangeCatalog, enterprises, audienceLocation, totalAudience)
 * @returns JSON response
 */
export const getGeneralReport = async (idSponsorProfile) => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + `sponsor/reports/getGeneralReport?idSponsorProfile=${idSponsorProfile}`,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

/**
 *  Get Publicity Reports Info (totalBanner, listAddBanner, totalPromotion, listAddPromotion)
 * @param {int} idSponsorProfile 
 * @returns JSON response
 */
export const getAdvertisingByProfile = async (idSponsorProfile) => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + `sponsor/reports/getAdvertisingByProfile?idSponsorProfile=${idSponsorProfile}`,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

/**
 * Get Advertisement Reports Info (totalAllViews, totalAllClic, totalAllHrs, totalAllAvg)
 * @param {int} idSponsorProfile 
 * @returns 
 */
export const getAdsByProfile = async (idSponsorProfile) => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + `sponsor/reports/getAdsByProfile?idSponsorProfile=${idSponsorProfile}`,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

/**
 * Get MarketStudies reports (Array market studies cards)
 * @param {int} idSponsorProfile 
 * @returns JSON Response
 */
export const getListMarketStudyReports = async (idSponsorProfile) => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + `sponsor/reports/getListMarketStudyReports?idSponsorProfile=${idSponsorProfile}`,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

/**
 * get Specific Market Studies (Question radio, binary, date, open, check)
 * @param {int} idSponsorProfile
 * @param {int} idQuizSponsor 
 * @returns JSON Response
 */
export const getSpecificMarketStudy = async (idSponsorProfile, idQuizSponsor) => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + `sponsor/reports/getSpecificMarketStudy?idSponsorProfile=${idSponsorProfile}&idQuizSponsor=${idQuizSponsor}`,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

/**
 * Get file download market study
 * @param {int} idSponsorProfile 
 * @param {int} idQuizSponsor 
 * @returns JSON Response
 */
export const getFileMarketStudy = async (idSponsorProfile, idQuizSponsor) => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + `sponsor/reports/getFileMarketStudy?idSponsorProfile=${idSponsorProfile}&idQuizSponsor=${idQuizSponsor}`,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

/**
 * Get File reports download
 * @param {int} idSponsorProfile 
 * @returns URL download
 */
export const getFileAdvertising = async (idSponsorProfile) => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + `sponsor/reports/getFileAdvertising?idSponsorProfile=${idSponsorProfile}`,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};