import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route
} from 'react-router-dom';
import { connect } from 'react-redux';
import { AppOutlet } from './AppOutlet';
import { AuthOutlet } from './AuthOutlet';

const AppRouter = ({ user: { logged } }) => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<AppOutlet isAuthenticated={logged} />} />
                <Route path="sponsor-access/*" element={<AuthOutlet isAuthenticated={logged} />} />
            </Routes>
        </BrowserRouter>
    )
}

const mapStateToProps = ({ user }) => {
    return {
        user
    }
}

export default connect(mapStateToProps)(AppRouter)
