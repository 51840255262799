import React, { Fragment, useState } from 'react';
import $ from 'jquery';

const SurveyModal = ({ questionsArr, titleName }) => {
    const [counters, setCounters] = useState({
        answer_1: '',
        answer_2: '',
        answer_3: '',
        answer_4: '',
        answer_5: '',
        answer_6: '',
        answer_7: '',
        answer_8: '',
        answer_9: '',
        answer_10: '',
    });
    const counterAssignment = (num) => {
        let structure;
        switch (num) {
            case 1:
                structure = <span>{counters.answer_1.length}/250</span>;
                break;
            case 2:
                structure = <span>{counters.answer_2.length}/250</span>;
                break;
            case 3:
                structure = <span>{counters.answer_3.length}/250</span>;
                break;
            case 4:
                structure = <span>{counters.answer_4.length}/250</span>;
                break;
            case 5:
                structure = <span>{counters.answer_5.length}/250</span>;
                break;
            case 6:
                structure = <span>{counters.answer_6.length}/250</span>;
                break;
            case 7:
                structure = <span>{counters.answer_7.length}/250</span>;
                break;
            case 8:
                structure = <span>{counters.answer_8.length}/250</span>;
                break;
            case 9:
                structure = <span>{counters.answer_9.length}/250</span>;
                break;
            case 10:
                structure = <span>{counters.answer_10.length}/250</span>;
                break;
            default:
                structure = <span>0/250</span>;
                break;
        }
        return structure;
    }
    return (
        <div
            className="modal"
            id="surveyModal"
            style={{
                backgroundColor: 'rgba(255,255,255,0.9)'
            }}
            data-backdrop="false"
        >
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className='col-12 mb-3'>
                            <button
                                type="button"
                                className="btn-close float-end"
                                onClick={() => $('#surveyModal').hide()}
                            ></button>
                        </div>
                        <div className='col-12 mb-3'>
                            <div className='row'>
                                <div className='col-sm-12 col-md-3 col-lg-3 d-flex justify-content-center flex-wrap align-content-center'>
                                    <img
                                        src="../assets/img/logo_guay_color.png"
                                        alt="guay logo"
                                        className="w-50"
                                    />
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center flex-wrap align-content-center'>
                                    <h4>{titleName}</h4>
                                </div>
                            </div>
                        </div>
                        {
                            questionsArr.map((data, index) => (
                                <div className='col-12 mb-3'>
                                    <label className="form-label">{index + 1 + ') '}{data.description}</label>
                                    {
                                        data.idTypeQuestion === 1 ? (
                                            <Fragment>
                                                <textarea
                                                    className="form-control"
                                                    rows="2"
                                                    id={'answer_' + data.idOrder}
                                                    name={'answer_' + data.idOrder}
                                                    onChange={e => {
                                                        setCounters({
                                                            ...counters,
                                                            [e.target.name]: e.target.value
                                                        });
                                                    }}
                                                    style={{ resize: 'none' }}
                                                    maxLength='250'
                                                ></textarea>
                                                {counterAssignment(data.idOrder)}
                                            </Fragment>
                                        ) : (
                                            data.idTypeQuestion === 2 || data.idTypeQuestion === 3 ? (
                                                <div className='row'>
                                                    {
                                                        data.answers.map((answer, index) => (
                                                            <div
                                                                className='col-sm-12 col-md-6 col-lg-6'
                                                                key={'answer-' + data.idOrder + '-option-' + index}
                                                            >
                                                                <div className="form-check">
                                                                    <input
                                                                        type="radio"
                                                                        className="form-check-input"
                                                                        id={'answer-' + data.idOrder + '-option-' + index}
                                                                        name={'answer-' + data.idOrder}
                                                                        value={answer.idOrder}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor={'answer-' + data.idOrder + '-option-' + index}
                                                                    ><span className='fw-bold'>{index === 0 ? ('a) ') : (index === 1 ? ('b) ') : (index === 2 ? ('c) ') : (index === 3 ? ('d) ') : (index === 4 ? ('e) ') : (null)))))}</span>{answer.description}</label>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            ) : (
                                                data.idTypeQuestion === 4 ? (
                                                    <div className='row'>
                                                        {
                                                            data.answers.map((answer, index) => (
                                                                <div
                                                                    className='col-sm-12 col-md-6 col-lg-6'
                                                                    key={'answer-' + data.idOrder + '-option-' + index}
                                                                >
                                                                    <div className="form-check">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id={'answer-' + data.idOrder + '-option-' + index}
                                                                            name={'answer-' + data.idOrder + '-option-' + index}
                                                                            value={answer.idOrder}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor={'answer-' + data.idOrder + '-option-' + index}
                                                                        ><span className='fw-bold'>{index === 0 ? ('a) ') : (index === 1 ? ('b) ') : (index === 2 ? ('c) ') : (index === 3 ? ('d) ') : (index === 4 ? ('e) ') : (null)))))}</span>{answer.description}</label>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                ) : (
                                                    data.idTypeQuestion === 5 ? (
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            id={'answer-' + data.idOrder}
                                                            name={'answer-' + data.idOrder}
                                                        />
                                                    ) : (
                                                        null
                                                    )
                                                )
                                            )
                                        )
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SurveyModal;
