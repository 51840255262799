import React from 'react';
import Popup from 'reactjs-popup';

export const Footer = () => {
    return (
        <div className='col-12 bg-white py-2 text-blue-sec footer__footer-style footer__box-shadow fs-7'>
            <div className='row'>
                <div className='col-5 d-flex flex-wrap align-content-center'>
                    <Popup trigger={<span className="d-inline p-footerApp left pointer">Contáctanos</span>} position="top">
                        <div className="bg-white text-center p-2 rounded shadow">
                            Teléfono: <a href="tel:5517980619">5517980619</a>
                        </div>
                    </Popup> <span className='fw-lighter mx-2'>|</span>
                    <span className='pointer' onClick={() => window.open("https://somosguay.com/support")}>Ayuda y soporte</span> <span className='fw-lighter mx-2'>|</span>
                    <span className='pointer' onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf")}>Aviso de privacidad</span><span className='fw-lighter mx-2'>|</span>
                    <span className='pointer' onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Terminos_%26_Condiciones_generales_Plataforma_guay.pdf")}>Términos y Condiciones</span>
                </div>
                <div className='col-3 d-flex justify-content-center flex-wrap align-content-center'>
                    <span className='fw-lighter'>Copyright © 2021 guay</span>
                </div>
                <div className='col d-flex justify-content-end'>
                    <div className='text-yellow'>
                        <p className='text-center m-0 p-0'>Siguenos</p>
                        <a href="https://www.facebook.com/guay-107436667630740" target="_blank" rel="noopener noreferrer"><i className="text-green bi bi-facebook me-2" /></a>
                        <a href="https://www.linkedin.com/company/mesientoguay/" target="_blank" rel="noopener noreferrer"><i className="text-cia bi-linkedin mx-2" /></a>
                        <a href="https://instagram.com/guay.digital?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram mx-2 text-yellow" /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}
