import React, { Fragment, useState, /* useContext, */ useEffect } from 'react';
// API calls
import { getSprProfilebyUser, updSprProfilebyUser } from '../../models/sprSponsor_model';
import { updSprPasswordUser } from '../../models/sprUser_model';
// Tools
import $ from 'jquery';
import * as toastr from 'toastr';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

const Account = ({ user, profile }) => {
    const navigate = useNavigate();
    const { userId } = user;
    const [changePassword, setChangePassword] = useState(false);
    const [showDate, setShowDate] = useState(false);
    const [age, setAge] = useState(0);
    const [maxDate, setMaxDate] = useState("");
    const [minDate, setMinDate] = useState("");
    const [accountObj, setAccountObj] = useState({
        idUser: userId,
        idprofile: 0,
        name: '',
        lastName: '',
        phone: '',
        birthday: '',
        altMail: '',
        gender: '',
        active: 0,
    });
    const [userEmail, setUserEmail] = useState('');
    const {
        name,
        lastName,
        phone,
        birthday,
        altMail,
        gender,
    } = accountObj;
    const [password, updatePassword] = useState({
        actualPass: "",
        newPassA: "",
        newPassB: ""
    });
    const { actualPass, newPassA, newPassB } = password;
    const [files, updateFiles] = useState([]);
    const [showImg, setShowImg] = useState('');
    const [validation1, setValidation1] = useState(false);
    const [validation2, setValidation2] = useState(false);
    const [validation3, setValidation3] = useState(false);
    const [validation4, setValidation4] = useState(false);
    const [validation5, setValidation5] = useState(false);
    const [validation6, setValidation6] = useState(false);
    const [correctPassword, setCorrectPassword] = useState(false);
    useEffect(() => {
        let today = new Date();
        let min = today.getFullYear() - 100;
        let max = today.getFullYear() - 18;
        let mm = today.getMonth() + 1;
        let dd = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
        let mm_2 = mm < 10 ? "0" + mm : mm;
        setMinDate("" + min + "-" + mm_2 + "-" + dd + "");
        setMaxDate("" + max + "-" + mm_2 + "-" + dd + "");
        getSprProfilebyUser(userId).then((response) => {
            let hoy = new Date();
            let cumpleanos = new Date(response.userProfile[0].birthday);
            let edad = hoy.getFullYear() - cumpleanos.getFullYear();
            let m = hoy.getMonth() - cumpleanos.getMonth();
            if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
                edad--;
            }
            if (cumpleanos.getFullYear() === 1900) {
                setAge(0);
            } else {
                setAge(edad);
            }
            setUserEmail(response.userProfile[0].mainMail);
            setShowImg(response.userProfile[0].imgProfile);
            setAccountObj({
                idUser: userId,
                idprofile: profile.idSponsorProfile === undefined || profile.idSponsorProfile === 'undefined' ? (0) : (profile.idSponsorProfile),
                name: response.userInfo[0].name,
                lastName: response.userInfo[0].last_name,
                phone: response.userProfile[0].phone,
                birthday: response.userProfile[0].birthday,
                altMail: response.userProfile[0].altMail,
                gender: response.userProfile[0].gender,
                active: 1,
            });
        });
    }, [userId, profile]);
    const getPasswords = (e) => {
        try {
            let value = e.target.value;
            $(".border-danger").removeClass("border-danger");
            if (e.target.name === 'newPassA') {
                let counter = 0;
                let regexSpecial = /[!@#$%^&*._-]/
                let regexCapital = /[A-Z]/
                let regexNumber = /[0-9]/
                let regexLength = /().{6}/
                let regexWhitespace = /\s/
                let regexLower = /[a-z]/
                if (regexSpecial.test(value)) {
                    setValidation1(true);
                    counter++;
                } else {
                    setValidation1(false);
                }
                if (regexCapital.test(value)) {
                    setValidation2(true);
                    counter++;
                } else {
                    setValidation2(false);
                }
                if (regexNumber.test(value)) {
                    setValidation3(true);
                    counter++;
                } else {
                    setValidation3(false);
                }
                if (regexLength.test(value)) {
                    setValidation4(true);
                    counter++;
                } else {
                    setValidation4(false);
                }
                if (regexWhitespace.test(value)) {
                    setValidation5(true);
                } else {
                    setValidation5(false);
                    counter++;
                }
                if (regexLower.test(value)) {
                    setValidation6(true);
                    counter++;
                } else {
                    setValidation6(false);
                }
                if (counter === 6) {
                    setCorrectPassword(true);
                } else {
                    setCorrectPassword(false);
                }
            }
            updatePassword({
                ...password,
                [e.target.name]: value
            });
        } catch (error) {
            console.log(error);
        }
    }
    const validationPassword = (key) => {
        let validated = 0;
        let validated_2 = 0;
        switch (key) {
            case 1:
                if (actualPass === "") {
                    $("#actualPass").addClass("border-danger");
                    toastr.error('Falta llenar el campo: Constraseña actual', '¡Ooops!');
                } else {
                    validated = validated + 1;
                }
                if (newPassA === "") {
                    $("#newPassA").addClass("border-danger");
                    toastr.error('Falta llenar el campo: Nueva contraseña', '¡Ooops!');
                } else {
                    validated = validated + 1;
                }
                if (newPassB === "") {
                    $("#newPassB").addClass("border-danger");
                    toastr.error('Falta llenar el campo: Confirmar contraseña', '¡Ooops!');
                } else {
                    validated = validated + 1;
                }
                if (validated === 3) {
                    return true;
                }
                break;
            case 2:
                if (newPassA !== newPassB) {
                    $("#newPassA").addClass("border-danger");
                    $("#newPassB").addClass("border-danger");
                    toastr.error('Las contraseñas no coinciden.', '¡Ooops!');
                } else {
                    validated_2 = validated_2 + 1;
                }
                if (validated_2 === 1) {
                    return true;
                }
                break;
            default:
                break;
        }
    }
    const getRandomArbitrary = (min, max) => {
        return Math.floor(Math.random() * (max - min)) + min;
    }
    const setPassword = () => {
        try {
            $('#savePassBtn').attr('disabled', '')
            let validation_1 = validationPassword(1);
            let validation_2 = validationPassword(2);
            if (validation_1 === true) {
                if (validation_2 === true) {
                    // Password validations with regex structure
                    /* let passwordregex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*._-]).{6,}$/;
                    if (!passwordregex.test(actualPass)) {
                        $('#actualPass').addClass('border-danger');
                        toastr.warning('Contraseña con formato incorrecto.', '¡Ooops!');
                        return;
                    }
                    if (!passwordregex.test(newPassA)) {
                        $('#newPassA').addClass('border-danger');
                        toastr.warning('Contraseña con formato incorrecto.', '¡Ooops!');
                        return;
                    }
                    if (!passwordregex.test(newPassB)) {
                        $('#newPassB').addClass('border-danger');
                        toastr.warning('Contraseña con formato incorrecto.', '¡Ooops!');
                        return;
                    } */
                    if (!correctPassword) {
                        toastr.warning('Contraseña con formato incorrecto.', '¡Ooops!');
                        return;
                    }
                    const saltValueNewA = "Z3VheUE=";
                    const saltValueNewB = "Z3VheUI=";
                    const saltValueOld = "Z3VheQ==";

                    const saltfinishOld = "bWUgc2llbnRvIGd1YXk=";
                    const saltfinishA = "bWUgc2llbnRvIGd1YXkgQQ==";
                    const saltfinishB = "bWUgc2llbnRvIGd1YXkgQg==";

                    const randomNumOld = getRandomArbitrary(10, 100);
                    const randomNumA = getRandomArbitrary(10, 100);
                    const randomNumB = getRandomArbitrary(10, 100);

                    let password_1 = saltValueOld + randomNumOld + btoa(actualPass) + saltfinishOld;
                    let password_2 = saltValueNewA + randomNumA + btoa(newPassA) + saltfinishA;
                    let password_3 = saltValueNewB + randomNumB + btoa(newPassB) + saltfinishB;
                    let data = {
                        actualPass: password_1,
                        newPassA: password_2,
                        newPassB: password_3,
                        userId: user.userId,
                    }
                    let formData = new FormData();
                    formData.append("request", JSON.stringify(data));
                    updSprPasswordUser(formData).then((response) => {
                        if (response.code === 1 || response.code === 200) {
                            toastr.success('Se actualizó la contraseña correctamente.', '¡Bien hecho!');
                            $('#savePassBtn').removeAttr('disabled')
                            $("#actualPass, #newPassA, #newPassB").val("");
                            updatePassword({
                                actualPass: "",
                                newPassA: "",
                                newPassB: ""
                            });
                        } else {
                            switch (response.code) {
                                case 1002:
                                    toastr.error('La contraseña actual no coincide.', '¡Ooops!');
                                    break;
                                case 1003:
                                    toastr.error('Las contraseñas no coinciden.', '¡Ooops!');
                                    break;
                                default:
                                    toastr.error('No se actualizó la contraseña correctamente.', '¡Ooops!');
                                    break;
                            }
                            $('#savePassBtn').removeAttr('disabled')
                        }
                    });
                } else {
                    $('#savePassBtn').removeAttr('disabled')
                }
            } else {
                $('#savePassBtn').removeAttr('disabled')
            }
        } catch (error) {
            console.log(error);
        }
    }
    const onChangeHandler = (e) => {
        try {
            let fileTypes = [
                "image/png",
                "image/jpg",
                "image/jpeg",
                "image/tiff"
            ];
            let correct = false;
            let reader = new FileReader();
            let file = e.target.files[0];
            for (let x in fileTypes) {
                if (fileTypes[x] === e.target.files[0].type) {
                    correct = true;
                }
            }
            if (correct === true) {
                reader.onload = function (e) {
                    $('#img-person').attr('src', e.target.result);
                };
                reader.readAsDataURL(e.target.files[0]);
                updateFiles({
                    ...files,
                    file,
                });
            } else {
                toastr.error('El tipo de archivo no es correcto. Los tipos de archivo son: .png, .tiff, .jpg, .jpeg.', '¡Ooops!');
            }
        } catch (error) {
            console.log(error);
        }
    }
    const renderFilePers = () => {
        $("#file").click();
    }
    return (
        <div className="col-11 bg-white_translucid-verylow box-shadow rounded-3 p-5 mx-auto my-4">
            <div className="row">
                <div className="col-12">
                    <div className='row'>
                        <div
                            className="col-6"
                            onClick={() => {
                                navigate('overview');
                            }}
                        >
                            <h2 className="pull-up me-0 me-sm-5"><i className="bi bi-arrow-left-square-fill pointer"></i></h2>
                        </div>
                        <div className='col-6'>
                            <img
                                src='../assets/img/elementos_patrocinios-32-33.png'
                                alt='label'
                                style={{
                                    height: '50px',
                                    position: 'absolute',
                                    right: '2.5%',
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-12 d-flex justify-content-center align-items-center mb-3'>
                    <div className='text-center'>
                        <input
                            type="file"
                            id="file"
                            hidden
                            onChange={onChangeHandler} />
                        <div className='mb-3' onClick={() => renderFilePers()}>
                            {
                                showImg !== '' ? (
                                    <img
                                        src={showImg}
                                        style={{
                                            width: '120px',
                                            height: '120px'
                                        }}
                                        id="img-person"
                                        alt='img-profile'
                                    />
                                ) : (
                                    <img
                                        src='../assets/img/sin_logo.png'
                                        style={{
                                            width: '120px',
                                            height: '120px'
                                        }}
                                        id="img-person"
                                        alt='img-profile'
                                    />
                                )
                            }
                        </div>
                        <b className='text-cian'>{name + ' ' + lastName}</b>
                        <br />
                        <span className='text-cian'>{profile.organization}</span>
                        <br />
                        <b className='text-base'>{userEmail}</b>
                    </div>
                </div>
                <div className='col-12 my-4'>
                    <b className='fs-5 text-cian'>Perfil</b>
                </div>
                <div className='col-12 mb-3'>
                    <div className='row mb-4'>
                        <div className='col'>
                            <div className="row align-items-center">
                                <div className="col-12 col-md-3">
                                    <b className="col-form-label text-base text-break">Nombre</b>
                                </div>
                                <div className="col-12 col-md-9">
                                    <input
                                        type="text"
                                        className="form-control bg-blue-white border-0"
                                        placeholder="Nombre"
                                        maxLength='150'
                                        id='name'
                                        name='name'
                                        value={name}
                                        onChange={(e) => {
                                            setAccountObj({
                                                ...accountObj,
                                                [e.target.name]: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className="row align-items-center">
                                <div className="col-12 col-md-3">
                                    <b className="col-form-label text-base text-break">Edad</b>
                                </div>
                                <div className="col-12 col-md-9">
                                    <div className='input-group'>
                                        {
                                            showDate ? (
                                                <Fragment>
                                                    <input
                                                        type="date"
                                                        className="form-control bg-blue-white border-0"
                                                        id='birthday'
                                                        name='birthday'
                                                        min={minDate}
                                                        max={maxDate}
                                                        onChange={(e) => {
                                                            let hoy = new Date();
                                                            let cumpleanos = new Date(e.target.value);
                                                            let edad = hoy.getFullYear() - cumpleanos.getFullYear();
                                                            let m = hoy.getMonth() - cumpleanos.getMonth();
                                                            if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
                                                                edad--;
                                                            }
                                                            setAge(edad);
                                                            setAccountObj({
                                                                ...accountObj,
                                                                [e.target.name]: e.target.value
                                                            });
                                                        }}
                                                        value={birthday}
                                                    />
                                                    <span
                                                        className="input-group-text pointer fw-bold"
                                                        onClick={() => setShowDate(false)}
                                                    >Ver Edad</span>
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    <input
                                                        type="text"
                                                        className="form-control border-0"
                                                        value={age}
                                                        readOnly
                                                    />
                                                    <span
                                                        className="input-group-text border-0 pointer fw-bold"
                                                        onClick={() => setShowDate(true)}
                                                    >Editar</span>
                                                </Fragment>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col'>
                            <div className="row align-items-center">
                                <div className="col-12 col-md-3">
                                    <b className="col-form-label text-base text-break">Apellido</b>
                                </div>
                                <div className="col-12 col-md-9">
                                    <input
                                        type="text"
                                        className="form-control bg-blue-white border-0"
                                        placeholder="Apellido"
                                        maxLength='150'
                                        id='lastName'
                                        name='lastName'
                                        value={lastName}
                                        onChange={(e) => {
                                            setAccountObj({
                                                ...accountObj,
                                                [e.target.name]: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className="row align-items-center">
                                <div className="col-12 col-md-3">
                                    <b className="col-form-label text-base text-break">Género</b>
                                </div>
                                <div className="col-12 col-md-9">
                                    <select
                                        className="form-select bg-blue-white border-0"
                                        id='gender'
                                        name='gender'
                                        value={gender}
                                        onChange={(e) => {
                                            setAccountObj({
                                                ...accountObj,
                                                [e.target.name]: e.target.value
                                            });
                                        }}
                                    >
                                        <option value="">Seleccionar</option>
                                        <option value="1">Omitir</option>
                                        <option value="2">Femenino</option>
                                        <option value="3">Masculino</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col'>
                            <div className="row align-items-center">
                                <div className="col-12 col-md-3">
                                    <b className="col-form-label text-base text-break">Télefono</b>
                                </div>
                                <div className="col-12 col-md-9">
                                    <input
                                        type="number"
                                        className="form-control bg-blue-white border-0"
                                        placeholder="Número a 10 dígitos"
                                        id='phone'
                                        name='phone'
                                        value={phone}
                                        onChange={(e) => {
                                            if (e.target.value.length < 11) {
                                                setAccountObj({
                                                    ...accountObj,
                                                    [e.target.name]: e.target.value
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className="row align-items-center">
                                <div className="col-12 col-md-3">
                                    <b className="col-form-label text-base text-break">Correo sec.</b>
                                </div>
                                <div className="col-12 col-md-9">
                                    <input
                                        type="email"
                                        className="form-control bg-blue-white border-0"
                                        placeholder="correo@correo.com"
                                        maxLength='150'
                                        id='altMail'
                                        name='altMail'
                                        value={altMail}
                                        onChange={(e) => {
                                            setAccountObj({
                                                ...accountObj,
                                                [e.target.name]: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 mb-3'>
                        <button
                            className='btn btn-sm bg-blue-navy text-white float-end'
                            onClick={() => {
                                $('#submitBtn').attr('disabled', '')
                                if (name.trim() === '') {
                                    toastr.warning('El nombre es obligatorio.', '¡Ooops!');
                                    return;
                                }
                                if (lastName.trim() === '') {
                                    toastr.warning('El apellido es obligatorio.', '¡Ooops!');
                                    return;
                                }
                                if (phone !== '') {
                                    if (phone.length !== 10) {
                                        toastr.warning('El número debe ser a 10 digitos.', '¡Ooops!');
                                        return;
                                    }
                                }
                                if (altMail !== '') {
                                    // eslint-disable-next-line
                                    let emailregex = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
                                    /* let emailregex = /^\w+([\.-]?\w+)*@[a-zA-Z0-9_]+?\.[a-zA-Z0-9_]{2,12}.*$/; */
                                    if (!emailregex.test(altMail)) {
                                        toastr.warning('Correo electrónico con formato incorrecto.', '¡Ooops!');
                                        return;
                                    }
                                }
                                let formData = new FormData();
                                let n = birthday.split('-');
                                let data = {
                                    ...accountObj,
                                    birthday: n[0] + '/' + n[1] + '/' + n[2]
                                }
                                formData.append("file", files.file);
                                formData.append("request", JSON.stringify(data));
                                updSprProfilebyUser(formData).then((response) => {
                                    if (response) {
                                        switch (response.code) {
                                            case 1:
                                            case 200:
                                                toastr.success('Se actualizó correctamente.', '¡Éxito!');
                                                break;
                                            case 404:
                                                toastr.error('No se encontro el id del usuario.', '¡Ooops!');
                                                break;
                                            case 1001:
                                                toastr.error('Problemas con algún dato en la petición.', '¡Ooops!');
                                                break;
                                            default:
                                                toastr.error('Problema con el servidor. Comunicarse con el equipo de soporte.', '¡Ooops!');
                                                break;
                                        }
                                        $('#submitBtn').removeAttr('disabled')
                                    } else {
                                        toastr.error('Problema con el servidor. Intente más tarde.', '¡Ooops!');
                                        $('#submitBtn').removeAttr('disabled')
                                    }
                                });
                            }}
                            id='submitBtn'
                        >Guardar</button>
                    </div>
                </div>
                <div className='col-12 my-4'>
                    <b className='fs-5 text-cian'>Cuenta</b>
                </div>
                <div className='col-12'>
                    <div className='row mb-4'>
                        <div className='col'>
                            <div className="row align-items-center">
                                <div className="col-12 col-md-3">
                                    <b className="col-form-label text-base text-break">Usuario</b>
                                </div>
                                <div className="col-12 col-md-9">
                                    <input
                                        type="email"
                                        className="form-control border-0"
                                        maxLength='150'
                                        readOnly
                                        value={userEmail}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className="row align-items-center">
                                <div className="col-12 col-md-3">
                                    <b className="col-form-label text-base text-break">Contraseña</b>
                                </div>
                                <div className="col-12 col-md-9">
                                    <input
                                        type="password"
                                        className="form-control border-0"
                                        maxLength='150'
                                        readOnly
                                        value={userEmail}
                                    />
                                </div>
                                <div className='col-12 text-end'>
                                    <u
                                        className='fw-bold text-green pointer'
                                        onClick={() => {
                                            if (changePassword === true) {
                                                setChangePassword(!changePassword);
                                                $("#actualPass, #newPassA, #newPassB").val("");
                                                updatePassword({
                                                    actualPass: "",
                                                    newPassA: "",
                                                    newPassB: ""
                                                });
                                            } else {
                                                setChangePassword(!changePassword);
                                            }
                                        }}
                                    >Cambiar</u>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        changePassword ? (
                            <Fragment>
                                <div className='row mb-4'>
                                    <div className='col'>
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-3">
                                                <b className="col-form-label text-base text-break">Contraseña actual</b>
                                            </div>
                                            <div className="col-12 col-md-9">
                                                <div className="input-group">
                                                    <input
                                                        type="password"
                                                        className="form-control bg-blue-white border-0"
                                                        placeholder="Contraseña"
                                                        maxLength='150'
                                                        id="actualPass"
                                                        name="actualPass"
                                                        onChange={getPasswords}
                                                        autoComplete="off"
                                                    />
                                                    <span className="input-group-text border-0">
                                                        <i
                                                            className="bi bi-eye"
                                                            id="icon_eye_2"
                                                            onClick={() => {
                                                                if ($("#actualPass").attr("type") === "password") {
                                                                    $("#actualPass").attr("type", "text");
                                                                    $("#icon_eye_2").attr("class", "bi bi-eye-slash");
                                                                } else {
                                                                    $("#actualPass").attr("type", "password");
                                                                    $("#icon_eye_2").attr("class", "bi bi-eye");
                                                                }
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                    </div>
                                </div>
                                <div className='row mb-4'>
                                    <div className='col'>
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-3">
                                                <b className="col-form-label text-base text-break">Nueva contraseña</b>
                                            </div>
                                            <div className="col-12 col-md-9">
                                                <div className="input-group">
                                                    <input
                                                        type="password"
                                                        className="form-control bg-blue-white border-0"
                                                        placeholder="Contraseña"
                                                        maxLength='150'
                                                        id='newPassA'
                                                        name='newPassA'
                                                        onChange={getPasswords}
                                                        autoComplete="off"
                                                    />
                                                    <span className="input-group-text border-0">
                                                        <i
                                                            className="bi bi-eye"
                                                            id="icon_eye_3"
                                                            onClick={() => {
                                                                if ($("#newPassA").attr("type") === "password") {
                                                                    $("#newPassA").attr("type", "text");
                                                                    $("#icon_eye_3").attr("class", "bi bi-eye-slash");
                                                                } else {
                                                                    $("#newPassA").attr("type", "password");
                                                                    $("#icon_eye_3").attr("class", "bi bi-eye");
                                                                }
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-3">
                                                <b className="col-form-label text-base text-break">Confirmación</b>
                                            </div>
                                            <div className="col-12 col-md-9">
                                                <div className="input-group">
                                                    <input
                                                        type="password"
                                                        className="form-control bg-blue-white border-0"
                                                        placeholder="Contraseña"
                                                        maxLength='150'
                                                        id='newPassB'
                                                        name='newPassB'
                                                        onChange={getPasswords}
                                                        autoComplete="off"
                                                    />
                                                    <span className="input-group-text border-0">
                                                        <i
                                                            className="bi bi-eye"
                                                            id="icon_eye_4"
                                                            onClick={() => {
                                                                if ($("#newPassB").attr("type") === "password") {
                                                                    $("#newPassB").attr("type", "text");
                                                                    $("#icon_eye_4").attr("class", "bi bi-eye-slash");
                                                                } else {
                                                                    $("#newPassB").attr("type", "password");
                                                                    $("#icon_eye_4").attr("class", "bi bi-eye");
                                                                }
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="fs-7">
                                    <span className="fw-bold">Requisitos:</span> Utiliza más de
                                    <span className={validation4 ? ('text-green fw-bold') : ('text-blue fw-bold')}> 6 carácteres, </span>
                                    <span className={validation2 ? ('text-green fw-bold') : ('text-blue fw-bold')}>1 mayúscula, </span>
                                    <span className={validation6 ? ('text-green fw-bold') : ('text-blue fw-bold')}>1 minuscula, </span>
                                    <span className={validation3 ? ('text-green fw-bold') : ('text-blue fw-bold')}>1 número, </span>
                                    <span className={validation1 ? ('text-green fw-bold') : ('text-blue fw-bold')}>1 símbolo (!@#$%^&*_-.), </span>
                                    <span className={validation5 === false ? ('text-green fw-bold') : ('text-blue fw-bold')}>sin espacios</span>
                                </p>
                                <button
                                    className='btn btn-sm bg-blue-navy text-white'
                                    onClick={() => setPassword()}
                                    id='savePassBtn'
                                >Cambiar</button>
                            </Fragment>
                        ) : (null)
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ user, profile }) => {
    return {
        user,
        profile
    }
}

export default connect(mapStateToProps)(Account);
