import React from 'react';
import {
    Routes,
    Route,
    Navigate
} from 'react-router-dom';
// Components
import Menu from '../components/genericComponents/Menu';
import Presence from '../components/sponsorConfiguration/Presence/Presence';
import ProfileScreen from '../components/profile/ProfileScreen';
import Header from '../components/genericComponents/Header';
import { Footer } from '../components/genericComponents/Footer';
// overview
import Overview from '../components/overview/Overview';
import Account from '../components/overview/Account';
import MarketStudiesScreen from '../components/marketStudies/MarketStudiesScreen';
import QuestionCreator from '../components/marketStudies/QuestionCreator';
import Reports from '../components/reports/ReportsScreen';

export const AppOutlet = ({ isAuthenticated }) => {

    return isAuthenticated ? (
        <div className="row">
            <Header />
            <div className="col-12 col-lg-3 col-xxl-2">
                <div className="row">
                    <Menu />
                </div>
            </div>
            <div className="col-12 col-lg-9 col-xxl-10 mb-5">
                <div className="row">
                    <Routes>
                        <Route path="overview" element={<Overview />} />
                        <Route path="overview/profile" element={<ProfileScreen />} />
                        <Route path="overview/general-account" element={<Account />} />
                        <Route path="configuration" element={<Presence />} />
                        <Route path="market-studies" element={<MarketStudiesScreen />} />
                        <Route path="market-studies-creator" element={<QuestionCreator />} />
                        <Route path="reports" element={<Reports />} />

                        <Route path="*" element={<Navigate replace to='overview' />} />

                    </Routes>
                </div>
            </div>
            <Footer />
        </div>
    ) : (
        <Navigate replace to="sponsor-access/login" />
    );
}
