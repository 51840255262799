import React, { Fragment, useEffect, useState } from "react";
import GoogleMapReact from 'google-map-react';
import { getLatLngFromState } from "../../helpers/helpers";


const AudienceMap = ({ audienceLocation }) => {

    const [heatMapData, setHeatMapData] = useState({
        positions: [],
        audienceLocation: [],
        options: {
            radius: 20,
            opacity: 0.6,
        }
    });

    useEffect(() => {
        const audience = audienceLocation.map(location => ({
            sovereignState: location.description,
            numCollabs: location.numCollabs,
            coords: { ...getLatLngFromState(location.idState) }
        }))
        setHeatMapData({
            positions: audience.map(audience => ({ ...audience.coords })),
            audienceLocation: audienceLocation.map(location => ({description: location.description, percentage: location.percentage, idState: location.idState})),
            options: {
                radius: 20,
                opacity: 0.6,
            }
        })

    }, [audienceLocation])

    return (
        <div className='card reports__cards-style' style={{height: "350px"}}>
            <div className='card-body'>
                <div className='row h-100'>
                    <div className='col-12 text-center mb-3'>
                        <span className='fs-6 text-blue-sec'>Audiencia por ubicación</span>
                    </div>
                    <div className='col-12' style={{ width: "100%", height: "90%" }}>
                        <div className="reports__div_map_info">
                            {
                                heatMapData.audienceLocation.map(location =>
                                    <Fragment key={location.idState}>
                                        <span className="fs-7">{`${location.description} (${location.percentage}%)`}</span><br />
                                    </Fragment>
                                )
                            }
                        </div>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyBgC8VAUgZHdOxXhfCXHD9UmfuUAe8e2ek", libraries: ["visualization"] }}
                            defaultCenter={{
                                lat: 24.784885625431258,
                                lng: -96.87078125
                            }}
                            defaultZoom={4}
                            heatmap={heatMapData}
                        >
                        </GoogleMapReact>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AudienceMap;