import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setUpdateQuiz } from '../../models/quizSponsor_model';
import { doSetQuizInitialState } from '../../redux/quizDuck';
import * as toastr from 'toastr';
import { convertDateToString } from '../../helpers/helpers';
import QuestionCreator from './QuestionCreator';
import Datepicker, { registerLocale } from 'react-datepicker';
import { getDay, addDays } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import $ from 'jquery';

const QuizSponsorSpecific = ({ quizSpecific, profile, doSetQuizInitialState, hiddeForm, setHiddeForm }) => {

    const { quizDetails } = quizSpecific;
    const [idSurvey, setIDSurvey] = useState(0);
    registerLocale('es', es);
    const today = new Date();

    const [values, setValues] = useState({
        title: quizDetails.title !== undefined ? quizDetails.title : '',
        description: quizDetails.description !== undefined ? quizDetails.description : '',
        initialDate: quizDetails.initialDate !== undefined ? quizDetails.initialDate : '',
        finalDate: quizDetails.finalDate !== undefined ? quizDetails.finalDate : '',
        limitAudience: profile.limitAudience !== undefined ? profile.limitAudience : 0,
        active: quizDetails.active !== undefined ? quizDetails.active : 1
    });

    const [showCreator, setShowCreatore] = useState(false);

    const handleSwitchChange = ({ target }) => {
        if (formatter(values.initialDate) > new Date() && (values.active === 0 || values.active === '')) {
            if (target.checked === true) {
                setValues(prevState => ({
                    ...prevState,
                    active: 1
                }));
            } else {
                setValues(prevState => ({
                    ...prevState,
                    active: 0
                }));
            }
        } else {
            if (values.active === 1) {
                if (target.checked === true) {
                    setValues(prevState => ({
                        ...prevState,
                        active: 1
                    }));
                } else {
                    setValues(prevState => ({
                        ...prevState,
                        active: 0
                    }));
                }
            } else {
                toastr.warning('No puedes activar hasta que selecciones una fecha valida.', '¡Ooops!');
            }
        }
    }

    const handleInputInitialDate = (date) => {
        let today = new Date(date);
        let y = today.getFullYear();
        let m = today.getMonth() + 1;
        let d = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
        let m_2 = m < 10 ? "0" + m : m;
        let intDate = "" + y + "-" + m_2 + "-" + d + "";
        const split = intDate.split('-');
        const dateNew = new Date(split[0], split[1], split[2]);
        $(`#month-1`).removeClass('span-circle-market-unselected');
        $(`#month-1`).addClass('span-circle-market-selected bg-blue-guay');
        $(`#month-2`).removeClass('span-circle-market-selected bg-yellow-guay');
        $(`#month-2`).addClass('span-circle-market-unselected');
        $(`#month-3`).removeClass('span-circle-market-selected bg-pink-guay');
        $(`#month-3`).addClass('span-circle-market-unselected');
        setValues(prevState => ({
            ...prevState,
            initialDate: intDate,
            finalDate: convertDateToString(dateNew)
        }));
    }

    const handleChangeDuration = (months) => {
        if (values.initialDate === '') {
            toastr.warning('Primero selecciona una fecha de inicio.', '¡Ooops!');
            return;
        }
        var date = new Date(values.initialDate),
            mnth = ("0" + (date.getMonth() + 1 + months)),
            day = ("0" + date.getDate()).slice(-2);
        let newDate = [date.getFullYear(), mnth, day].join("-");
        if (months === 1) {
            $(`#month-1`).removeClass('span-circle-market-unselected');
            $(`#month-1`).addClass('span-circle-market-selected bg-blue-guay');
            $(`#month-2`).removeClass('span-circle-market-selected bg-yellow-guay');
            $(`#month-2`).addClass('span-circle-market-unselected');
            $(`#month-3`).removeClass('span-circle-market-selected bg-pink-guay');
            $(`#month-3`).addClass('span-circle-market-unselected');
        }
        if (months === 2) {
            $(`#month-2`).removeClass('span-circle-market-unselected');
            $(`#month-2`).addClass('span-circle-market-selected bg-yellow-guay');
            $(`#month-1`).removeClass('span-circle-market-selected bg-blue-guay');
            $(`#month-1`).addClass('span-circle-market-unselected');
            $(`#month-3`).removeClass('span-circle-market-selected bg-pink-guay');
            $(`#month-3`).addClass('span-circle-market-unselected');
        }
        if (months === 3) {
            $(`#month-3`).removeClass('span-circle-market-unselected');
            $(`#month-3`).addClass('span-circle-market-selected bg-pink-guay');
            $(`#month-2`).removeClass('span-circle-market-selected bg-yellow-guay');
            $(`#month-2`).addClass('span-circle-market-unselected');
            $(`#month-1`).removeClass('span-circle-market-selected bg-blue-guay');
            $(`#month-1`).addClass('span-circle-market-unselected');
        }
        setValues(prevState => ({
            ...prevState,
            finalDate: newDate
        }));
    }

    const formatter = (date) => {
        let format = new Date(date + 'T00:00:00');
        return format;
    }

    const handleClickGuardar = (e) => {
        e.preventDefault();
        $('#submitBtn_1').attr('type', 'button')
        $('#submitBtn_1').attr('disabled', '')
        if (formatter(values.initialDate) > addDays(new Date(), 1)) {
            setUpdateQuiz(quizDetails.idQuizSponsor, quizDetails.idProfile, values.title, values.description, values.initialDate, values.finalDate, values.limitAudience, values.active).then(response => {
                switch (response?.code) {
                    case 1:
                        setIDSurvey(response.idQuizSponsor);
                        setShowCreatore(true);
                        toastr.success('Se ha guardado correctamente su cuestionario.');
                        setHiddeForm(true);
                        break;

                    case 99:
                        toastr.error('Ocurrió un error al guardar, verifique su conexión a internet.');
                        break;

                    case 1000:
                        toastr.warning('Verifique que todos sus campos se encuentran completos.');
                        break;

                    case 1001:
                        toastr.error('El perfil seleccionado no se encuentra activo, imposible actualizar.');
                        break;

                    default:
                        toastr.error('Ocurrió un error desconocido, verifique su conexión a internet.');
                        break;
                }
                $('#submitBtn_1').attr('type', 'submit')
                $('#submitBtn_1').removeAttr('disabled')
            });
        } else {
            toastr.warning('No puedes guardar hasta que selecciones una fecha valida.', '¡Ooops!');
            $('#submitBtn_1').attr('type', 'submit')
            $('#submitBtn_1').removeAttr('disabled')
        }
    }

    const isWeekday = (date) => {
        const day = getDay(date);
        return day !== 0 && day !== 6;
    };

    const handleDefaultMonthSelection = () => {
        var past_date = new Date(values.initialDate);
        var current_date = new Date(values.finalDate);
        var difference = (current_date.getFullYear() * 12 + current_date.getMonth()) - (past_date.getFullYear() * 12 + past_date.getMonth());
        console.log(difference)
        if (difference === 1) {
            $(`#month-1`).removeClass('span-circle-market-unselected');
            $(`#month-1`).addClass('span-circle-market-selected bg-blue-guay');
            $(`#month-2`).removeClass('span-circle-market-selected bg-yellow-guay');
            $(`#month-2`).addClass('span-circle-market-unselected');
            $(`#month-3`).removeClass('span-circle-market-selected bg-pink-guay');
            $(`#month-3`).addClass('span-circle-market-unselected');
        }
        if (difference === 2) {
            $(`#month-2`).removeClass('span-circle-market-unselected');
            $(`#month-2`).addClass('span-circle-market-selected bg-yellow-guay');
            $(`#month-1`).removeClass('span-circle-market-selected bg-blue-guay');
            $(`#month-1`).addClass('span-circle-market-unselected');
            $(`#month-3`).removeClass('span-circle-market-selected bg-pink-guay');
            $(`#month-3`).addClass('span-circle-market-unselected');
        }
        if (difference === 3) {
            $(`#month-3`).removeClass('span-circle-market-unselected');
            $(`#month-3`).addClass('span-circle-market-selected bg-pink-guay');
            $(`#month-2`).removeClass('span-circle-market-selected bg-yellow-guay');
            $(`#month-2`).addClass('span-circle-market-unselected');
            $(`#month-1`).removeClass('span-circle-market-selected bg-blue-guay');
            $(`#month-1`).addClass('span-circle-market-unselected');
        }
    }
    return (
        <div className='row w-100'>
            {
                hiddeForm ? (
                    <div className='col-12'>
                        <span className='text-base'>Título: <span className='fw-bold'>{values.title}</span> <span className='text-green pointer' onClick={() => { setHiddeForm(false); setTimeout(handleDefaultMonthSelection, 1000) }}>Editar <i className='bi bi-pencil' /></span></span>
                    </div>
                ) : (
                    <form onSubmit={handleClickGuardar}>
                        <div className='row mb-4'>
                            <div className='col'>
                                <div className="row align-items-center">
                                    <div className="col-2">
                                        <b className="col-form-label text-base">Título</b>
                                    </div>
                                    <div className="col-10">
                                        <input type="text" className="form-control bg-blue-white border-0"
                                            name="title"
                                            value={values.title}
                                            onChange={(e) => {
                                                setValues(prevState => ({
                                                    ...prevState,
                                                    title: e.target.value
                                                }))
                                            }}
                                            maxLength="50"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col'>
                                <div className="row align-items-center">
                                    <div className="col-2">
                                        <b className="col-form-label text-base">Descripción</b>
                                    </div>
                                    <div className="col-10">
                                        <input type="text" className="form-control bg-blue-white border-0"
                                            placeholder={quizDetails.description}
                                            name="description"
                                            value={values.description}
                                            onChange={(e) => {
                                                setValues(prevState => ({
                                                    ...prevState,
                                                    description: e.target.value
                                                }))
                                            }}
                                            maxLength="150"
                                            autoComplete='off'
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col'>
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <b className="col-form-label text-base">Fecha inicial</b>
                                    </div>
                                    <div className="col-8">
                                        <Datepicker
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control bg-blue-white border-0"
                                            id="initialDate"
                                            name="initialDate"
                                            selected={values.initialDate === '' ? ('') : (formatter(values.initialDate))}
                                            onChange={date => handleInputInitialDate(date)}
                                            filterDate={isWeekday}
                                            excludeDates={today.getDay() === 5 ? (
                                                [addDays(new Date(), 1)]
                                            ) : (
                                                [addDays(new Date(), 1)]
                                            )}
                                            minDate={addDays(new Date(), 1)}
                                            locale="es"
                                            required
                                            autoComplete='off'
                                            placeholderText='dd/mm/aaaa'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className="row">
                                    <div className="col-3 d-flex flex-wrap align-content-center">
                                        <b className="text-base">Duración</b>
                                    </div>
                                    <div className="col-4">
                                        <div className='row'>
                                            <div className='col-12 d-flex justify-content-around bg-gray rounded-pill py-1 box-shadow'>
                                                <span
                                                    className='span-circle-market-unselected pointer border-blue-guay'
                                                    id='month-1'
                                                    onClick={() => handleChangeDuration(1)}
                                                >1</span>
                                                <span
                                                    className='span-circle-market-unselected pointer border-yellow-guay'
                                                    id='month-2'
                                                    onClick={() => handleChangeDuration(2)}
                                                >2</span>
                                                <span
                                                    className='span-circle-market-unselected pointer border-pink-guay'
                                                    id='month-3'
                                                    onClick={() => handleChangeDuration(3)}
                                                >3</span>
                                            </div>
                                            <div className='col-12 text-center'>
                                                <b className="text-base fs-7">Meses</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 d-flex justify-content-center flex-wrap align-content-center">
                                        <span className="text-base fw-bold">{values.finalDate}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col'>
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <b className="col-form-label text-base">Audiencia patrocinada</b>
                                    </div>
                                    <div className="col-8">
                                        <input type="number" className="form-control bg-blue-white border-0"
                                            name="audience"
                                            defaultValue={values.limitAudience}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className="row align-items-center">
                                    <div className="col-2 d-flex flex-wrap align-content-center">
                                        <b className="col-form-label text-base">Activo</b>
                                    </div>
                                    <div className="col-8 d-flex flex-wrap align-content-center">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input bg-green-check" type="checkbox" role="switch"
                                                name='active'
                                                checked={values.active === 1 ? true : false}
                                                onChange={handleSwitchChange}
                                            />
                                            <label className="fw-bold form-check-label">{values.active === 1 ? 'activado' : 'desactivado'}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 mt-4 d-flex justify-content-end'>
                            <button
                                type="submit"
                                className='btn btn-sm bg-pink-guay text-white'
                                id='submitBtn_1'
                            >Guardar</button>
                        </div>
                    </form>
                )
            }
            {
                (showCreator || quizDetails.title !== undefined) && (
                    <QuestionCreator
                        showCreator={showCreator}
                        idSurvey={idSurvey}
                    />
                )
            }
        </div>
    );
};

const mapStateToProps = ({ quizSpecific, profile }) => {
    return {
        profile,
        quizSpecific
    }
}

export default connect(mapStateToProps, { doSetQuizInitialState })(QuizSponsorSpecific);
