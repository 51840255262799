import React from 'react';
import Chart from "react-apexcharts";
import { colorsArray2 } from "../../../helpers/helpers";

const QuestionTypeRadio = ({ question }) => {

    const dataQuestion = {
        series: question.quizAnswersList.map(answer => parseInt(answer.percentAnsw)),
        options: {
            chart: {
                type: 'pie',
            },
            colors: colorsArray2,
            labels: question.quizAnswersList.map(answer => answer.descriptionAnswer),
            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'center',
                markers: {
                    width: 12,
                    height: 12,
                    radius: 12,
                },
                itemMargin: {
                    vertical: 7
                },
                offsetY: 7
            },
            stroke: {
                show: false
            },
            dataLabels: {
                enabled: true,
                formatter: (value) => value.toFixed(2) + "%",
                style: {
                    colors: ['#15253c']
                },
                dropShadow: {
                    enabled: false,
                }
            },
            tooltip: {
                theme: 'dark',
                fillSeriesColor: false,
                y: {
                    show: true,
                    formatter: (value) => value + "%",
                    title: {
                        formatter: (seriesName) => seriesName,
                    },
                },
                marker: {
                    show: true,
                },
            }
        }
    };

    return (
        <div className='col-12 col-md-6 p-3 mb-3 mb-md-0'>
            <p className='fs-6 text-gray fw-bold'>{question.description}</p>
            <Chart
                options={dataQuestion.options}
                series={dataQuestion.series}
                type="pie"
                height={350}
            />
        </div>
    )
}

export default QuestionTypeRadio