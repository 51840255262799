import React from 'react';
import Chart from "react-apexcharts";
import { colorsArray2 } from "../../../helpers/helpers";

const QuestionTypeCheck = ({ question }) => {

    const data1 = {
        series: [{
            name: question.description,
            data: question.quizAnswersList.map(answer => answer.percentAnsw)
        }],
        options: {
            chart: {
                toolbar: {
                    show: false
                }
            },
            colors: colorsArray2,
            plotOptions: {
                bar: {
                    barHeight: '50%',
                    distributed: true,
                    horizontal: true,
                    borderRadius: 7,
                    dataLabels: {
                        position: 'bottom',
                        maxItems: 100,
                        orientation: "horizontal",
                        style: {
                            colors: ['#15253c']
                        },
                    }
                }
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                    return `${opts.w.config.xaxis.categories[opts.dataPointIndex]} ${val}%`;
                },
                offsetX: 75,
                style: {
                    colors: ['#15253c'],
                    fontSize: '14px',
                },
                // dropShadow: {
                //     enabled: true,
                //     top: 1,
                //     left: 1,
                //     blur: 1,
                //     color: '#000',
                //     opacity: 1
                // }
            },
            xaxis: {
                type: 'category',
                // categories: ["Pantalón de mezclilla", "Pantalón o falda sastre", "Pantalón o falda casual", "Blusa o camisa", "Saco o blazer"],
                categories: question.quizAnswersList.map(answer => answer.descriptionAnswer),
                labels: {
                    show: true
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: true,
                }
            },
            yaxis: {
                labels: {
                    show: false
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                }
            },
            tooltip: {
                theme: 'dark',
                x: {
                    show: false
                },
                y: {
                    show: false,
                    formatter: (value) => value + "%",
                    title: {
                        formatter: () => ''
                    }
                },
                marker: {
                    show: true,
                },
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'round',
                colors: colorsArray2,
                width: 2,
                dashArray: 0,
            }
        }
    }

    return (
        <div className='col-12 col-md-6 p-3 mb-3 mb-md-0'>
            <p className='fs-6 text-gray fw-bold'>{question.description}</p>
            <Chart
                options={data1.options}
                series={data1.series}
                type="bar"
                height={350}
            />
        </div>
    )
}

export default QuestionTypeCheck