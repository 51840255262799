import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from '../../hooks/useForm';
import Recaptcha from "react-google-invisible-recaptcha";
import * as toastr from 'toastr';
import $ from 'jquery';
import { doLoginAction } from '../../redux/authDuck';
import { connect } from 'react-redux';

const LoginScreen = ({ user, doLoginAction }) => {

    const [humanDetected, setHumanDetected] = useState(false);

    const recaptcha = useRef();

    useEffect(() => {
        if (user.error) {
            toastr.error(user.error.message);
        }
    }, [user.error]);


    const initialForm = {
        email: '',
        password: '',
    }

    const [formValues, handleInputChange, reset] = useForm(initialForm);

    const { email, password } = formValues;

    const handleClicLogin = () => {
        if (email !== '' && password !== '') {
            if (humanDetected) {
                recaptcha.current.execute();
                doLoginAction(email, password);
                reset();
            } else {
                toastr.warning('Debes seleccionar el checkbox', '¡Ooops!')
            }
        }
        setHumanDetected(false)
        recaptcha.current.reset();
    }

    const handleSeePassword = () => {
        if ($("#floatingPassword").attr("type") === "password") {
            $("#floatingPassword").attr("type", "text");
            $("#icon_eye_1").attr("class", "bi bi-eye-slash");
        } else {
            $("#floatingPassword").attr("type", "password");
            $("#icon_eye_1").attr("class", "bi bi-eye");
        }
    }

    const handleHumanDetected = e => {
        if (e.target.checked) {
            toastr.success("Human detected." + recaptcha.current.getResponse())
            setHumanDetected(true);
        } else {
            setHumanDetected(false);
        }
    }

    return (
        <div className="row">
            <div className='login__container-centered bg-image-one'>
                <div className='col-10 mx-auto rounded' style={{ background: 'transparent' }}>
                    <div className='col-10 mx-auto my-5'>
                        <div className='row'>
                            <div className='col-12 col-md-3 d-flex justify-content-center align-items-center'>
                                <div className='d-flex justify-content-center'>
                                    <img
                                        src="../assets/img/elementos_patrocinios-13.png"
                                        className="img-fluid blurryImg w-50"
                                        alt="personaje guay"
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-5 d-flex align-items-start flex-column'>
                                <div className='my-auto'>
                                    <div className='d-flex justify-content-center'>
                                        <img
                                            src="../assets/img/elementos_patrocinios-33.png"
                                            className="img-fluid px-4 my-4 blurryImg w-50"
                                            alt="logo guay"
                                        />
                                    </div>
                                    <p className='text-center text-gray'>
                                        Lo que no se define no se puede medir,
                                        lo que no se mide no se puede mejorar,
                                        lo que no se mejora se degrada siempre.
                                        <br />
                                        <br />
                                        <b>-William Thomson-</b>
                                    </p>
                                </div>
                                <div className='w-100 text-center'>
                                    <b className='text-yellow'>
                                        Siguenos
                                        <a href="https://www.facebook.com/guay-107436667630740" target="_blank" rel="noopener noreferrer"><i className="text-green bi bi-facebook mx-2" /></a>
                                        <a href="https://www.linkedin.com/company/mesientoguay/" target="_blank" rel="noopener noreferrer"><i className="text-cia bi-linkedin mx-2" /></a>
                                        <a href="https://instagram.com/guay.digital?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram mx-2 text-yellow" /></a>
                                    </b>
                                </div>
                            </div>
                            <div className='col-12 col-md-4 text-center rounded-3 bg-blue-light mt-5 mt-md-0 d-flex flex-wrap align-content-center'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <h3 className='fw-bold text-gray my-4'>Inicia sesión</h3>
                                    </div>
                                    <div className='col-12'>
                                        <div className="mb-3">
                                            <input type="email" name="email" value={email} onChange={handleInputChange} className="form-control" placeholder="correo" />
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="input-group mb-3">
                                            <input type="password" name="password" value={password} onChange={handleInputChange} className="form-control" placeholder="contraseña" id="floatingPassword" />
                                            <span className="input-group-text" onClick={handleSeePassword}><i className="bi bi-eye" id="icon_eye_1"></i></span>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <p className='mb-3'><Link to="/sponsor-access/email-password-recovery" className="login__text-decoration-none text-pink">
                                            ¿No recuerdas tu contraseña?
                                        </Link></p>
                                    </div>
                                    <div className='col-12'>
                                        <div className="form-check d-flex justify-content-center mb-3">
                                            <Recaptcha
                                                ref={recaptcha}
                                                sitekey="6Lf2KjIbAAAAAK-K2Cwtz7UkGf_ENb5B0dmrHFlx"
                                                onResolved={() => console.log("Human detected." + recaptcha.current.getResponse())}
                                            />
                                            <input
                                                className="form-check-input me-3"
                                                type="checkbox"
                                                onClick={handleHumanDetected}
                                                checked={humanDetected}
                                            />
                                            <label className="form-check-label">
                                                No soy un robot
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <button className='btn bg-blue-navy text-white rounded px-5 mb-3' onClick={handleClicLogin}>Ingresar</button>
                                    </div>
                                    <div className='col-12'>
                                        <p className='mb-3'>¿No tienes una cuenta? <u className='text-pink pointer' onClick={() => toastr.info("Espéralo próximamente")}>Ingresa aquí</u></p>
                                    </div>
                                </div>
                                {/* <div className="d-grid gap-3">
                                    <button className='btn bg-white text-gray d-flex justify-content-between'
                                        onClick={() => toastr.info("Espéralo próximamente")}>
                                        <img
                                            src="../assets/img/elementos_patrocinios-14.png"
                                            className="login__logo-icons"
                                            alt="logo facebook"
                                        />
                                        <span className='flex-grow-1'>Entra con Facebook</span>
                                    </button>
                                    <button className='btn bg-white text-gray d-flex justify-content-between'
                                        onClick={() => toastr.info("Espéralo próximamente")}>
                                        <img
                                            src="../assets/img/elementos_patrocinios-15.png"
                                            className="login__logo-icons"
                                            alt="logo gmail"
                                        />
                                        <span className='flex-grow-1'>Entra con Gmail</span>
                                    </button>
                                    <button className='btn bg-white text-gray d-flex justify-content-between'
                                        onClick={() => toastr.info("Espéralo próximamente")}>
                                        <img
                                            src="../assets/img/elementos_patrocinios-16.png"
                                            className="login__logo-icons"
                                            alt="logo apple"
                                        />
                                        <span className='flex-grow-1'>Entra con Apple</span>
                                    </button>
                                    <div className='d-flex justify-content-around'>
                                        <hr className='w-25' />
                                        o
                                        <hr className='w-25' />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ user }) => {
    return {
        user
    }
}

export default connect(mapStateToProps, { doLoginAction })(LoginScreen)