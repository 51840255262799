import { isEmptyObject } from 'jquery';
import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";
import { colorsArray2 } from "../../../helpers/helpers";

const QuestionTypeOpen = ({ question }) => {

    const [graphData, setGraphData] = useState({
        series: [{
            name: "",
            data: [0]
        }],
        options: {
            chart: {
                type: 'bar',
                stacked: true,
                toolbar: {
                    show: false
                },
            },
            title: {
                text: `Total de respuestas respondidas: ${question.quizAnswersList[0].numAnsw}`
            },
            colors: colorsArray2,
            plotOptions: {
                bar: {
                    barHeight: '45%',
                    horizontal: true,
                    borderRadius: 20,
                }
            },
            dataLabels: {
                enabled: true,
                formatter: (val) => val + "%",
                style: {
                    colors: ['#15253c'],
                    fontSize: '16px',
                },
            },
            legend: {
                show: true,
                position: 'top',
                horizontalAlign: 'center',
                markers: {
                    width: 12,
                    height: 12,
                    radius: 12,
                },
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'round',
                colors: colorsArray2,
                width: 2,
                dashArray: 0,
            },
            xaxis: {
                categories: ["Encuestas"],
                labels: {
                    show: false
                },
                axisBorder: {
                    show: true,
                    color: '#000',
                    height: 1,
                    width: 10,
                },
                axisTicks: {
                    show: true,
                },
            },
            yaxis: {
                labels: {
                    show: false
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            tooltip: {
                theme: 'dark',
                x: {
                    show: true
                },
                y: {
                    show: false,
                    formatter: (value) => value + "%",
                    title: {
                        formatter: (value) => value
                    }
                },
                marker: {
                    show: true,
                },
            },
        }
    });

    useEffect(() => {
        if (!isEmptyObject(question) && question.quizAnswersList.length > 0) {
            setGraphData(oldData => ({
                ...oldData,
                series: question.quizAnswersList.map(answer => ({
                    name: answer.descriptionAnswer,
                    data: [answer.percentAnsw]
                }))
            }))
        }

    }, [question])

    return (
        <div className='col-12 col-md-6 p-3 mb-3 mb-md-0'>
            <p className='fs-6 text-gray fw-bold'>{question.description}</p>
            <Chart
                options={graphData.options}
                series={graphData.series}
                type="bar"
                height={"100%"}
            />
        </div>
    )
}

export default QuestionTypeOpen