import React, { useState, useEffect } from 'react';
import { getSponsorProfile } from '../../models/sponsor_model';
import { connect } from 'react-redux';
import { doSetProfileAction } from '../../redux/profileDuck';

const Header = ({ user, profile, doSetProfileAction }) => {
    const { userId } = user;
    const [sponsorList, setSponsorList] = useState([]);
    useEffect(() => {
        getSponsorProfile(userId).then(response => {
            if (response) {
                if (response.code === 1) {
                    setSponsorList(response.listProfile);
                }
            }
        });
    }, [userId]);
    return (
        <div className='col-12 bg-blue-guay sticky-top header__box-shadow'>
            <div className='row'>
                <div className='col-6 px-2 py-1'>
                    <img
                        src='../assets/img/elementos_patrocinios-31.png'
                        alt='logo guay'
                        className='img-fluid blurryImg'
                        style={{
                            height: '30px'
                        }}
                    />
                </div>
                <div className='col-6 px-2 py-1'>
                    <div className="dropdown dropstart d-flex justify-content-end">
                        <button
                            className="dropdown-nav"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            {profile.initials}
                        </button>
                        <div
                            className="dropdown-menu dropdown-menu-nav shadow"
                            aria-labelledby="dropdownMenuButton1"
                        >
                            <div className='col-12 mb-3'>
                                <div className='row'>
                                    <div className='col-12 mb-1 d-flex justify-content-center'>
                                        <span className='span-circle bg-blue-navy text-white d-flex justify-content-center flex-wrap align-content-center'>{profile.initials}</span>
                                    </div>
                                    <div className='col-12 d-flex justify-content-center'>
                                        <span>{profile.organization}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='dropdown-item-div container-vh-50'>
                                <div className='row'>
                                    {
                                        sponsorList.map(data => (
                                            data.idSponsorProfile !== profile.idSponsorProfile ? (
                                                <div className='col-12 pointer header__header-option rounded-pill rounded-end' key={data.idSponsorProfile} onClick={() => {
                                                    const profile = sponsorList.find(profile => profile.idSponsorProfile === parseInt(data.idSponsorProfile));
                                                    doSetProfileAction(profile);
                                                    window.location.reload();
                                                }}>
                                                    <div className='row'>
                                                        <div className='col-2 py-1'>
                                                            <span className='span-circle bg-pink-guay text-white d-flex justify-content-center flex-wrap align-content-center'>{data.initials}</span>
                                                        </div>
                                                        <div className='col-10 py-1 d-flex flex-wrap align-content-center'>
                                                            <span>{data.organization}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (null)
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ user, profile }) => {
    return {
        user,
        profile,
    }
}

export default connect(mapStateToProps, { doSetProfileAction })(Header);