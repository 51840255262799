import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { colorsArray2, convertDateToString } from "../../helpers/helpers";
import { getFileMarketStudy } from '../../models/sponsorReports_model';
import MarketStudyDetail from './MarketStudyDetail';
import * as toastr from 'toastr';
import { TailSpin } from 'react-loader-spinner'

const MarketStudies = ({ marketStudies, idSponsorProfile }) => {

    const [show, setShow] = useState(false);
    const [sliderInner, setSliderInner] = useState([]);
    const [sliderData, setSliderData] = useState([]);
    const [sliderPage, setSliderPage] = useState(0);
    const [studieSpecific, setStudieSpecific] = useState({
        idQuiz: 0,
        title: ""
    });
    const [downloadIcon, setDownloadIcon] = useState(0);

    useEffect(() => {

        if (marketStudies.length > 0) {
            const arrayFill = marketStudies.map(studie => ({
                ...studie,
                series: [{
                    name: 'Encuestas completadas',
                    data: [studie.finished]
                }, {
                    name: 'Colaboradores impactados',
                    data: [studie.views]
                }],
            }));
            let array = [];
            let array_2 = [];
            let arrayAux = [];
            let arrayCompare = [];
            for (let x in marketStudies) {
                if (array.length < 3) {
                    if (array_2.length > 0) {
                        array.push(array_2[0], {
                            ...marketStudies[x],
                            series: [{
                                name: 'Encuestas completadas',
                                data: [marketStudies[x].finished]
                            }, {
                                name: 'Colaboradores impactados',
                                data: [marketStudies[x].views]
                            }],
                        });
                        array_2 = [];
                    } else {
                        array.push({
                            ...marketStudies[x],
                            series: [{
                                name: 'Encuestas completadas',
                                data: [marketStudies[x].finished]
                            }, {
                                name: 'Colaboradores impactados',
                                data: [marketStudies[x].views]
                            }],
                        });
                        array_2 = [];
                    }
                } else {
                    arrayAux.push(array);
                    array_2.push({
                        ...marketStudies[x],
                        series: [{
                            name: 'Encuestas completadas',
                            data: [marketStudies[x].finished]
                        }, {
                            name: 'Colaboradores impactados',
                            data: [marketStudies[x].views]
                        }],
                    });
                    array = [];
                }
            }
            for (let y in arrayAux) {
                for (let x in arrayAux[y]) {
                    arrayCompare.push(arrayAux[y][x]);
                }
            }
            arrayAux.push(arrayFill.filter(elemento => {
                if (arrayCompare.find(element => element.idQuiz === elemento.idQuiz)) {
                    return null;
                } else {
                    return elemento;
                }
            }));
            setSliderData(arrayAux);
            setSliderInner(arrayAux[0]);
        } else {
            setSliderInner([]);
        }

        // eslint-disable-next-line
    }, [marketStudies])

    const sliderFunction = (indicator) => {
        let page;
        let arrayNum = sliderData.length - 1;
        if (indicator === 1) {
            page = sliderPage + 1;
            if (page <= arrayNum) {
                setSliderInner(sliderData[page]);
                setSliderPage(page);
            }
        } else {
            page = sliderPage - 1;
            if (page >= 0) {
                setSliderInner(sliderData[page]);
                setSliderPage(page);
            }
        }
    }

    const checkStatus = function (date) {
        if (convertDateToString(new Date()) > date) {
            return (
                <>
                    <span className='text-cian'><i className="bi bi-check2-circle"></i> <b>Terminada</b></span>
                    <br />
                    <span>estatus</span>
                </>
            )
        } else {
            return (
                <>
                    <span className='text-green'><i className="bi bi-circle-fill text-green"></i> <b>Activo</b></span>
                    <br />
                    <span>estatus</span>
                </>
            )
        }
    }

    const graphOptions = {
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
                show: false
            }
        },
        colors: colorsArray2,
        dataLabels: {
            enabled: true,
            formatter: (val) => val + "%",
            style: {
                colors: ['#15253c']
            },
        },
        plotOptions: {
            bar: {
                barHeight: '45%',
                horizontal: true,
                borderRadius: 15,
            }
        },
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'center',
            markers: {
                width: 12,
                height: 12,
                radius: 12,
            },
        },
        stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'round',
            colors: colorsArray2,
            width: 2,
            dashArray: 0,
        },
        xaxis: {
            categories: ["Encuestas"],
            labels: {
                show: false
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: true,
            }
        },
        yaxis: {
            labels: {
                show: false
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: true,
            }
        },
        tooltip: {
            theme: 'dark',
            x: {
                show: true
            },
            y: {
                show: false,
                formatter: (value) => value + "%",
                title: {
                    formatter: (value) => value
                }
            },
            marker: {
                show: true,
            },
        },
    };

    const handleClickDownload = function (studie) {
        setDownloadIcon(studie.idQuiz);
        toastr.info("Estamos preparando tu archivo...");
        getFileMarketStudy(idSponsorProfile, studie.idQuiz).then((response) => {
            switch (response?.code) {
                case 1:
                    window.open(response.urlFile);
                    setDownloadIcon(0);
                    break;

                case 1001:
                    toastr.info("El perfil actual no se encuentra activo");
                    setDownloadIcon(0);
                    break;

                    case 1002:
                    toastr.info("El estudio de mercado no se encuentra o esta desactivada");
                    setDownloadIcon(0);
                    break;

                    case 1003:
                    toastr.info("El estudio de mercado no se ha contestado");
                    setDownloadIcon(0);
                    break;

                default:
                    toastr.error("Ocurrió un error, contacte a su administrador.", "Error");
                    setDownloadIcon(0);
                    break;
            }
        })
    }

    return (
        <div className='card border-0'>
            <div className='card-body'>
                <div className="d-flex justify-content-between">
                    <div className='p-0 m-0 reports__bringFront align-self-center'>
                        <button
                            className='btn btn-sm btn-secondary rounded-circle m-0'
                            onClick={() => sliderFunction(0)}
                            type='button'
                        >
                            <i className="bi bi-chevron-left"></i>
                        </button>
                    </div>
                    <div className='row'>
                        {
                            sliderInner.map(studie => (
                                <div className="col-12 col-md-5 col-lg-3 flex-grow-1 reports__cards-style p-3 mx-1 mb-3 mb-md-0" key={studie.idQuiz}>
                                    <div className='d-flex justify-content-between'>
                                        <div className='text-gray'>
                                            <span>Nombre: <b className='fs-7'>{studie.title}</b></span>
                                            <br />
                                            <span>Periodo: <b className='fs-7'>{studie.initialDate + " al \n " + studie.finalDate}</b></span>
                                        </div>
                                        <div className='text-gray text-center'>
                                            {checkStatus(studie.finalDate)}
                                        </div>
                                    </div>
                                    <hr className='reports__horizontalRule text-white' />
                                    <div className='d-flex justify-content-between mb-3'>
                                        <div className='text-gray'>
                                            <span>Total de reactivos: <b>{studie.numQuestions}</b></span>
                                        </div>
                                        {studie.total > 0 &&
                                            <div className='text-gray text-center'>
                                                <u
                                                    className='pointer'
                                                    onClick={() => {
                                                        setShow(oldValue => !oldValue);
                                                        if (show) {
                                                            setStudieSpecific(null);
                                                        } else {
                                                            setStudieSpecific({idQuiz: studie.idQuiz, title: studie.title });
                                                        }
                                                        setTimeout(() => {
                                                            window.scrollTo({
                                                                top: document.body.scrollHeight,
                                                                behavior: "smooth"
                                                            });
                                                        }, 500);
                                                    }}
                                                >
                                                    {studieSpecific.idQuiz === studie.idQuiz ? "Cerrar detalle" : "Ver detalle"}
                                                </u>
                                            </div>
                                        }
                                    </div>
                                    <div className='mb-3 mb-md-0'>
                                        <Chart
                                            options={graphOptions}
                                            series={studie.series}
                                            type="bar"
                                            height={"100%"}
                                        />
                                    </div>
                                    <div className='text-center'>
                                        {downloadIcon === studie.idQuiz ?
                                            <div className='btn btn-sm reports__btn-pink text-white'>
                                                <div className='d-flex'>
                                                    <span className='me-2'>Descargando archivos</span>
                                                    <TailSpin
                                                        color="#fff"
                                                        height="20px"
                                                        width="auto"
                                                    />
                                                </div>
                                            </div>
                                            :
                                            <button
                                                className='btn btn-sm reports__btn-pink text-white'
                                                type='button'
                                                onClick={() => handleClickDownload(studie)}
                                            >
                                                <div className='d-flex'>
                                                    <span className='me-2'>Descarga los datos</span>
                                                    <i className="bi bi-download"></i>
                                                </div>
                                            </button>
                                        }
                                    </div>
                                </div>
                            )
                            )
                        }
                    </div>
                    <div className='p-0 m-0 reports__bringFront align-self-center'>
                        <button
                            className='btn btn-sm btn-secondary rounded-circle m-0'
                            onClick={() => sliderFunction(1)}
                            type='button'
                        >
                            <i className="bi bi-chevron-right"></i>
                        </button>
                    </div>
                </div>
                {show && <MarketStudyDetail idSponsorProfile={idSponsorProfile} idQuiz={studieSpecific.idQuiz} title={studieSpecific.title} />}
            </div>
        </div>
    )
}

export default MarketStudies