import React, { Fragment, useState } from 'react';
// API call
import { setBrandPresence } from '../../../models/sponsor_model';
// Tools
import $ from 'jquery';
import * as toastr from 'toastr';

const Administration = ({
    adminForm,
    setAdminForm,
    resetForm,
    previewImage,
    setPreviewImage,
    handleHelper,
}) => {
    const [administrationFile, setAdministrationFile] = useState({});
    const [showSubmit, setShowSubmit] = useState(true);
    const {
        id,
        brand,
        type,
        adminName,
        url,
        resource,
    } = adminForm;
    const onClickAdvInp = () => {
        $("#adminInput").click();
    }
    const saveAdvertising = () => {
        if (adminName === '') {
            $('#adminName').addClass('border-danger');
            toastr.warning("Nombre es un campo obligatorio.", "¡Ooops!");
            return;
        }
        if (brand === 0) {
            if (resource.length === 0) {
                if (administrationFile.length === 0) {
                    toastr.warning("Debes subir algún archivo.", "¡Ooops!");
                    return;
                }
            }
        }
        let formData = new FormData();
        let data = {
            idBrandPresence: brand,
            idSponsorProfile: id,
            typePresence: type,
            title: adminName,
            customUrl1: url,
            customUrl2: '',
            initDate: '1990/01/01',
            finalDate: '2030/12/31',
            idPromotionCategory: 0,
            idPromotionType: 0,
        };
        setShowSubmit(false);
        formData.append("request", JSON.stringify(data));
        formData.append("file", administrationFile);
        setBrandPresence(formData).then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                        toastr.success("Se guardó correctamente.", "¡Éxito!");
                        setShowSubmit(true);
                        resetForm();
                        break;
                    case 1001:
                        toastr.error("El archivo es necesario.", "¡Ooops!");
                        setShowSubmit(true);
                        break;
                    default:
                        toastr.error("Error interno. Comunicarse con el equipo de soporte. " + response.message, "¡Ooops!");
                        setShowSubmit(true);
                        break;
                }
            } else {
                toastr.error("Problemas con el servidor. Intenta nuevamente más tarde.", "¡Ooops!");
                setShowSubmit(true);
            }
        });
    }
    return (
        <Fragment>
            <div className="col-12 mb-5 fs-7">
                <div className="col-12 bg-white_translucid-verylow shadow p-3 rounded">
                    <div className='row'>
                        <div className="col-12 d-flex flex-wrap align-content-start text-cian">
                            <h5 className='fw-bold'>Administradores </h5><i className='bi bi-exclamation-circle-fill text-yellow fs-7 ms-1 pointer' onClick={() => handleHelper(3)} />
                        </div>
                        <div className='col-sm-12 col-md-5 col-lg-5 mb-3'>
                            <div className='row'>
                                <div className='col-12 mb-3'>
                                    <div className='col-sm-12 col-md-6 col-lg-6 mx-auto'>
                                        {
                                            previewImage.image_3 === '' ? (
                                                <img
                                                    className='img-fluid pointer'
                                                    src='../assets/img/elementos_patrocinios-36.png'
                                                    alt='layout'
                                                    onClick={() => onClickAdvInp()}
                                                />
                                            ) : (
                                                <div
                                                    className="card d-flex flex-wrap align-content-end border-0 box-shadow-2"
                                                    style={{
                                                        borderBottomLeftRadius: '15px',
                                                        borderBottomRightRadius: '15px',
                                                    }}
                                                >
                                                    <img
                                                        className='img-fluid pointer card-img-top'
                                                        style={{
                                                            borderBottomLeftRadius: '15px',
                                                            borderBottomRightRadius: '15px',
                                                            height: '350px',
                                                        }}
                                                        src={previewImage.image_3}
                                                        alt='layout'
                                                        onClick={() => onClickAdvInp()}
                                                    />
                                                    <div className="card-img-overlay p-0 d-flex flex-wrap align-content-end">
                                                        <img
                                                            className='img-fluid pointer'
                                                            style={{ opacity: '0.9' }}
                                                            src='../assets/img/elementos_patrocinios-28.png'
                                                            alt='layout'
                                                            onClick={() => onClickAdvInp()}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <input
                                        type="file"
                                        id="adminInput"
                                        className="visually-hidden"
                                        onChange={e => {
                                            if (e.target.files.length > 0) {
                                                let file = e.target.files[0];
                                                let ext = file.name.split('.').pop();
                                                ext = ext.toLowerCase();
                                                switch (ext) {
                                                    case 'jpg':
                                                    case 'jpeg':
                                                    case 'gif':
                                                    case 'png':
                                                        if (file.size < 1000000) {
                                                            let u = URL.createObjectURL(e.target.files[0]);
                                                            let img = new Image();
                                                            img.onload = function () {
                                                                /* if ((img.width === 230 && img.height === 370)) { */
                                                                if ((img.width === 300 && img.height === 600)) {
                                                                    setAdministrationFile(file);
                                                                    setAdminForm({
                                                                        ...adminForm,
                                                                        type: 6,
                                                                    });
                                                                    setPreviewImage({
                                                                        ...previewImage,
                                                                        image_3: u,
                                                                    });
                                                                } else {
                                                                    toastr.warning("Las dimensiones de la imagen deben ser 300x600 y las dimensiones de tu imagen son " + img.width + "x" + img.height + ".", "¡Ooops!");
                                                                }
                                                            };
                                                            img.src = u;
                                                        } else {
                                                            toastr.warning("El peso de la imagen debe ser menor a 1 MB.", "¡Ooops!");
                                                        }
                                                        break;
                                                    default:
                                                        toastr.warning("El formato debe ser jpe, jpeg o png.", "¡Ooops!");
                                                        break;
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                <div className='col-12 d-flex justify-content-center'>
                                    <span className='text-base'>Permite JPG, PNG o GIF. Peso máximo 500 KB</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-7 col-lg-7 d-flex flex-wrap align-content-center mb-3'>
                            <div className='row'>
                                <div className='col-12 mb-3'>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-3 col-lg-3 d-flex flex-wrap align-content-center'>
                                            <span
                                                className='text-base'
                                                htmlFor='url'
                                            >Liga de Imagen</span>
                                        </div>
                                        <div className='col-sm-12 col-md-9 col-lg-9'>
                                            <input
                                                type="text"
                                                className="form-control form-control-gray"
                                                id="url"
                                                name="url"
                                                value={url}
                                                onChange={e => {
                                                    setAdminForm({
                                                        ...adminForm,
                                                        [e.target.name]: e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 mb-3'>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-3 col-lg-3 d-flex flex-wrap align-content-center'>
                                            <span
                                                className='text-base'
                                                htmlFor='loaderName'
                                            >Texto alternativo</span>
                                        </div>
                                        <div className='col-sm-12 col-md-9 col-lg-9'>
                                            <input
                                                type="text"
                                                className="form-control form-control-gray"
                                                id="adminName"
                                                name="adminName"
                                                value={adminName}
                                                onChange={e => {
                                                    $('#adminName').removeClass('border-danger');
                                                    $('#adminStartDate').removeClass('border-danger');
                                                    $('#adminEndDate').removeClass('border-danger');
                                                    setAdminForm({
                                                        ...adminForm,
                                                        [e.target.name]: e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 mb-3'>
                            <div className='row'>
                                <div className='col-12 d-flex justify-content-center'>
                                    <button
                                        className='btn btn-sm bg-blue-navy text-white'
                                        onClick={() => saveAdvertising()}
                                        disabled={!showSubmit}
                                    >
                                        {
                                            showSubmit ? (
                                                <span>Guardar</span>
                                            ) : (
                                                <span>
                                                    <span className="spinner-grow spinner-grow-sm" /> Cargando
                                                </span>
                                            )
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Administration;
