import axios from 'axios';

/**
 * Metodo para recuperar informacion del sponsor utilizando params
 * @returns JSON response
 */
export const getSponsorProfile = async (userId = "") => {
    try {
        let result = await axios({
            method: 'GET',
            url: global.base_url + `sponsor/getSponsorProfile?idUser=${userId}`,
            headers: {
                Authorization: global.tokenAuth
            }
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};

export const setUpdateProfile = async (idUser, idSponsorProfile = 0, organization, sector, subSector, webPage, facebook, instagram, twitter, linkedin, description, active, fileInput, legalName, mission, vision, slogan) => {
    const formData = new FormData();

    formData.append("request", `{
        idUser: ${idUser}, 
        idSponsorProfile: ${idSponsorProfile}, 
        organization: "${organization}", 
        sector: "${sector}", 
        subSector: "${subSector}", 
        webPage: "${webPage}",
        facebook: "${facebook}",
        instagram: "${instagram}",
        twitter: "${twitter}",
        linkedin: "${linkedin}",
        description: "${description}",
        active: ${active},
        legalName: "${legalName}",
        mission: "${mission}",
        vision: "${vision}",
        slogan: "${slogan}",
    }`);

    formData.append("file", fileInput);

    try {
        let result = await axios({
            method: 'POST',
            url: global.base_url + "sponsor/setUpdateProfile",
            headers: {
                Authorization: global.tokenAuth
            },
            data: formData,
        })
        return (result.data ? result.data : false);
    } catch (error) {
        console.log(error);
    }
};
/**
 * 
 * @param {*} idSponsorProfile 
 * @returns 
 */
export const getBrandPresence = async (idSponsorProfile) => {
    try {
        let result = await axios({
            url: global.base_url + "sponsor/getBrandPresence?idSponsorProfile=" + idSponsorProfile + "",
            method: 'GET',
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            }
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
/**
 * 
 * @param {*} formData 
 * @returns 
 */
export const setBrandPresence = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "sponsor/setBrandPresence",
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            },
            data: formData
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}


/**
 * 
 * @param {*} formData 
 * @returns 
 */
export const deleteBrandPresence = async (formData) => {
    try {
        let result = await axios({
            url: global.base_url + "sponsor/deleteBrandPresence",
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data;',
                Authorization: global.tokenAuth
            },
            data: formData
        });
        if (result.data) {
            return result.data;
        }
    } catch (error) {
        console.log(error);
    }
}
