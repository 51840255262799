import React, { useState, Fragment } from 'react';
// API call
import { setBrandPresence } from '../../../models/sponsor_model';
import ModalViewer from './ModalViewer';
// Tools
import $ from 'jquery';
import * as toastr from 'toastr';
import PropTypes from 'prop-types';
import Datepicker, { registerLocale } from 'react-datepicker';
import { getDay, addDays, subDays } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

const Advertising = ({
    advertisingForm,
    advertisingData,
    viewerData,
    dateOfRenovation,
    setViewerData,
    setAdvertisingForm,
    resetForm,
    deleteFile,
    promoCat,
    promoType,
    handleHelper,
}) => {
    const [advertisingImage, setAdvertisingImage] = useState({});
    const [showSubmit, setShowSubmit] = useState(true);
    registerLocale('es', es);
    const {
        id,
        brand,
        type,
        advertisingName,
        urlProm,
        urlWeb,
        advertisingStartDate,
        advertisingEndDate,
        resource,
        idPromotionCategory,
        idPromotionType,
    } = advertisingForm;
    const onClickAdvInp = () => {
        $("#advertisingInput").click();
    }
    const saveAdvertising = () => {
        if (advertisingName === '') {
            $('#advertisingName').addClass('border-danger');
            toastr.warning("Texto alternativo es un campo obligatorio.", "¡Ooops!");
            return;
        }
        if (advertisingStartDate === '') {
            $('#advertisingStartDate').addClass('border-danger');
            toastr.warning("Fecha de inicio es un campo obligatorio.", "¡Ooops!");
            return;
        }
        if (advertisingEndDate === '') {
            $('#advertisingEndDate').addClass('border-danger');
            toastr.warning("Fecha de fin es un campo obligatorio.", "¡Ooops!");
            return;
        }
        if (idPromotionCategory === '') {
            $('#idPromotionCategory').addClass('border-danger');
            toastr.warning("Categoría es un campo obligatorio.", "¡Ooops!");
            return;
        }
        if (idPromotionType === '') {
            $('#idPromotionType').addClass('border-danger');
            toastr.warning("Tipo Promoción es un campo obligatorio.", "¡Ooops!");
            return;
        }
        if (brand === 0) {
            if (resource.length === 0) {
                if (advertisingImage.length === 0) {
                    toastr.warning("Debes subir algún archivo.", "¡Ooops!");
                    return;
                }
            }
        }
        let formData = new FormData();
        let data = {
            idBrandPresence: brand,
            idSponsorProfile: id,
            typePresence: type,
            title: advertisingName,
            customUrl1: urlProm,
            customUrl2: urlWeb,
            initDate: advertisingStartDate,
            finalDate: advertisingEndDate,
            idPromotionCategory: idPromotionCategory,
            idPromotionType: idPromotionType,
        }
        setShowSubmit(false);
        formData.append("request", JSON.stringify(data));
        formData.append("file", advertisingImage);
        setBrandPresence(formData).then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                        toastr.success("Se guardó correctamente.", "¡Éxito!");
                        setShowSubmit(true);
                        resetForm();
                        cleanForm();
                        break;
                    case 1001:
                        toastr.error("El archivo es necesario.", "¡Ooops!");
                        setShowSubmit(true);
                        break;
                    default:
                        toastr.error("Error interno. Comunicarse con el equipo de soporte. " + response.message, "¡Ooops!");
                        setShowSubmit(true);
                        break;
                }
            } else {
                toastr.error("Problemas con el servidor. Intenta nuevamente más tarde.", "¡Ooops!");
                setShowSubmit(true);
            }
        });
    }
    const isWeekday = (date) => {
        const day = getDay(date);
        return day !== 0 && day !== 6;
    };
    const today = new Date();
    const cleanForm = () => {
        setAdvertisingForm({
            id: id,
            brand: 0,
            type: 0,
            advertisingName: '',
            urlProm: '',
            urlWeb: '',
            advertisingStartDate: '',
            advertisingEndDate: '',
            resource: '',
            idPromotionCategory: 0,
            idPromotionType: 0,
        });
        setAdvertisingImage({});
        $("#advertisingInput").val('');
        $("#advertisingForm").hide();
    }
    return (
        <Fragment>
            <div className="col-12 mb-5 fs-7">
                <div className="col-12 bg-white_translucid-verylow shadow p-3 rounded presence__show-container container-3">
                    <div className='row'>
                        <div className="col-12 d-flex flex-wrap align-content-start text-yellow">
                            <h5 className='fw-bold'>Promociones </h5><i className='bi bi-exclamation-circle-fill fs-7 ms-1 pointer' onClick={() => handleHelper(4)} />
                        </div>
                        <div className='col-sm-12 col-md-5 col-lg-5 mb-3'>
                            <div className='row'>
                                <div className='col-12 d-flex justify-content-center mb-3'>
                                    <img
                                        className='w-100 pointer'
                                        src='../assets/img/elementos_patrocinios-27.png'
                                        alt='layout'
                                        onClick={() => onClickAdvInp()}
                                    />
                                    <input
                                        type="file"
                                        id="advertisingInput"
                                        className="visually-hidden"
                                        onChange={e => {
                                            if (e.target.files.length > 0) {
                                                let file = e.target.files[0];
                                                let ext = file.name.split('.').pop();
                                                ext = ext.toLowerCase();
                                                switch (ext) {
                                                    case 'jpg':
                                                    case 'jpeg':
                                                    case 'gif':
                                                    case 'png':
                                                        if (file.size < 1000000) {
                                                            /* setAdvertisingImage(file);
                                                            setAdvertisingForm({
                                                                ...advertisingForm,
                                                                type: 4,
                                                            }); */
                                                            let u = URL.createObjectURL(e.target.files[0]);
                                                            let img = new Image();
                                                            img.onload = function () {
                                                                if ((img.width === 620 && img.height === 250)) {
                                                                    setAdvertisingImage(file);
                                                                    setAdvertisingForm({
                                                                        ...advertisingForm,
                                                                        type: 4,
                                                                    });
                                                                } else {
                                                                    toastr.warning("Las dimensiones de la imagen deben ser 620x250 y las dimensiones de tu imagen son " + img.width + "x" + img.height + ".", "¡Ooops!");
                                                                }
                                                            };
                                                            img.src = u;
                                                        } else {
                                                            toastr.warning("El peso de la imagen debe ser menor a 1 MB.", "¡Ooops!");
                                                        }
                                                        break;
                                                    default:
                                                        toastr.warning("El formato debe ser jpe, jpeg o png.", "¡Ooops!");
                                                        break;
                                                }
                                            }
                                        }}
                                    />
                                </div>
                                <div className='col-12 d-flex justify-content-center'>
                                    <span className='text-base'>Permite JPG, PNG o GIF. Peso máximo 500 KB</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-7 col-lg-7 d-flex flex-wrap align-content-center mb-3'>
                            <div className='row'>
                                <div className='col-12 mb-3'>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-3 col-lg-3 d-flex flex-wrap align-content-center'>
                                            <span
                                                className='text-base'
                                                htmlFor='urlProm'
                                            >Liga de Imagen</span>
                                        </div>
                                        <div className='col-sm-12 col-md-9 col-lg-9 d-flex flex-wrap align-content-center'>
                                            <input
                                                type="text"
                                                className="form-control form-control-gray"
                                                id="urlProm"
                                                name="urlProm"
                                                value={urlProm}
                                                onChange={e => {
                                                    setAdvertisingForm({
                                                        ...advertisingForm,
                                                        [e.target.name]: e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 mb-3'>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-3 col-lg-3 d-flex flex-wrap align-content-center'>
                                            <span
                                                className='text-base'
                                                htmlFor='advertisingName'
                                            >Texto alternativo</span>
                                        </div>
                                        <div className='col-sm-12 col-md-9 col-lg-9 d-flex flex-wrap align-content-center'>
                                            <input
                                                type="text"
                                                className="form-control form-control-gray"
                                                id="advertisingName"
                                                name="advertisingName"
                                                value={advertisingName}
                                                onChange={e => {
                                                    $('#advertisingName').removeClass('border-danger');
                                                    $('#advertisingStartDate').removeClass('border-danger');
                                                    $('#advertisingEndDate').removeClass('border-danger');
                                                    $('#idPromotionCategory').removeClass('border-danger');
                                                    $('#idPromotionType').removeClass('border-danger');
                                                    setAdvertisingForm({
                                                        ...advertisingForm,
                                                        [e.target.name]: e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 mb-3'>
                            <div className='row'>
                                <div className='col-sm-12 col-md-6 col-lg-6 mb-3'>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-4 col-lg-4 d-flex flex-wrap align-content-center'>
                                            <span
                                                className='text-base'
                                                htmlFor='idPromotionCategory'
                                            >Categoría</span>
                                        </div>
                                        <div className='col-sm-12 col-md-8 col-lg-8 d-flex flex-wrap align-content-center'>
                                            <select
                                                className="form-select form-control-gray"
                                                id="idPromotionCategory"
                                                name="idPromotionCategory"
                                                value={idPromotionCategory}
                                                onChange={e => {
                                                    $('#advertisingName').removeClass('border-danger');
                                                    $('#advertisingStartDate').removeClass('border-danger');
                                                    $('#advertisingEndDate').removeClass('border-danger');
                                                    $('#idPromotionCategory').removeClass('border-danger');
                                                    $('#idPromotionType').removeClass('border-danger');
                                                    setAdvertisingForm({
                                                        ...advertisingForm,
                                                        [e.target.name]: e.target.value
                                                    });
                                                }}
                                            >
                                                <option value=''>Seleccionar</option>
                                                {
                                                    promoCat.map(data => (
                                                        <option
                                                            key={data.idPromotionCategory}
                                                            value={data.idPromotionCategory}
                                                        >{data.description}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6 mb-3'>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-4 col-lg-4 d-flex flex-wrap align-content-center'>
                                            <span
                                                className='text-base'
                                                htmlFor='idPromotionType'
                                            >Tipo Promoción</span>
                                        </div>
                                        <div className='col-sm-12 col-md-8 col-lg-8 d-flex flex-wrap align-content-center'>
                                            <select
                                                className="form-select form-control-gray"
                                                id="idPromotionType"
                                                name="idPromotionType"
                                                value={idPromotionType}
                                                onChange={e => {
                                                    $('#advertisingName').removeClass('border-danger');
                                                    $('#advertisingStartDate').removeClass('border-danger');
                                                    $('#advertisingEndDate').removeClass('border-danger');
                                                    $('#idPromotionCategory').removeClass('border-danger');
                                                    $('#idPromotionType').removeClass('border-danger');
                                                    setAdvertisingForm({
                                                        ...advertisingForm,
                                                        [e.target.name]: e.target.value
                                                    });
                                                }}
                                            >
                                                <option value=''>Seleccionar</option>
                                                {
                                                    promoType.map(data => (
                                                        <option
                                                            key={data.idPromotionType}
                                                            value={data.idPromotionType}
                                                        >{data.description}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6 mb-3'>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-4 col-lg-4 d-flex flex-wrap align-content-center'>
                                            <span
                                                className='text-base'
                                                htmlFor='advertisingStartDate'
                                            >Fecha de inicio</span>
                                        </div>
                                        <div className='col-sm-12 col-md-7 col-lg-7 d-flex flex-wrap align-content-center'>
                                            <Datepicker
                                                dateFormat="dd-MM-yyyy"
                                                className="form-control form-control-gray"
                                                selected={advertisingStartDate}
                                                onChange={date => {
                                                    $('#advertisingName').removeClass('border-danger');
                                                    $('#advertisingStartDate').removeClass('border-danger');
                                                    $('#advertisingEndDate').removeClass('border-danger');
                                                    $('#idPromotionCategory').removeClass('border-danger');
                                                    $('#idPromotionType').removeClass('border-danger');
                                                    setAdvertisingForm({
                                                        ...advertisingForm,
                                                        advertisingStartDate: date
                                                    });
                                                }}
                                                filterDate={isWeekday}
                                                excludeDates={today.getDay() === 4 ? (
                                                    [addDays(new Date(), 1), addDays(new Date(), 4)]
                                                ) : (
                                                    today.getDay() === 5 ? (
                                                        [addDays(new Date(), 3), addDays(new Date(), 4)]
                                                    ) : (
                                                        [addDays(new Date(), 1), addDays(new Date(), 2)]
                                                    )
                                                )}
                                                minDate={today.getDay() === 4 ? (
                                                    addDays(new Date(), 3)
                                                ) : (
                                                    addDays(new Date(), 2)
                                                )}
                                                maxDate={new Date(dateOfRenovation)}
                                                placeholderText="Selecciona una fecha"
                                                locale="es"
                                                autoComplete={false}
                                            />
                                        </div>
                                        <div className='col-sm-12 col-md-1 col-lg-1 p-0 d-flex flex-wrap align-content-center'>
                                            <i className='bi bi-calendar3 fs-4' />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-4 col-lg-4 d-flex flex-wrap align-content-center'>
                                            <span
                                                className='text-base'
                                                htmlFor='advertisingEndDate'
                                            >Fecha de fin</span>
                                        </div>
                                        <div className='col-sm-12 col-md-7 col-lg-7 d-flex flex-wrap align-content-center'>
                                            <Datepicker
                                                dateFormat="dd-MM-yyyy"
                                                className="form-control form-control-gray"
                                                selected={advertisingEndDate}
                                                onChange={date => {
                                                    $('#advertisingName').removeClass('border-danger');
                                                    $('#advertisingStartDate').removeClass('border-danger');
                                                    $('#advertisingEndDate').removeClass('border-danger');
                                                    $('#idPromotionCategory').removeClass('border-danger');
                                                    $('#idPromotionType').removeClass('border-danger');
                                                    setAdvertisingForm({
                                                        ...advertisingForm,
                                                        advertisingEndDate: date
                                                    });
                                                }}
                                                filterDate={isWeekday}
                                                minDate={today.getDay() === 0 ? (
                                                    subDays(new Date(), 3)
                                                ) : (
                                                    subDays(new Date(), 1)
                                                )}
                                                maxDate={new Date(dateOfRenovation)}
                                                placeholderText="Selecciona una fecha"
                                                locale="es"
                                                autoComplete={false}
                                            />
                                        </div>
                                        <div className='col-sm-12 col-md-1 col-lg-1 p-0 d-flex flex-wrap align-content-center'>
                                            <i className='bi bi-calendar3 fs-4' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 d-flex justify-content-center mb-3'>
                            <button
                                className='btn btn-sm bg-blue-navy text-white'
                                onClick={() => {
                                    if (showSubmit) {
                                        if (advertisingData.length === 5) {
                                            toastr.warning('Solo puedes agregar 5 promociones.', '¡Ooops!');
                                        } else {
                                            saveAdvertising();
                                        }
                                    }
                                }}
                                disabled={!showSubmit}
                            >
                                {
                                    showSubmit ? (
                                        <span>Guardar</span>
                                    ) : (
                                        <span>
                                            <span className="spinner-grow spinner-grow-sm" /> Cargando
                                        </span>
                                    )
                                }
                            </button>
                        </div>
                        <div className='col-12 mb-3'>
                            <div className='row'>
                                {
                                    advertisingData.length === 0 ? (
                                        <div className="col-11 mx-auto p-5 d-flex justify-content-center flex-wrap align-content-center">
                                            <h4 className='text-base'>No hay promociones disponibles</h4>
                                        </div>
                                    ) : (
                                        <Fragment>
                                            <div className="col-11 mx-auto p-2">
                                                <div className="row">
                                                    <div className="col-8 d-flex flex-wrap align-content-center">
                                                        <span className='text-base fw-bold'>Promoción</span>
                                                    </div>
                                                    <div className="col-4 d-flex flex-wrap align-content-center"></div>
                                                </div>
                                            </div>
                                            {
                                                advertisingData.map((data, index) => (
                                                    <div
                                                        className={index % 2 === 0 ? ("col-11 mx-auto border bg-gray text-base") : ("col-11 mx-auto text-base")}
                                                        key={'advertising-' + index}
                                                    >
                                                        <div className="row">
                                                            <div className="col-8 d-flex flex-wrap align-content-center">
                                                                <span>{data.title}</span>
                                                            </div>
                                                            <div className="col-4 p-1 d-flex justify-content-around">
                                                                <span><i
                                                                    className="bi bi-eye pointer text-cian"
                                                                    onClick={() => {
                                                                        setViewerData({
                                                                            ...viewerData,
                                                                            url: data.resourceUrl,
                                                                            type: data.typePresence
                                                                        });
                                                                        $("#modalViewer").show();
                                                                    }}
                                                                ></i></span>
                                                                <span><i
                                                                    className="bi bi-pencil text-yellow pointer"
                                                                    onClick={() => {
                                                                        setAdvertisingForm({
                                                                            id: data.idSponsorProfile,
                                                                            brand: data.idBrandPresence,
                                                                            type: data.typePresence,
                                                                            advertisingName: data.title,
                                                                            urlProm: data.customUrl1,
                                                                            urlWeb: data.customUrl2,
                                                                            advertisingStartDate: new Date(data.initialDate),
                                                                            advertisingEndDate: new Date(data.finalDate),
                                                                            resource: data.resourceUrl,
                                                                            idPromotionCategory: data.idPromotionCategory,
                                                                            idPromotionType: data.idPromotionType,
                                                                        });
                                                                    }}
                                                                ></i></span>
                                                                <span><i
                                                                    className="bi bi-trash pointer text-danger"
                                                                    onClick={() => {
                                                                        let formData = {
                                                                            idBrandPresence: data.idBrandPresence
                                                                        }
                                                                        deleteFile(formData);
                                                                        cleanForm();
                                                                    }}
                                                                ></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </Fragment>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalViewer
                data={viewerData}
            />
        </Fragment>
    );
}

Advertising.propTypes = {
    advertisingForm: PropTypes.object.isRequired,
    advertisingData: PropTypes.array.isRequired,
    viewerData: PropTypes.object.isRequired,
    dateOfRenovation: PropTypes.string.isRequired,
    setViewerData: PropTypes.func.isRequired,
    setAdvertisingForm: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
}

export default Advertising;
