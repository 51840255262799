export const soloNumeros = (e) => {
    var key = e.charCode;
    return key >= 48 && key <= 57;
}

// convert Sun Jan 01 2023 00:00:00 GMT-0600 (hora estándar central) to yyyy-mm-dd
export const convertDateToString = (str) => {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
}

// Regresa la lat y lng de cada estado dependiendo del idState
export const getLatLngFromState = function (idState) {
    switch (idState) {
        case 1: // Aguascalientes
            return {
                lat: 21.8760, lng: -102.2960
            }
        case 2: // Baja California
            return {
                lat: 32.6633, lng: -115.4677
            }
        case 3: // Baja California Sur
            return {
                lat: 24.14437, lng: -110.3005
            }
        case 4: // Campeche
            return {
                lat: 19.84386, lng: -90.52554
            }
        case 5: // Coahuila
            return {
                lat: 25.42321, lng: -101.0053
            }
        case 6: // Colima
            return {
                lat: 19.24997, lng: -103.72714
            }
        case 7: // Chiapas
            return {
                lat: 16.75973, lng: -93.11308
            }
        case 8: // Chihuahua
            return {
                lat: 28.63528, lng: -106.08889
            }
        case 9: // Ciudad de Mexico
            return {
                lat: 19.42847, lng: -99.12766
            }
        case 10: // Durango
            return {
                lat: 24.02032, lng: -104.65756
            }
        case 11: // Guanajuato
            return {
                lat: 21.01858, lng: -101.2591
            }
        case 12: // Guerrero
            return {
                lat: 17.5506, lng: -99.50578
            }
        case 13: // Hidalgo
            return {
                lat: 20.11697, lng: -98.73329
            }
        case 14: // Jalisco
            return {
                lat: 20.66682, lng: -103.39182
            }
        case 15: // Estado de Mexico
            return {
                lat: 19.322978, lng: -100.7262987
            }
        case 16: // Michoacan
            return {
                lat: 19.70078, lng: -101.18443
            }
        case 17: // Morelos
            return {
                lat: 18.9261, lng: -99.23075
            }
        case 18: // Nayarit
            return {
                lat: 21.50951, lng: -104.89569
            }
        case 19: // Nuevo Leon
            return {
                lat: 25.67507, lng: -100.31847
            }
        case 20: // Oaxaca
            return {
                lat: 17.06542, lng: -96.72365
            }
        case 21: // Puebla
            return {
                lat: 19.03793, lng: -98.20346
            }
        case 22: // Queretaro
            return {
                lat: 20.58806, lng: -100.38806
            }
        case 23: // Quintana Roo
            return {
                lat: 18.51413, lng: -88.30381
            }
        case 24: // San Luis Potosi
            return {
                lat: 22.1128637, lng: -101.0261095
            }
        case 25: // Sinaloa
            return {
                lat: 24.7885603, lng: -107.377303
            }
        case 26: // Sonora
            return {
                lat: 29.3814573, lng: -113.9821209
            }
        case 27: // Tabasco
            return {
                lat: 17.9514689, lng: -93.1192714,
            }
        case 28: // Tamaulipas
            return {
                lat: 22.36094, lng: -97.89997
            }
        case 29: // Tlaxcala
            return {
                lat: 19.31905, lng: -98.19982
            }
        case 30: // Veracruz
            return {
                lat: 19.18095, lng: -96.1429
            }
        case 31: // Yucatan
            return {
                lat: 20.97537, lng: -89.61696
            }

        case 32: // Zacatecas
            return {
                lat: 22.76843, lng: -102.58141
            }

        default: // Estado no especificado
            return {
            }
    }
}

export const returnMonthBasedOnNumber = function (monthNumber) {
    switch (monthNumber) {
        case 1:
            return "Enero";
        case 2:
            return "Febrero";
        case 3:
            return "Marzo";
        case 4:
            return "Abril";
        case 5:
            return "Mayo";
        case 6:
            return "Junio";
        case 7:
            return "Julio";
        case 8:
            return "Agosto";
        case 9:
            return "Septiembre";
        case 10:
            return "Octubre";
        case 11:
            return "Noviembre";
        case 12:
            return "Diciembre";
        default:
            return undefined;
    }
}

export const colorsArray = ["#9cb537", "#af2f72", "#2188b4", "#dfa443",
    "#7d9645", "#be5b70", "#4e81ad", "#d69356",
    "#3f4c40", "#9a5587", "#6979a4", "#c7a73d",
    "#29514e", "#8d6391", "#7d6f9b", "#15253c"];

export const colorsArray2 = ["#af2f72", "#2188b4","#9cb537", "#dfa443",
    "#be5b70",  "#7d9645", "#4e81ad", "#d69356",
    "#9a5587", "#6979a4", "#3f4c40", "#c7a73d",
    "#8d6391", "#29514e", "#7d6f9b", "#15253c"];
