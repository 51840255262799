import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { doLogOutAction } from '../../redux/authDuck';
import { doCleanProfileLogOut } from '../../redux/profileDuck';
import { changeNavigationSelected, resetNavigationSelected } from '../../redux/navigationDuck';
import $ from 'jquery';

const Menu = ({ user, navigation, doLogOutAction, doCleanProfileLogOut, changeNavigationSelected, resetNavigationSelected }) => {

    const handleClickLogout = () => {
        doLogOutAction();
        doCleanProfileLogOut();
        resetNavigationSelected();
    }
    const handleChangeIcon = id => {
        switch (id) {
            case 2:
                $(`#icon-2`).attr('src', '../assets/img/elementos_patrocinios-23.png');
                $(`#icon-1`).attr('src', '../assets/img/elementos_patrocinios-17.png');
                $(`#icon-3`).attr('src', '../assets/img/elementos_patrocinios-18.png');
                $(`#icon-4`).attr('src', '../assets/img/elementos_patrocinios-20.png');
                break;
            case 3:
                $(`#icon-3`).attr('src', '../assets/img/elementos_patrocinios-22.png');
                $(`#icon-1`).attr('src', '../assets/img/elementos_patrocinios-17.png');
                $(`#icon-2`).attr('src', '../assets/img/elementos_patrocinios-19.png');
                $(`#icon-4`).attr('src', '../assets/img/elementos_patrocinios-20.png');
                break;
            case 4:
                $(`#icon-4`).attr('src', '../assets/img/elementos_patrocinios-24.png');
                $(`#icon-1`).attr('src', '../assets/img/elementos_patrocinios-17.png');
                $(`#icon-2`).attr('src', '../assets/img/elementos_patrocinios-19.png');
                $(`#icon-3`).attr('src', '../assets/img/elementos_patrocinios-18.png');
                break;
            default:
                $(`#icon-1`).attr('src', '../assets/img/elementos_patrocinios-21.png');
                $(`#icon-2`).attr('src', '../assets/img/elementos_patrocinios-19.png');
                $(`#icon-3`).attr('src', '../assets/img/elementos_patrocinios-18.png');
                $(`#icon-4`).attr('src', '../assets/img/elementos_patrocinios-20.png');
                break;
        }
    }
    return (
        <div className="col-12 mt-4">
            <div className="row">
                <div className="col-12 bg-white_translucid mx-auto shadow rounded-3 py-2">
                    <div className="row fs-6 ps-4">
                        {
                            /* Sponsor options */
                            !user.isAdmin && (
                                <>
                                    <Link to="overview" className={navigation.SELECTED === 'inicio' ? "col-12 menu__menu-option-selected text-decoration-none rounded-pill rounded-end" : "col-12 menu__menu-option text-decoration-none rounded-pill rounded-end"} onClick={() => {changeNavigationSelected('inicio'); handleChangeIcon(1)}}>
                                        <span className="show-1"><img src='../assets/img/elementos_patrocinios-17.png' style={{ width: '23px', height: '23px' }} id='icon-1' alt='iconimg' /> Inicio</span>
                                    </Link>
                                    <Link to="configuration" className={navigation.SELECTED === 'configuracion' ? "col-12 menu__menu-option-selected text-decoration-none rounded-pill rounded-end" : "col-12 menu__menu-option text-decoration-none rounded-pill rounded-end"} onClick={() => {changeNavigationSelected('configuracion'); handleChangeIcon(2)}}>
                                        <span className="show-2"><img src='../assets/img/elementos_patrocinios-19.png' style={{ width: '23px', height: '23px' }} id='icon-2' alt='iconimg' /> Configuración</span>
                                    </Link>
                                    <Link to="reports" className={navigation.SELECTED === 'reportes' ? "col-12 menu__menu-option-selected text-decoration-none rounded-pill rounded-end" : "col-12 menu__menu-option text-decoration-none rounded-pill rounded-end"} onClick={() => {changeNavigationSelected('reportes'); handleChangeIcon(3)}}>
                                        <span className="show-3"><img src='../assets/img/elementos_patrocinios-18.png' style={{ width: '23px', height: '23px' }} id='icon-3' alt='iconimg' /> Reportes</span>
                                    </Link>
                                    <Link to="market-studies" className={navigation.SELECTED === 'estudios' ? "col-12 menu__menu-option-selected text-decoration-none rounded-pill rounded-end" : "col-12 menu__menu-option text-decoration-none rounded-pill rounded-end"} onClick={() => {changeNavigationSelected('estudios'); handleChangeIcon(4)}}>
                                        <span className="show-4"><img src='../assets/img/elementos_patrocinios-20.png' style={{ width: '23px', height: '23px' }} id='icon-4' alt='iconimg' /> Estudios de mercado</span>
                                    </Link>
                                </>
                            )
                            /* Sponsor options */
                        }

                        < div
                            className="col-12 menu__menu-option rounded-pill rounded-end"
                            onClick={handleClickLogout}
                        >
                            <span className="show-4" ><i className="bi bi-door-open-fill" /> Salir</span>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

const mapStateToProps = ({ user, navigation }) => {
    return {
        user,
        navigation
    }
}

export default connect(mapStateToProps, { doLogOutAction, doCleanProfileLogOut, changeNavigationSelected, resetNavigationSelected })(Menu);
