import React, { useEffect, useState } from 'react';
import { getListMarketStudyByIdProfile, setDesactiveQuiz } from '../../models/quizSponsor_model';
import { connect } from 'react-redux';
import * as toastr from 'toastr';
import { doSetEditQuizAction } from '../../redux/quizDuck';

const QuizSponsorList = ({ profile, doSetEditQuizAction, setHiddeForm }) => {
    const [sliderInner, setSliderInner] = useState([]);
    const [sliderData, setSliderData] = useState([]);
    const [sliderPage, setSliderPage] = useState(0);
    const [showPagination, setShowPagination] = useState(false);
    useEffect(() => {
        getListMarket(profile.idSponsorProfile);
        return () => {

        };
    }, [profile]);
    const getListMarket = (idSponsorProfile) => {
        getListMarketStudyByIdProfile(idSponsorProfile).then(response => {
            switch (response?.code) {
                case 1:
                    if (response.listQuizSponsor.length > 0) {
                        let array = [];
                        let array_2 = [];
                        let arrayAux = [];
                        let arrayCompare = [];
                        for (let x in response.listQuizSponsor) {
                            if (array.length < 4) {
                                if (array_2.length > 0) {
                                    array.push(array_2[0], response.listQuizSponsor[x]);
                                    array_2 = [];
                                } else {
                                    array.push(response.listQuizSponsor[x]);
                                    array_2 = [];
                                }
                            } else {
                                arrayAux.push(array);
                                array_2.push(response.listQuizSponsor[x]);
                                array = [];
                            }
                        }
                        for (let y in arrayAux) {
                            for (let x in arrayAux[y]) {
                                arrayCompare.push(arrayAux[y][x]);
                            }
                        }
                        arrayAux.push(response.listQuizSponsor.filter(elemento => arrayCompare.indexOf(elemento) === -1));
                        setSliderData(arrayAux);
                        setSliderInner(arrayAux[0]);
                        console.log(response.listQuizSponsor.length)
                        if (response.listQuizSponsor.length > 4) {
                            setShowPagination(true);
                        }
                    } else {
                        setSliderInner([]);
                        setShowPagination(false);
                    }
                    break;
                case 99:
                    toastr.error('Ocurrió un error al traer los cuestionarios.');
                    setSliderInner([]);
                    setShowPagination(false);
                    break;

                case 1001:
                    toastr.warning('El perfil seleccionado no se encuentra activo.');
                    setSliderInner([]);
                    setShowPagination(false);
                    break;

                case 1002:
                    toastr.warning('El perfil seleccionado no cuenta con cuestionarios activos.');
                    setSliderInner([]);
                    setShowPagination(false);
                    break;

                default:
                    toastr.error('verifique su conexión a internet', 'Ocurrió un error desconocido');
                    setSliderInner([]);
                    setShowPagination(false);
                    break;
            }
        })
    }
    const handleClickEdit = (idQuizSponsor) => {
        doSetEditQuizAction(idQuizSponsor);
    }
    const handleClickDelete = (idQuizSponsor) => {
        setDesactiveQuiz(idQuizSponsor).then(response => {
            switch (response?.code) {
                case 1:
                    toastr.info('Encuesta desactivada');
                    getListMarket(profile.idSponsorProfile);
                    break;
                case 99:
                    toastr.error('Ocurrió un error al desactivar el cuestionario seleccionada.');
                    break;
                case 1001:
                    toastr.warning('El cuestionario que esta tratando de desactivar, no existe.');
                    break;
                default:
                    toastr.error('verifique su conexión a internet', 'Ocurrió un error desconocido');
                    break;
            }
        })
    }
    const sliderFunction = (indicator) => {
        let page;
        let arrayNum = sliderData.length - 1;
        if (indicator === 1) {
            page = sliderPage + 1;
            if (page <= arrayNum) {
                setSliderInner(sliderData[page]);
                setSliderPage(page);
            }
        } else {
            page = sliderPage - 1;
            if (page >= 0) {
                setSliderInner(sliderData[page]);
                setSliderPage(page);
            }
        }
    }
    return (
        <div className="row" id="listQuizSponsor">
            <div className='col-12 mb-3'>
                <div className="row">
                    {
                        sliderInner.map(quiz => (
                            <div
                                className="col-sm-12 col-md-3 col-lg-3 mx-auto"
                                key={quiz.idQuizSponsor}
                            >
                                <div className="flip-container">
                                    <div className="flipper">
                                        <div className="front h-100 py-5 px-2" >
                                            <span>{quiz.title}</span>
                                        </div>
                                        <div className="back h-100 py-5 px-2 d-flex justify-content-around" >
                                            <i
                                                className='bi bi-pencil fs-4 option-card'
                                                onClick={() => {
                                                    handleClickEdit(quiz.idQuizSponsor);
                                                    setHiddeForm(true);
                                                }}
                                            />
                                            <i
                                                className='bi bi-trash fs-4 option-card'
                                                onClick={() => handleClickDelete(quiz.idQuizSponsor)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            {
                showPagination && (
                    <div className='col-12 d-flex justify-content-end'>
                        <button
                            className="btn btn-sm btn-outline-secondary rounded-circle"
                            onClick={() => sliderFunction(0)}
                        ><i className='bi bi-chevron-left' /></button>
                        <button
                            className="btn btn-sm btn-outline-secondary rounded-circle ms-1"
                            onClick={() => sliderFunction(1)}
                        ><i className='bi bi-chevron-right' /></button>
                    </div>
                )
            }
        </div>
    )
}

const mapStateToProps = ({ profile, quizSpecific }) => {
    return {
        profile,
        quizSpecific
    }
}

export default connect(mapStateToProps, { doSetEditQuizAction })(QuizSponsorList);
